import { forwardRef } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import * as styles from "./input.module.css";
import { CustomCheckboxProps } from "./Input.types";

/**
 * CustomCheckboxProps
 * @param {boolean} checked - Checked state
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange - Change event handler
 * @param {string} label - Label of the input
 * @param {string} ariaLabel - Aria label of the input
 * @param {string} className - Class name of the input
 * @param {any} rest - Any other props to be passed to the input
 * @returns {JSX.Element}
 */

export const CheckBox = forwardRef<HTMLInputElement, CustomCheckboxProps>(
  ({ checked, onChange, label, ariaLabel, className = "", ...rest }, ref) => {
    return (
      <div className={`${styles["checkbox-container"]} ${className}`}>
        <label className={styles["checkbox-label"]}>
          <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            aria-checked={checked}
            className={styles["checkbox"]}
            aria-label={ariaLabel}
            ref={ref}
            {...rest}
          />
          <span className={`${styles["custom-checkbox"]} ${checked ? styles["filled"] : ""}`}>
            {checked && <CheckOutlinedIcon className={styles["check-icon"]} />}
          </span>
          {label}
        </label>
      </div>
    );
  },
);

CheckBox.displayName = "CheckBox";
