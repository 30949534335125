import { type ApolloError } from '@apollo/client'
import {
  type AmclDuplicateContact,
  type BullhornDuplicateContact,
  type AddContactInput,
  type UpdateContactInput,
  type Company
} from '~/src/__generated__/gql-types'
import {
  ContactDupeCard,
  type ContactDupeCardType
} from '../../shared/dupes/ContactDupeCard'
import { ContactDupeRow } from '../../shared/dupes/ContactDupeRow'
import { Text } from '@alku/ui-kit'
import * as styles from './duplicates.module.css'

interface ContactDuplicateProps {
  amclHardMatches: AmclDuplicateContact[]
  amclPartialMatches: AmclDuplicateContact[]
  bullhornMatches: BullhornDuplicateContact[]
  contactObject: AddContactInput | UpdateContactInput
  companyObject: Company
  loading: boolean
  error: ApolloError | undefined
  type: 'add' | 'view'
}

const pluralize = (count: number, singular: string, plural: string): string =>
  count === 1 ? singular : plural

export const ContactDuplicatesComponent: React.FC<ContactDuplicateProps> = ({
  amclHardMatches,
  amclPartialMatches,
  bullhornMatches,
  contactObject,
  companyObject,
  loading,
  error,
  type = 'view'
}) => {
  let subtitle = ''

  if (amclHardMatches.length > 0) {
    subtitle += `${amclHardMatches.length} other ${pluralize(
      amclHardMatches.length,
      'AM',
      'AM’s'
    )} ${pluralize(
      amclHardMatches.length,
      'has',
      'have'
    )} this contact on their lists`
  }
  if (amclHardMatches.length > 0 && amclPartialMatches.length > 0)
    subtitle += ' and '
  // Message for partial matches
  if (amclPartialMatches.length > 0) {
    subtitle += `${amclPartialMatches.length} ${pluralize(
      amclPartialMatches.length,
      'AM',
      'AM’s'
    )} ${pluralize(amclPartialMatches.length, 'has', 'have')} a similar contact`
  }
  if (bullhornMatches.length > 0)
    subtitle = 'This contact has already been added as a DBA in Bullhorn'
  subtitle += '. Please review:'

  return (
    <>
      {loading && <>Loading...</>}
      {error && <>{error.message}</>}
      {!loading && (
        <>
          <div className={styles.subtitle}>
            <Text style='four' weight='bold' color='alku-brand-primary'>
              {subtitle}
            </Text>
          </div>
          <ContactDupeCard
            type='input'
            contact={contactObject as ContactDupeCardType}
            company={companyObject}
          />
          {bullhornMatches.length > 0 ? (
            /* Show Bullhorn matches (Dba) */
            <>
              <div className={styles.separator}>
                <Text
                  style='four'
                  weight='bold'
                  color='alku-brand-primary'
                  className='text'
                >
                  Exact Match{bullhornMatches.length > 1 ? 'es' : ''}
                </Text>
              </div>
              {bullhornMatches.map((duplicate) => {
                return (
                  <div className={styles.margin} key={duplicate.id}>
                    <ContactDupeCard
                      type='bullhorn'
                      contact={duplicate as ContactDupeCardType}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {amclHardMatches.length > 0 && (
                /* Show AMCL exact matches (if no Bullhorn matches) */
                <>
                  <div className={styles.separator}>
                    <Text
                      style='four'
                      weight='bold'
                      color='alku-brand-primary'
                      className='text'
                    >
                      Exact Match{amclHardMatches.length > 1 ? 'ES' : ''}
                    </Text>
                  </div>
                  {amclHardMatches.map((duplicate) => {
                    return (
                      <div className={styles.margin} key={duplicate.id}>
                        <ContactDupeRow
                          data={duplicate as ContactDupeCardType}
                        />
                      </div>
                    )
                  })}
                </>
              )}
              {amclPartialMatches.length > 0 && (
                /* Show AMCL partial matches (if no Bullhorn matches) */
                <>
                  <div className={styles.separator}>
                    <Text
                      style='four'
                      weight='bold'
                      color='alku-brand-primary'
                      className='text'
                    >
                      Partial Match{amclPartialMatches.length > 1 ? 'ES' : ''}
                    </Text>
                  </div>
                  {amclPartialMatches.map((duplicate) => {
                    return (
                      <div className={styles.margin} key={duplicate.id}>
                        <ContactDupeCard
                          type='partial'
                          contact={duplicate as ContactDupeCardType}
                        />
                      </div>
                    )
                  })}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
