import { Form, Formik, type FormikProps } from 'formik'
import { type FC } from 'react'
import { Button } from '@alku/ui-kit'

import { TextInput } from '../../../shared/forms/form-elements'
import hasError from '../../../shared/forms/helpers/hasError'
import * as styles from './AddExistingDBAForm.module.css'
import { type TogglePopover } from '../../../table/Table'
import * as Yup from 'yup'
import {
  type Contact,
  type Company,
  useAddExistingDbaMutation,
  type AddExistingDbaMutation,
  type AddExistingDbaInput,
  GetActiveListSummaryDocument,
  GetLeadsListSummaryDocument,
  GetActiveListDocument
} from '~/src/__generated__/gql-types'
import { type StoreObject } from '@apollo/client'
import { useAuth } from '~/src/auth/hooks/useAuth'

interface AddExistingFormProps {
  contact: Contact
  onRequestClose: () => void
  togglePopover: TogglePopover
  companyObject: Company
}

export const AddExistingDBAForm: FC<AddExistingFormProps> = (props) => {
  const { contact, onRequestClose, togglePopover, companyObject } = props

  const [addExistingDBA, { loading }] = useAddExistingDbaMutation({
    update: (cache, result) => {
      const rootKey = 'addExistingDba' as keyof AddExistingDbaMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // this indicates that the contact was moved to another list during the mutation
      if (result.data[rootKey].ref_company !== companyObject.id) {
        cache.modify({
          id: cache.identify(companyObject),
          fields: {
            contacts(existingContactRefs) {
              return [
                ...existingContactRefs.filter(
                  (element: { __ref: string }) =>
                    element.__ref !== contactCacheKey
                )
              ]
            }
          }
        })
      }
    }
  })

  const { id } = useAuth()

  const handleSubmit = async (
    values: Partial<AddExistingDbaInput>
  ): Promise<void> => {
    await addExistingDBA({
      variables: {
        contactId: contact.id,
        input: values as AddExistingDbaInput
      },
      onCompleted: () => {
        onRequestClose()
        togglePopover(
          'success',
          `Successfully Add Existing DBA ${contact.first_name} ${contact.last_name}`,
          3000
        )
      },
      onError(error) {
        onRequestClose()
        console.error(error)
        togglePopover(
          'error',
          `Failed to Add Existing DBA ${contact.first_name} ${contact.last_name}`,
          3000
        )
      },
      refetchQueries: [
        {
          query: GetActiveListSummaryDocument,
          variables: { userId: id }
        },
        {
          query: GetLeadsListSummaryDocument,
          variables: { userId: id }
        },
        {
          query: GetActiveListDocument,
          variables: { userId: id }
        }
      ]
      // awaitRefetchQueries: true
    })
  }

  const initialValues: Partial<AddExistingDbaInput> = {
    bullhorn_id: undefined
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AEDBAFormSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form id='existing-dba-contact' className={styles.form}>
          <div className={styles['form-element']}>
            <TextInput
              type='number'
              {...getFormFieldValues(formProps, 'bullhorn_id')}
            />
          </div>
          <Button
            style='primary-impact'
            type='submit'
            as='button'
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export const AEDBAFormSchema = Yup.object({
  bullhorn_id: Yup.number()
    .positive()
    .integer()
    .required()
    .label('Bullhorn ID')
    .typeError('Bullhorn ID must be a number')
})

interface FieldsReturn {
  label: string
  id: string
  name: string
  error?: string
  hasError: boolean
  value: string | undefined
  required: boolean
  touched?: boolean
  handleChange: FormikProps<Partial<AddExistingDbaInput>>['handleChange']
}

const getFormFieldValues = (
  {
    errors,
    values,
    handleChange,
    touched
  }: FormikProps<Partial<AddExistingDbaInput>>,
  key: keyof (typeof AEDBAFormSchema)['fields']
): FieldsReturn => {
  const { spec } = AEDBAFormSchema.fields[key] as Yup.Schema
  return {
    label: spec.label as string,
    id: key,
    name: key,
    error: errors[key],
    touched: touched[key],
    hasError: hasError(errors[key]),
    value: values[key]?.toString(),
    handleChange,
    required: !spec.optional
  }
}
