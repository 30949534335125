import { Form, Formik, type FormikProps } from 'formik'
import { useEffect, useMemo, useRef, type FC } from 'react'
import { TextInput } from '../../../shared/forms/form-elements'
import * as styles from './RQDBAForm.module.css'
import { Button, SelectDropdownField, SelectDropdown } from '@alku/ui-kit'
import * as Yup from 'yup'
import {
  useRequalifyContactMutation,
  type Contact,
  type Company,
  useGetDistributionListOptionsLazyQuery,
  GetActiveListSummaryDocument,
  GetLeadsListSummaryDocument,
  type RequalifyContactInput,
  type RequalifyContactMutation,
  type DbaActionType,
  GetActiveListDocument
} from '~/src/__generated__/gql-types'
import { useAuth } from '~/src/auth/hooks/useAuth'
import { groupedActionOptions } from '../AddDBAForm/constants/helper'
import { getSelectedOptions } from '../AddDBAForm/constants/formData'
import { type StoreObject } from '@apollo/client'
import { type TogglePopover } from '~/src/components/table/Table'
import { type GroupBase } from 'react-select'

interface RQDBAFormProps {
  contact: Contact
  onRequestClose: () => void
  togglePopover: TogglePopover
  companyObject: Company
}

export const RQDBAForm: FC<RQDBAFormProps> = ({
  contact,
  onRequestClose,
  togglePopover,
  companyObject
}) => {
  const { id } = useAuth()

  const [requalifyContact, { loading }] = useRequalifyContactMutation({
    update: (cache, result) => {
      const rootKey = 'requalifyContact' as keyof RequalifyContactMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // this indicates that the contact was moved to another list during the mutation
      if (result.data[rootKey].contact?.ref_company !== companyObject.id) {
        cache.modify({
          id: cache.identify(companyObject),
          fields: {
            contacts(existingContactRefs) {
              return [
                ...existingContactRefs.filter(
                  (element: { __ref: string }) =>
                    element.__ref !== contactCacheKey
                )
              ]
            }
          }
        })
      }
    }
  })

  const handleDbaPopover = (success: boolean, error: string): void => {
    togglePopover(success ? 'success' : 'error', error, 3000)
  }

  const handleSubmit = async (
    values: Partial<RequalifyContactInput>
  ): Promise<void> => {
    await requalifyContact({
      variables: {
        contactId: contact.id,
        input: values as RequalifyContactInput
      },
      onCompleted: (data) => {
        const { contact, error: dbaError } = data.requalifyContact
        if (dbaError) {
          if (dbaError.__typename === 'DbaNoteError') {
            handleDbaPopover(
              true,
              'Successfully Requalified, but failed to create note. See Bullhorn.'
            )
          } else if (dbaError.__typename === 'DbaTaskError') {
            handleDbaPopover(
              true,
              'Successfully Requalified, but failed to create task. See Bullhorn.'
            )
          } else if (dbaError.__typename === 'DbaEmailError') {
            handleDbaPopover(
              true,
              'Successfully Requalified, but failed to send email. See Bullhorn.'
            )
          }
        }
        if (!contact) {
          onRequestClose()
          togglePopover('error', 'Failed to DBA contact', 3000)
        }
        if (contact && !dbaError) {
          onRequestClose()
          togglePopover(
            'success',
            `Successfully Requalified ${contact.first_name} ${contact.last_name}`,
            3000
          )
        }
      },
      onError(error) {
        onRequestClose()
        console.error(error)
        togglePopover(
          'error',
          `Failed to Requalify ${contact.first_name} ${contact.last_name}`,
          3000
        )
      },
      refetchQueries: [
        {
          query: GetActiveListSummaryDocument,
          variables: { userId: id }
        },
        {
          query: GetLeadsListSummaryDocument,
          variables: { userId: id }
        },
        {
          query: GetActiveListDocument,
          variables: { userId: id }
        }
      ],
      update: (cache, result) => {
        const rootKey = 'requalifyContact'

        if (!result?.data?.[rootKey]) return

        const { contact } = result.data[rootKey]

        if (contact) {
          // Get the contact object from the cache
          const contactCacheKey = cache.identify(contact)

          // If the contact object is not in the cache, return
          if (!contactCacheKey) return

          // This indicates that the contact was moved to another list during the mutation
          if (contact.ref_company !== companyObject.id) {
            cache.modify({
              id: cache.identify(companyObject),
              fields: {
                contacts(existingContactRefs) {
                  return existingContactRefs.filter(
                    (element: { __ref: string }) =>
                      element.__ref !== contactCacheKey
                  )
                }
              }
            })
          }
        }
      }
      // awaitRefetchQueries: true
    })
  }

  const initialValues: Partial<RequalifyContactInput> = {
    notes: undefined,
    bullhorn_id: undefined,
    email_subscriptions: [],
    action: undefined
  }

  const [
    getDistributionLists,
    { data: distributionList, loading: distributionListLoading }
  ] = useGetDistributionListOptionsLazyQuery()

  const distributionListOptions = useMemo(() => {
    if (!distributionList?.getDistributionListOptions) return []

    return distributionList.getDistributionListOptions.map((list) => ({
      label: `${list.display_name} - (${list.email})`,
      value: list.email
    }))
  }, [distributionList])

  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  useEffect(() => {
    void getDistributionLists()
  }, [getDistributionLists])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RQDBAFormSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        setFieldValue
      }) => (
        <Form id='dba-contact' className={styles.form}>
          <div className={styles['form-element']}>
            <TextInput
              type='number'
              label='Bullhorn ID'
              id='bullhorn_id'
              name='bullhorn_id'
              error={errors.bullhorn_id}
              touched={touched.bullhorn_id}
              value={values.bullhorn_id?.toString()}
              required
              handleChange={handleChange}
            />
          </div>
          <div className={styles['form-element']}>
            <TextInput
              id='notes'
              label='Bullhorn Note'
              name='notes'
              error={errors.notes}
              touched={touched.notes}
              value={values.notes}
              required
              handleChange={handleChange}
              multiline
            />
          </div>

          <div className={styles['form-element']}>
            <SelectDropdown
              isMulti
              options={distributionListOptions}
              isSearchable
              placeholder='Start typing to select colleagues'
              name='email_subscriptions'
              label='Internal Distribution List'
              required
              id='email_subscriptions'
              isLoading={distributionListLoading}
              value={getSelectedOptions(
                distributionListOptions,
                values.email_subscriptions as string[]
              )}
              touched={touched.email_subscriptions}
              error={
                errors.email_subscriptions && touched.email_subscriptions
                  ? errors.email_subscriptions
                  : undefined
              }
              onBlur={() => {
                void setFieldTouched('email_subscriptions')
              }}
              onChange={(value) => {
                void setFieldValue('email_subscriptions', value)
              }}
            />
          </div>
          <div className={styles['form-element']}>
            <SelectDropdownField<GroupBase<DbaActionType>>
              id='action'
              name='action'
              label='Task Action'
              defaultOptionSelected
              options={Object.values(groupedActionOptions)}
              value={groupedActionOptions[values.action]}
              touched={touched.action}
              error={
                errors.action && touched.action ? errors.action : undefined
              }
              required={true}
              onChange={(value: string) => {
                void setFieldValue('action', value)
              }}
            />
          </div>

          <Button
            style='primary-impact'
            type='submit'
            as='button'
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export const RQDBAFormSchema = Yup.object({
  bullhorn_id: Yup.number()
    .positive()
    .integer()
    .required()
    .label('Bullhorn ID')
    .typeError('Bullhorn ID must be a number'),
  notes: Yup.string().trim().required().label('Bullhorn Note'),
  isActionRequired: Yup.boolean().default(true),
  action: Yup.string().when('isActionRequired', {
    is: true,
    then: (RQDBAFormSchema) => Yup.string().required('Action is required'),
    otherwise: (RQDBAFormSchema) => Yup.string().notRequired()
  }),
  isEmailsSuscriptionsRequired: Yup.boolean().default(true),
  email_subscriptions: Yup.array()
    .of(
      Yup.string().email('Invalid email format').required('Email is required')
    )
    .when('isEmailsSuscriptionsRequired', {
      is: true,
      then: (RQDBAFormSchema) =>
        Yup.array()
          .of(
            Yup.string()
              .email('Invalid email format')
              .required('Email is required')
          )
          .required('Email subscriptions are required')
          .min(1, 'At least one email is required'),
      otherwise: (RQDBAFormSchema) => Yup.array().notRequired()
    })
})
