import { RadioButton } from '@alku/ui-kit'
import * as styles from './radio.module.css'

export interface RadioGroupProps {
  options: string[]
  selection: string
  setSelection: any
}

export const RadioGroup: React.FC<RadioGroupProps> = (props): JSX.Element => {
  return (
    <div className={styles.radio_group}>
      {props.options.map((option, key) => (
        <RadioButton
          key={`${option}-${key}`}
          checked={props.selection === option}
          onChange={() => props.setSelection(option)}
          value={option}
          name={option}
          label={option}
          ariaLabel={option}
        />
      ))}
    </div>
  )
}
