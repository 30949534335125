/*
  Avatar:
  Component Styles
*/
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.2rem solid #333333;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.01rem;
  line-height: 1;
  color: var(--alku-black from global);

  &.sm {
    width: 4rem;
    height: 4rem;
    margin-right: 0.6rem; /* maybe move to login in component? */
  }

  &.md {
    width: 4.4rem;
    height: 4.4rem;
  }
}
