@import '../../../breakpoints.module.css'; /* TODO: figure out how to share breakpoints */

 /* Modified to match UI Kit for backward compat */
.label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  height: 3rem;
  &.required {
    &::after {
      content: '*';
      color: var(--alku-brand-primary from global);
      margin-left: 0.5rem;
    }
  }
}

.input,
.select,
.textarea {
  width: 100%;
  font-size: 1.6rem;
  padding: 1rem 1.3rem;
  border-width: 0 0 0.2rem 0;
  border-color: var(--grey-5 from global);
}

.error {
  background-color: var(--alku-red-light from global);
  border-width: 0 0 0.2rem;
  border-color: var(--alku-red from global);
  opacity: 1;
}

.error-text{
  color: var(--alku-red from global);
}

.input:read-only,
.select:disabled,
.textarea:read-only {
  background-color: var(--grey-1 from global);
  color: var(--grey-6 from global);
  opacity: 1;
}

.textarea {
  min-height: 8rem;
  overflow-y: auto;
  resize: none;
  line-height: 1.3;
}

.note {
  margin-top: 2rem;
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.note b {
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

.file {
  width: 100%;
}

.file button {
  width: 17rem;
  padding: 1.5rem;
  font-size: 1.6rem;
}

.fileHiddenInput {
  display: none;
}

.fileInput {
  display: flex;
  align-items: center;
}

.fileInput span {
  padding: 0 1.5rem 0 2rem;
}

.phoneInputContainer {
  width: 100%;
  display: flex;
}

.phoneInput {
  width: 100%;
  padding: 1rem 1.3rem;
  font-size: 1.6rem;
  border-width: 0 0 0.2rem 0;
  border-color: var(--grey-5 from global);
}

.phoneInput:read-only {
  background-color: var(--grey-1 from global);
}

.selectPhonePrefix {
  background-image: none;
  border-width: 0 0 0.2rem;
  border-color: var(--grey-5 from global);
  font-weight: 100;
}

.selectPhonePrefix:disabled {
  opacity: 1;
  color: initial;
  background-color: var(--grey-1 from global);
}

.phoneInputContainer button {
  position: relative;
  left: -2rem;
}

/* nesting so this doesn't affect cxt */
:global(table) .phoneInputWidth {
  width: calc(100% - 5rem);
}

.hidden {
  display: none;
}

dialog .text-input input {
  height: 4.5rem;
}
