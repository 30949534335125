/* Text Input */
.text-input-container {
  margin-bottom: 0.8rem;
}

.text-input-container-no-label {
  margin-top: 3.5rem;
}

.text-input-label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  height: 3rem;
}

.text-input-required {
  color: var(--alku-brand-primary from global);
  margin-left: 0.5rem;
}

.text-input-field {
  width: 100%;
  padding: 0.8rem;
  border-bottom: 0.2rem solid var(--alku-black from global);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 1.3rem;
  height: 2.5rem;
}

.text-input-field:focus {
  outline: none;
  border-color: var(--alku-brand-primary from global);
}

.error-label {
  color: var(--alku-brand-primary from global);
}

/* Textarea Input */
.textarea-field {
  width: 100%;
  padding: 0.8rem;
  border: 0.2rem solid var(--alku-black from global);
  font-size: 1.3rem;
}

.textarea-field:focus {
  outline: none;
  border-color: var(--alku-brand-primary from global);
}

/* Select Input */
.select-input-container {
  margin-bottom: 0.8rem;
}

.select-input-label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  height: 3rem;
}

.select-input-field {
  width: 100%;
  padding: 0.8rem;
  border-bottom: 0.2rem solid var(--alku-black from global);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 1.3rem;
}

.select-input-field:focus,
.select-input-field:focus-visible {
  outline: none;
  border-color: var(--alku-brand-primary from global);
}

/* Radio Button */
.custom-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio__checkmark {
  position: relative;
  height: 2rem;
  width: 2rem;
  background-color: white;
  border: 0.2rem solid var(--alku-brand-primary from global);
  border-radius: 50%;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.custom-radio__checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: white;
  transform: translate(-50%, -50%);
}

.custom-radio input:checked + .custom-radio__checkmark {
  background-color: var(--alku-brand-primary from global);
  border: 0.2rem solid var(--alku-brand-primary from global);
}

.custom-radio input:checked + .custom-radio__checkmark:after {
  display: block;
}

.custom-radio__label {
  margin-left: 1rem;
}

/* Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  display: none;
}

.custom-checkbox {
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid var(--alku-brand-primary from global);
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lg-checkbox {
  width: 2rem;
  height: 2rem;
}

.filled {
  background-color: var(--alku-brand-primary from global);
  border-color: var(--alku-brand-primary from global);
}

.check-icon {
  color: white;
  font-size: 1.3rem;
}

/* Toggle */
.toggle-switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle-switch-label {
  margin-left: 1rem;
  font-size: 1.3rem;
}

.toggle-switch-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch-slider {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  background-color: var(--alku-icon-disabled from global);
  border-radius: 2rem;
  transition: background-color 0.3s;
}

.toggle-switch-slider:before {
  content: "";
  position: absolute;
  height: 1.6rem;
  width: 1.6rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch-checkbox:checked + .toggle-switch-slider {
  background-color: var(--alku-brand-primary from global);
}

.toggle-switch-checkbox:checked + .toggle-switch-slider:before {
  transform: translateX(20px);
}
