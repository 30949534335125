import { ReactNode } from "react";

export interface TextProps {
  children: ReactNode;
  style: string;
  as?: string;
  color?: string;
  weight?: string;
  href?: string;
  linkNewTab?: boolean;
  className?: string;
}

export interface HeadingProps {
  children: ReactNode;
  style: string;
  as: keyof Pick<JSX.IntrinsicElements, "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div">;
  color?: string;
}
