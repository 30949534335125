import { useEffect, useState } from 'react'
import * as styles from './accordion.module.css'
import { Icon, Text } from '@alku/ui-kit'
import { Table } from '../table/Table'
import {
  type ListType,
  type Company,
  type Contact
} from '../../__generated__/gql-types'
import classNames from 'classnames'

interface AccordionProps {
  title: string
  quantity: number
  color: string
  isOpen?: boolean
  list: Contact[]
  setShowAddContactForm?: (show: boolean) => void
  showAddContactForm?: boolean
  companyObject: Company
  refreshAccordionTs?: number
  isReadOnly: boolean
  hidden?: boolean
  listType: ListType
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  quantity,
  color,
  isOpen = false,
  list,
  setShowAddContactForm,
  showAddContactForm = false,
  companyObject,
  refreshAccordionTs,
  isReadOnly,
  hidden = false,
  listType
}): JSX.Element => {
  const [isAccordionExpanded, setIsAccordionExpanded] =
    useState<boolean>(isOpen)
  const accordionId = `${title.replace(' ', '-')}-${companyObject.id}`

  const handleAccordionToggle = (): void => {
    setIsAccordionExpanded((prevState) => !prevState)
  }

  useEffect(() => {
    setIsAccordionExpanded(isOpen)
  }, [isOpen, showAddContactForm, refreshAccordionTs])

  return (
    <div
      className={classNames(
        styles.accordion,
        isAccordionExpanded ? styles.open : '',
        hidden && styles.hidden,
        // This extra 'hidden' class is needed for the &:nth-last-child(1 of :not(.hidden)) selector to work
        hidden && 'hidden',
        styles['accordion-' + color]
      )}
    >
      <button
        className={styles['accordion-title']}
        onClick={handleAccordionToggle}
        aria-controls={accordionId}
        aria-expanded={isAccordionExpanded}
      >
        <Text as='p' style='detail' color={color} weight='bold'>
          {title} ({quantity})
        </Text>
        {!isAccordionExpanded ? (
          <Icon
            symbol='ChevronRightOutlinedIcon'
            color='bright-blue'
            size='md'
          />
        ) : (
          <Icon symbol='ExpandMoreOutlinedIcon' color='bright-blue' size='md' />
        )}
      </button>
      {isAccordionExpanded && (
        <div className={styles['accordion-content']} id={accordionId}>
          <Table
            listType={listType}
            companyObject={companyObject}
            list={list}
            setShowAddContactForm={setShowAddContactForm}
            showAddContactForm={showAddContactForm}
            contactCategory={title}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
    </div>
  )
}
