import { SortType, TimeZone } from '../../../__generated__/gql-types'

export const TIMEZONE_VALUE_MAPPER: Record<TimeZone | 'NOT_FOUND', number> = {
  [TimeZone.Est]: 0,
  [TimeZone.Cst]: 1,
  [TimeZone.Mst]: 2,
  [TimeZone.Pst]: 3,
  // Not sure where to sort these in relation to the above
  [TimeZone.Ast]: 4,
  [TimeZone.Hst]: 5,
  [TimeZone.Sst]: 6,
  NOT_FOUND: 7
}

export const activeListSortData: Array<{
  label: string
  value: SortType
}> = [
  {
    label: 'Company A-Z',
    value: SortType.Alpha
  },
  {
    label: 'Company Z-A',
    value: SortType.AlphaReversed
  },
  {
    label: 'Company New-Old',
    value: SortType.NewOld
  },
  {
    label: 'Company Old-New',
    value: SortType.OldNew
  },
  {
    label: 'Number of Contacts',
    value: SortType.Count
  },
  {
    label: 'Time Zone',
    value: SortType.Timezone
  }
]
