import { useEffect, useRef, useState } from 'react'
import { Button, Modal, Icon, Text } from '@alku/ui-kit'

import * as styles from './table.module.css'
import * as sharedStyles from '../../styles.module.css'
import { TableRow } from './TableRow'
import {
  type ListType,
  type Company,
  type Contact
} from '../../__generated__/gql-types'
import { ContactForm } from '../call-list/contactForm/ContactForm'
import { useAuth } from '~/src/auth/hooks/useAuth'
import classNames from 'classnames'

export type TogglePopover = (
  icon: string,
  message: string,
  /** default: 2000 */
  timeout?: number
) => void

interface TableProps {
  list: Contact[]
  setShowAddContactForm?: (show: boolean) => void
  showAddContactForm?: boolean
  contactCategory: string
  companyObject: Company
  isReadOnly: boolean
  listType: ListType
}

export const Table: React.FC<TableProps> = ({
  list,
  setShowAddContactForm = () => false,
  showAddContactForm = false,
  contactCategory,
  companyObject,
  isReadOnly,
  listType
}): JSX.Element => {
  /* Set sort direction */
  const [isSortAsc, setisSortAsc] = useState<boolean>(true)

  /* Sort table data by last name */
  const sortByReferralAndName = (asc: boolean, data: Contact[]): Contact[] => {
    const newData = [...data]

    newData.sort((a, b) => {
      // If both have referral set to true, sort by referral_ts
      if (a.referral && b.referral) {
        return asc
          ? a.referral_ts - b.referral_ts
          : b.referral_ts - a.referral_ts
      }

      // If only one has referral set to true, place it first
      if (a.referral && !b.referral) return -1
      if (!a.referral && b.referral) return 1

      // If none have referral set to true, sort by last_name
      return asc
        ? a.last_name.localeCompare(b.last_name)
        : b.last_name.localeCompare(a.last_name)
    })

    return newData
  }

  /* Set table data */
  const data = list ?? []
  /* Sort by referral date by default */
  const [rows, setRows] = useState(sortByReferralAndName(true, data))

  const [expandAllContactPhones, setExpandAllContactPhones] =
    useState<boolean>(false)

  /* This is used to put the header sticky */
  const headerRef = useRef<HTMLTableRowElement>(null)

  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [popoverMessage, setPopoverMessage] = useState<string>('')
  const [popoverIcon, setPopoverIcon] = useState<string>('')
  const [contactDialogOpen, setContactDialogOpen] = useState<boolean>(false)
  const [editingContact, setEditingContact] = useState<Contact | null>(null)

  // Needed to manage updates from cache changes
  useEffect(() => {
    const data = list ?? []
    setRows(sortByReferralAndName(isSortAsc, data))
  }, [list, isSortAsc])

  useEffect(() => {
    setContactDialogOpen(showAddContactForm)
  }, [showAddContactForm])

  const togglePopover: TogglePopover = (icon, message, timeout = 2000) => {
    setPopoverMessage(message)
    setPopoverIcon(icon)
    setShowPopover(true)

    if (typeof timeout === 'number') {
      setTimeout(() => {
        setShowPopover(false)
      }, timeout)
    }
  }

  const handleContactModalClose = (): void => {
    setContactDialogOpen(false)
    setEditingContact(null)
    setShowAddContactForm(false)
  }

  const getIcon = (type: string): JSX.Element => {
    switch (type) {
      case 'success':
        return (
          <Icon
            symbol='CheckCircleOutlinedIcon'
            color='text-green'
            size='md'
            space='right'
          />
        )
      case 'loading':
        return (
          <Icon
            symbol='AutorenewOutlinedIcon'
            color='text-red'
            size='md'
            space='right'
            animation='flip'
          />
        )
      default:
        return (
          <Icon
            symbol='ErrorOutlineOutlinedIcon'
            color='icon-red'
            size='md'
            space='right'
          />
        )
    }
  }

  const { id } = useAuth()

  const isEditingContact = editingContact != null

  return (
    <div className={styles['contact-popover-wrapper']}>
      <div
        className={classNames(styles.wrapper, rows.length > 6 && styles.sticky)}
      >
        <table className={styles['contact-table']}>
          <thead>
            <tr ref={headerRef}>
              <th
                onClick={() => {
                  setRows(sortByReferralAndName(!isSortAsc, rows))
                  setisSortAsc(!isSortAsc)
                }}
              >
                <span>
                  Contact
                  <span className={styles.sort}>
                    <>
                      <Button
                        onClick={() => {
                          setRows(sortByReferralAndName(!isSortAsc, rows))
                          setisSortAsc(!isSortAsc)
                        }}
                        as='button'
                        style='icon-only'
                        id='sort-asc'
                      >
                        <Icon
                          symbol='ArrowDropDownOutlinedIcon'
                          color={
                            isSortAsc ? 'alku-white' : 'alku-white-inactive'
                          }
                          size='md'
                          rotate={true}
                        />
                      </Button>
                      <Button
                        onClick={() => {
                          setRows(sortByReferralAndName(!isSortAsc, rows))
                          setisSortAsc(!isSortAsc)
                        }}
                        as='button'
                        style='icon-only'
                        id='sort-desc'
                      >
                        <Icon
                          symbol='ArrowDropDownOutlinedIcon'
                          color={
                            !isSortAsc ? 'alku-white' : 'alku-white-inactive'
                          }
                          size='md'
                        />
                      </Button>
                    </>
                  </span>
                </span>
              </th>
              <th>
                <div className={styles.phoneHeader}>
                  Phone(s)
                  <Button
                    as='button'
                    style='icon-only'
                    ariaLabel='expand all contact row phone numbers in company'
                    onClick={() => {
                      setExpandAllContactPhones(!expandAllContactPhones)
                    }}
                    id={`expand-collapse-phones-${companyObject.id}`}
                  >
                    <Icon
                      symbol={
                        expandAllContactPhones
                          ? 'ExpandLessOutlinedIcon'
                          : 'ExpandMoreOutlinedIcon'
                      }
                      color='alku-white'
                      size='md'
                      space='left'
                    />
                  </Button>
                </div>
              </th>
              <th>Location</th>
              <th>Email</th>
              <th>Notes</th>
              <th>
                <span className={sharedStyles['u-sr-only']}>actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 && (
              <tr>
                <td colSpan={7} className={styles['empty-state']}>
                  No contacts have been added yet
                </td>
              </tr>
            )}
            {rows.map((row: Contact, i: number) => (
              <TableRow
                key={row.id}
                row={row}
                togglePopover={togglePopover}
                contactCategory={contactCategory}
                isReadOnly={isReadOnly}
                companyObject={companyObject}
                expandAllContactPhones={expandAllContactPhones}
                listType={listType}
                setEditingContact={setEditingContact}
                setContactDialogOpen={setContactDialogOpen}
                numberOfRows={rows.length}
                rowIndex={i}
              />
            ))}
          </tbody>
        </table>
        <Modal
          open={contactDialogOpen}
          onRequestClose={handleContactModalClose}
          closeOnOutsideClick={false}
          title={`${isEditingContact ? 'Edit' : 'Add a'} Contact${
            isEditingContact
              ? ` - ${editingContact.first_name} ${editingContact.last_name}`
              : ''
          }`}
        >
          {contactDialogOpen && (
            <>
              <ContactForm
                callListUserId={id}
                companyObject={companyObject}
                handleContactModalClose={handleContactModalClose}
                data={editingContact ?? undefined}
                togglePopover={togglePopover}
              />
            </>
          )}
        </Modal>
      </div>
      {showPopover && (
        <div
          className={styles.popover}
          data-testid={`${contactCategory}-${companyObject.name}-popover`}
        >
          {getIcon(popoverIcon)}
          <Text as='p' style='detail' color='dark-grey' weight='semibold'>
            {popoverMessage}
          </Text>
        </div>
      )}
    </div>
  )
}
