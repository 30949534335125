/*
  NavItem:
  Component Styles
*/

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.link {
  margin: 0 2.1rem;
  padding: 3rem 0;
  display: block;
  border-bottom: 0.4rem solid transparent;

  &:hover,
  &.active {
    transition: 0.2s ease all;
    border-color: var(--alku-brand-primary from global);
  }
}
