import * as styles from "./alert.module.css";
import { Button, Icon } from "../index";
import classNames from "classnames";
import { AlertProps, AlertType } from "./Alert.types";
import { themeConfig } from "../helpers/getTheme";

/**
 * Alert Component
 *
 * @param {ReactNode} children - The content of the alert, supporting text and hyperlinks.
 * @param {"error" | "alert" | "success" | "info"} [type="error"] - The theme type of the alert.
 * @param {boolean} [dismissible=false] - Whether the alert can be dismissed.
 * @param {string} id - The id attribute for the alert, useful for testing.
 * @param {"sm" | "md"} [size="md"] - The size of the alert.
 * @param {() => void} onClick - Callback function when the alert is dismissed.
 * @returns {JSX.Element} The rendered alert component.
 */
export const Alert: React.FC<AlertProps> = ({
  children,
  type = AlertType.Error,
  dismissible = false,
  id,
  size = "md",
  onClick,
}) => {
  const { icon, color } = themeConfig[type];

  return (
    <div className={classNames(styles.container, styles[type], styles[size])}>
      <div className={styles.content}>
        <Icon symbol={icon} color={color} size={size === "sm" ? "md" : "xl"} />
        <div className={styles.text}>{children}</div>
      </div>
      {dismissible && (
        <Button as="button" type="button" id={id} style="icon-only" ariaLabel="close" onClick={onClick}>
          <Icon symbol="CloseOutlinedIcon" color={color} size="lg" />
        </Button>
      )}
    </div>
  );
};
