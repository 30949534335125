import * as styles from './spinner.module.css'

import { Icon, Text } from '@alku/ui-kit'

export interface SpinnerProps {
  message?: string
  fullScreen?: boolean
}

export const Spinner: React.FC<SpinnerProps> = ({
  message = 'Just a second while we fetch the data.',
  fullScreen
}): JSX.Element => {
  const SpinnerElement = (
    <div className={styles.container}>
      <Text as='p' style='detail' color='alku-black' weight='bold'>
        Now Loading...
      </Text>
      <Icon
        symbol='AutorenewOutlinedIcon'
        color='icon-red'
        size='lg'
        space='center'
        animation='spin'
      />
      <Text as='p' style='detail' color='dark-blue'>
        {message}
      </Text>
    </div>
  )
  if (fullScreen)
    return <div className={styles.fullScreen}>{SpinnerElement}</div>

  return SpinnerElement
}
