import { type FC } from 'react'
import { Icon, Text } from '@alku/ui-kit'
import * as styles from './popover.module.css'

interface PopoverProps {
  popoverMessage: string
  popoverIcon: string
  testId?: string
}

export const Popover: FC<PopoverProps> = ({
  popoverMessage,
  popoverIcon,
  testId
}: PopoverProps): JSX.Element => {
  return (
    <div className={styles.popover} data-testid={testId}>
      {getIcon(popoverIcon)}
      <Text as='p' style='detail' color='dark-grey' weight='semibold'>
        {popoverMessage}
      </Text>
    </div>
  )
}

const getIcon = (type: string): JSX.Element => {
  switch (type) {
    case 'success':
      return (
        <Icon
          symbol='CheckCircleOutlinedIcon'
          color='text-green'
          size='md'
          space='right'
        />
      )
    case 'loading':
      return (
        <Icon
          symbol='AutorenewOutlinedIcon'
          color='text-red'
          size='md'
          space='right'
          animation='flip'
        />
      )
    case 'error':
      return (
        <Icon
          symbol='ErrorOutlineOutlinedIcon'
          color='text-red'
          size='md'
          space='right'
          animation='flip'
        />
      )
    default:
      return (
        <Icon
          symbol='ErrorOutlineOutlinedIcon'
          color='icon-red'
          size='md'
          space='right'
        />
      )
  }
}
