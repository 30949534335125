.list {
  display: flex;

  :global(.react-select__control) {
    width: 32.1rem;
  }

  > :not(:last-child) {
    margin-right: 2.6rem;
  }
}
