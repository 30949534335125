import { IconOptions } from "../icon/Icon.types";

export enum Theme {
  Default = "default",
  Info = "info",
  Alert = "alert",
  Success = "success",
  Error = "error",
}

export const themeConfig: Record<Theme, { icon: IconOptions | undefined; color: string }> = {
  [Theme.Alert]: { icon: "ReportProblemOutlinedIcon", color: "icon-warn" },
  [Theme.Error]: { icon: "ErrorOutlineOutlinedIcon", color: "alku-brand-primary" },
  [Theme.Info]: { icon: "InfoOutlinedIcon", color: "icon-info" },
  [Theme.Success]: { icon: "CheckCircleOutlineOutlinedIcon", color: "icon-success" },
  [Theme.Default]: { icon: undefined, color: "icon-info" },
};
