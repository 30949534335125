import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react'
import {
  useIsAuthenticated,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'
import useCustomMsalAuthentication from './useCustomMsalAuthentication'
import { InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants'
import {
  type SortType,
  type UserProfile,
  useGetCurrentUserLazyQuery
} from '../../__generated__/gql-types'
import { useErrorHandler } from './useErrorHandler'
import { getAccessToken } from '../msal/helpers'

// Screens
import UnauthenticatedScreen from '../../screens/unauthenticated/Unauthenticated'
import AppLoading from '../../screens/appLoading/AppLoading'
import { isInExtension } from '~/src/helpers/environment'

export const AuthContext = createContext<UserProfileWithoutIds>({
  bullhorn_id: -1,
  call_list_sort_by: 'ALPHA' as SortType,
  first_name: '',
  last_name: '',
  id: '',
  favorites: []
})

// Export the provider as we need to wrap the entire app with it
interface AuthProviderProps {
  children: ReactNode
}

// TODO: We need to omit the `ids` field from the UserProfile type, until we remove it from the backend
type UserProfileWithoutIds = Omit<UserProfile, 'ids'>

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useState<UserProfileWithoutIds>()

  const [getCurrentUser, { data, error }] = useGetCurrentUserLazyQuery()
  const { accounts } = useMsal()
  const { setError } = useErrorHandler()
  const isAuthenticated = useIsAuthenticated(accounts[0])

  const validTokenGetCurrentUser = useCallback(async () => {
    if (isAuthenticated) {
      const token = await getAccessToken()
      if (typeof token === 'string') void getCurrentUser()
    }
  }, [getCurrentUser, isAuthenticated])

  // Fetch the current user bullhornid on initial load
  useEffect(() => {
    if (error !== undefined)
      setError({ hasError: true, errorMessage: error.message })
    else void validTokenGetCurrentUser()

    if (data !== null && data !== undefined) setUser(data.currentUser)
  }, [
    accounts,
    data,
    error,
    getCurrentUser,
    setError,
    user,
    validTokenGetCurrentUser
  ])

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <>
      {isInExtension() ? (
        <ExtensionAuth isAuthenticated={isAuthenticated} />
      ) : (
        <AppAuth />
      )}
      {user !== undefined ? ( // User is authenticated
        <AuthenticatedTemplate>
          <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
        </AuthenticatedTemplate>
      ) : isAuthenticated ? (
        <AppLoading errorMessage={error?.message} />
      ) : null}
      {!isAuthenticated && ( // User is not authenticated
        <UnauthenticatedTemplate>
          <UnauthenticatedScreen hasAccounts={accounts.length > 0} />
        </UnauthenticatedTemplate>
      )}
    </>
  )
}

const AppAuth = (): JSX.Element => {
  useMsal()
  useMsalAuthentication(InteractionType.Redirect)
  return <></>
}
const ExtensionAuth = ({
  isAuthenticated
}: {
  isAuthenticated: boolean
}): JSX.Element => {
  useCustomMsalAuthentication(isAuthenticated)
  return <></>
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export const useAuth = (): UserProfileWithoutIds => {
  return useContext(AuthContext)
}
