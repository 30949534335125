import * as styles from "./flyout.module.css";
import { useRef, useLayoutEffect, type FC } from "react";
import classNames from "classnames";
import { FlyoutProps } from "./flyout.types";

export const Flyout: FC<FlyoutProps> = (props) => {
  const { type = "default", children, isOpen, setIsOpen, width } = props;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClickAway);
      });
    } else {
      document.removeEventListener("click", handleClickAway);
    }

    return () => {
      document.removeEventListener("click", handleClickAway);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClickAway = (e: MouseEvent): void => {
    const target = e.target as HTMLElement;
    if (ref?.current === document.activeElement && ref?.current?.contains(target)) return;
    setIsOpen(false);
  };

  return (
    <div
      className={classNames(
        styles.flyout,
        styles[type],
        isOpen ? (styles.open as string) : (styles.closed as string),
        width === "md" ? (styles.md as string) : (styles.lg as string),
      )}
      aria-expanded={isOpen}
      ref={ref}
    >
      {children}
    </div>
  );
};
