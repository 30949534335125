import { memo, type FC, useCallback } from 'react'
import { Button, Icon, Text, Modal } from '@alku/ui-kit'
import * as styles from './ExportCallListModal.module.css'
import * as sharedStyles from '~/src/styles.module.css'
import useExportCallListLazy from './hooks/useExportCallListLazy'
import {
  useArchiveListMutation,
  ListType,
  GetActiveListSummaryDocument,
  GetNotCallingListDocument,
  GetLeadsListDocument,
  type ArchiveListMutation
} from '../../../__generated__/gql-types'
import { useNavigate } from 'react-router-dom'
import { type StoreObject } from '@apollo/client/cache'

interface ExportCallListModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  listType: ListType
}

interface ArchiveCallListModalProps extends ExportCallListModalProps {
  callListUserId: string
}

export const ExportCallListModal: FC<ExportCallListModalProps> = ({
  isOpen,
  setIsOpen,
  listType
}) => {
  const { loading, exportCallList } = useExportCallListLazy(listType)

  const handleClick = useCallback(async (): Promise<void> => {
    const successfullyExported = await exportCallList()
    if (successfullyExported) setIsOpen(false)
  }, [exportCallList, setIsOpen])

  const listTypeName = getCallListName(listType)

  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title={'Export Your List'}
    >
      <ExportInner listName={listTypeName} />
      <Button
        as='button'
        type='button'
        style='primary-impact'
        onClick={handleClick}
        className={styles.action}
        disabled={loading}
      >
        Export List
      </Button>
    </Modal>
  )
}

export const ArchiveCallListModal: FC<ArchiveCallListModalProps> = ({
  isOpen,
  setIsOpen,
  listType,
  callListUserId
}) => {
  const { loading: exportLoading, exportCallList } =
    useExportCallListLazy(listType)
  const navigate = useNavigate()

  const listTypeToQueryMap = {
    [ListType.Active]: GetActiveListSummaryDocument,
    [ListType.Leads]: GetLeadsListDocument,
    [ListType.NotCalling]: GetNotCallingListDocument
  }
  const refetchQueries = {
    query: listTypeToQueryMap[listType],
    variables: { userId: callListUserId }
  }

  const [archiveList, { loading: archiveLoading }] = useArchiveListMutation({
    variables: {
      userId: callListUserId,
      listType
    },
    update: (cache, result) => {
      const rootKey = 'archiveList' as keyof ArchiveListMutation

      if (!result?.data?.[rootKey]) return

      const companyCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      /* Remove the company from the cache */
      cache.evict({ id: companyCacheKey })
    },
    refetchQueries: refetchQueries ? [refetchQueries] : undefined
  })

  const handleClick = useCallback(async (): Promise<void> => {
    const successfullyExported = await exportCallList()
    if (!successfullyExported) return

    const { errors } = await archiveList()
    setIsOpen(false)
    if (!errors?.length) navigate('/')
  }, [archiveList, exportCallList, navigate, setIsOpen])

  const listTypeName = getCallListName(listType)

  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title={'Delete Your ' + listTypeName}
    >
      <ArchiveInner listName={listTypeName} listType={listType} />
      <Button
        as='button'
        type='button'
        style='primary-impact'
        onClick={handleClick}
        className={styles.action}
        disabled={archiveLoading || exportLoading}
      >
        Export &amp; Delete List
      </Button>
      <Button
        as='button'
        type='button'
        style='primary-default'
        onClick={() => {
          setIsOpen(false)
        }}
        className={sharedStyles['cancel-button']}
      >
        Cancel
      </Button>
    </Modal>
  )
}

interface ArchiveInnerProp {
  listName: string
  listType: ListType
}

export const ArchiveInner: FC<ArchiveInnerProp> = memo(function ArchiveInner({
  listName,
  listType
}) {
  return (
    <>
      <p className={styles.warning}>
        <Icon symbol='WarningAmberRoundedIcon' color='icon-red' size='lg' />
        <Text style='detail' as='span' color='alku-brand-primary' weight='bold'>
          Warning:
        </Text>
      </p>

      <Text as='p' style='detail' weight='bold' className={styles.warningMsg}>
        Deleting your list is a permanent action that cannot be undone.
      </Text>

      {listType !== ListType.Active && (
        <Text
          as='p'
          style='detail'
          weight='bold'
          color='alku-brand-primary'
          className={styles.warningMsg}
        >
          Reminder: There is no import for your {listName}!
        </Text>
      )}

      <Text as='p' style='detail' color='grey-8'>
        Your <b>{listName}</b> data will be automatically exported as a CSV file
        before deletion.
      </Text>
    </>
  )
})

export const ExportInner: FC<{ listName: string }> = memo(function ExportInner({
  listName
}) {
  return (
    <>
      <Text as='p' style='detail' color='grey-8'>
        Click Export below to download your <b>{listName}</b> data as a CSV
        file.
      </Text>
    </>
  )
})

function getCallListName(listType: ListType): string {
  switch (listType) {
    case ListType.Active:
      return 'Active List'
    case ListType.Leads:
      return 'Leads List'
    case ListType.NotCalling:
      return 'Not Calling List'
    default:
      return 'List'
  }
}
