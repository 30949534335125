.flyout {
  max-width: 21.8rem;
  padding: 1rem 2rem;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  white-space: normal;
  text-align: left;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }

  &.default {
    background: var(--alku-white from global);
    border: 0.1rem solid #cccccc;
    border-radius: 0.5rem;
  }

  &.default,
  &.account {
    &.md {
      width: 15.6rem;
    }

    &.lg {
      width: 22.4rem;
    }
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  &.account {
    background-color: #f9f9f9; /* consolidated from #f8f8f8 */

    &::after {
      width: 1.5rem;
      height: 1.5rem;
      background: #f9f9f9;
      transform: rotate(45deg);
      position: absolute;
      right: 2rem;
      content: "";
      top: -0.8rem;
      border-bottom-right-radius: 0.5rem;
      box-shadow: -0.2rem -0.2rem 0.5rem -0.3rem rgba(0, 0, 0, 0.3);
    }
  }
}

.flyout-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

.flyout-list-item-btn {
  width: 100%;
}

.default-wrapper {
  position: absolute;
  top: 100%;
  left: -2.4rem;
}

.account-wrapper {
  position: absolute;
  top: 100%;
  right: -1.3rem;
}

.position-right {
  right: 0;
  left: unset;
}
