.form {
  width: 70%;
}

.form-element {
  margin-bottom: 2rem;
  width: 100%;

  &:last-of-type {
    margin-bottom: 3.2rem;
  }
}

.phone-container {
  display: flex;
}

.phone-select {
  margin-right: 1.6rem;
  max-width: 15rem;
}
