import {
  type UpdateContactMutationFn,
  type UpdateContactInput,
  GetActiveListSummaryDocument
} from '../../../__generated__/gql-types'

export const handleUpdateContact = async (
  updateContactFn: UpdateContactMutationFn,
  updatedContact: UpdateContactInput,
  contactId: string,
  callListUserId: string,
  onCompleted?: () => void
): Promise<boolean> => {
  try {
    await updateContactFn({
      variables: {
        contact: updatedContact,
        id: contactId
      },
      onCompleted,
      refetchQueries: [
        {
          query: GetActiveListSummaryDocument,
          variables: { userId: callListUserId }
        }
      ],
      awaitRefetchQueries: true
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
