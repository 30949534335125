import { type Dispatch, type SetStateAction, useCallback, useMemo } from 'react'
import {
  SortType,
  type Company,
  type Lead,
  ListType
} from '~/src/__generated__/gql-types'
import { CompanySection } from '../company/CompanySection'
import { TIMEZONE_VALUE_MAPPER } from '~/src/screens/listScreens/constants/sortDropdownData'
import * as sharedStyles from '../../../styles.module.css'
import { type SCREEN_STATE } from '~/src/screens/listScreens/helpers/list.helper'

export const Companies = ({
  companiesData,
  userId,
  sortValue,
  defaultOpenContactCategory,
  isReadOnly,
  contactSearchValue,
  setEditingCompany,
  setEditingLead,
  setCompanyDialogOpen,
  setScreenState,
  listType,
  leads
}: {
  companiesData: Company[]
  userId: string | undefined
  sortValue: SortType
  defaultOpenContactCategory: any
  isReadOnly: boolean
  contactSearchValue: string
  setEditingCompany: (company: Company | null) => void
  setEditingLead: (company: Company | null) => void
  setScreenState: (state: keyof typeof SCREEN_STATE) => void
  setCompanyDialogOpen: Dispatch<SetStateAction<boolean>>
  listType: ListType
  leads?: Lead[]
}): JSX.Element => {
  const sortCompany = useCallback(
    (companyA: Company, companyB: Company): number => {
      switch (sortValue) {
        case SortType.Count:
          return (
            (companyB.contacts?.length ?? 0) - (companyA.contacts?.length ?? 0)
          )
        case SortType.Timezone: {
          // EST > CST > MST > PST https://alkutech.atlassian.net/browse/RP-105
          const companyAValue: number =
            TIMEZONE_VALUE_MAPPER[companyA?.timezone ?? 'NOT_FOUND']
          const companyBValue: number =
            TIMEZONE_VALUE_MAPPER[companyB?.timezone ?? 'NOT_FOUND']
          return companyAValue - companyBValue
        }
        case SortType.AlphaReversed:
          return (companyB.name ?? '').localeCompare(companyA.name ?? '')
        // Since this is the default value I left this at the end but if we ever want
        // an "Unsorted" state, we have to set the default to return 0 and move the sort function
        // to the Company sort case
        case SortType.NewOld: {
          return companyA.created_ts < companyB.created_ts ? 1 : -1
        }
        case SortType.OldNew: {
          return companyA.created_ts > companyB.created_ts ? 1 : -1
        }
        case SortType.Alpha:
        default:
          return (companyA.name ?? '').localeCompare(companyB.name ?? '')
      }
    },
    [sortValue]
  )

  const sortedCompanies = useMemo<Company[]>(() => {
    if (companiesData !== undefined && companiesData !== null) {
      const sortedCompanies = companiesData.filter((v) => v !== null)

      if (sortValue === SortType.Timezone) {
        // sort alphabetically first
        sortedCompanies.sort((companyA: Company, companyB: Company) =>
          (companyA.name ?? '').localeCompare(companyB.name ?? '')
        )
        // then sort by timezone
        sortedCompanies.sort(sortCompany)
      } else {
        sortedCompanies.sort(sortCompany)
      }

      return sortedCompanies
    } else {
      return []
    }
  }, [companiesData, sortCompany, sortValue])

  if (companiesData && companiesData.length > 0) {
    return (
      <div className={sharedStyles.section}>
        {sortedCompanies.map((companyData, index) =>
          companyData != null ? (
            <CompanySection
              listType={listType}
              leads={listType === ListType.Leads ? leads : undefined}
              key={companyData.id}
              data={companyData}
              isExpanded={
                /* first company should be expanded by default */
                Array.isArray(defaultOpenContactCategory) || index === 0
              }
              defaultOpen={defaultOpenContactCategory}
              isReadOnly={isReadOnly}
              contactSearchQuery={contactSearchValue}
              onEdit={(company) => {
                /* Check if editing and incomplete lead or a company */
                if (!company.bullhorn_id) {
                  setEditingLead(company)
                  setEditingCompany(null)
                } else {
                  setEditingCompany(company)
                  setEditingLead(null)
                }

                setCompanyDialogOpen(true)
              }}
              setScreenState={setScreenState}
              callListUserId={userId as string}
              setCompanyDialogOpen={setCompanyDialogOpen}
            />
          ) : null
        )}
      </div>
    )
  } else {
    return <>No Companies</>
  }
}
