import { type ReactNode, type FC, useState, useCallback } from 'react'
import { Button, type ButtonProps, Icon, type IconProps } from '@alku/ui-kit'
import classNames from 'classnames'
import * as styles from './FlyoutIconButton.module.css'
import { Flyout, type FlyoutProps } from '../../shared/flyout/Flyout'

interface FlyoutIconButtonProps {
  label: string
  id?: string
  iconProps?: Omit<Partial<IconProps>, 'symbol'> & {
    symbol: IconProps['symbol']
  }
  flyoutChildren?: ReactNode
  buttonProps?: Omit<Partial<ButtonProps>, 'onClick' | 'id'>
  position?: 'right' | 'left'
  flyoutWidth?: FlyoutProps['width']
}

export const FlyoutIconButton: FC<FlyoutIconButtonProps> = (props) => {
  const {
    label,
    id,
    iconProps,
    flyoutChildren,
    buttonProps,
    position = 'right',
    flyoutWidth
  } = props
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false)

  const toggleFlyoutVisibility = useCallback((): void => {
    setIsFlyoutOpen((bool) => !bool)
  }, [])

  return (
    <>
      <Button
        as='button'
        type='button'
        style='function'
        ariaLabel={label}
        id={id}
        onClick={toggleFlyoutVisibility}
        {...buttonProps}
      >
        {iconProps && <Icon color='alku-black' size='lg' {...iconProps} />}
        {buttonProps?.children ?? ''}
      </Button>
      <div
        className={classNames(
          styles['default-wrapper'],
          styles[`position-${position}`]
        )}
      >
        <Flyout
          type='default'
          isOpen={isFlyoutOpen}
          width={flyoutWidth ?? 'lg'}
          setIsOpen={setIsFlyoutOpen}
        >
          <ul>{flyoutChildren}</ul>
        </Flyout>
      </div>
    </>
  )
}
