import { ReactNode } from "react";

export enum AlertType {
  Info = "info",
  Alert = "alert",
  Success = "success",
  Error = "error",
}

export type AlertProps = {
  children: ReactNode;
  type: AlertType;
  dismissible?: boolean;
  id: string;
  size?: "sm" | "md";
  onClick?: () => void;
};
