@custom-media --md (min-width: 62em); /* 992px */

.dupe {
  border-radius: 0.1rem;
  background: var(--grey-4 from global);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.25);
}

.dupe.narrow {
  background: var(--white from global);
}

.dupe-card {
  padding: 2.4rem;
  border-top: 0.3rem solid var(--alku-red from global);
}

.column {
  width: 33%;
  min-width: 33%;
  margin-right: 3rem;
}

.dupe-row {
  padding: 3rem 2.4rem;

  &.narrow {
    padding: 1.6rem;
  }
}

.contact-row-content {
  width: 33%;
  max-width: 33%;
}

.company-row-content {
  width: 44%;
  max-width: 44%;
}

.date {
  margin-right: 5rem;
}

.container {
  margin: 2rem 6rem 0 6rem;
}

.small-container {
  margin: 1rem 0;
}

@media (--md) {
  .details {
    margin-bottom: 2rem;
  }
}

.details {
  margin-bottom: 1rem;
}

.bullhorn-logo {
  max-width: 5rem;
  width: 5rem;
}

.space {
  margin-left: 0.5rem;
}

.mt8 {
  margin-top: 0.8rem;
}
