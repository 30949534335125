import productionConfig from './features.prod'
import uatConfig from './features.uat'
import qaConfig from './features.qa'
import devConfig from './features.dev'

/* List here all feature flags */
export interface FeatureFlags {
  uiKit: boolean
  showEnvironmentBadge: boolean
}

export enum Environment {
  PROD = 'prod',
  QA = 'qa',
  UAT = 'uat',
  DEV = 'dev'
}

export const environment = process.env.ENVIRONMENT ?? Environment.PROD

let featureFlags: FeatureFlags

switch (environment) {
  case Environment.PROD:
    featureFlags = productionConfig
    break
  case Environment.QA:
    featureFlags = qaConfig
    break
  case Environment.UAT:
    featureFlags = uatConfig
    break
  case Environment.DEV:
    featureFlags = devConfig
    break
  default:
    throw new Error('Invalid environment, please check your .env file.')
}

export default featureFlags
