/* example styles for UI kit */

.container {
  position: relative;
  display: inline-flex;
  margin-left: 10rem;
}

.account-wrapper {
  position: absolute;
  right: -2.4rem;
  top: 100%;
  width: 21.3rem;
  z-index: 150;
}

.avatar {
  display: flex;
  align-items: center;
}

.default-wrapper {
  position: absolute;
  top: 100%;
  left: -2.4rem;
  z-index: 150;
  /* width: 15.6rem; */
}

.position-right {
  right: 0;
  left: unset;
}

.position-left {
  left: 0;
}
