import * as styles from "./navItem.module.css";
import classNames from "classnames";
import { Text } from "../typography";
import { NavItemProps } from "./NavItem.types";

/**
 * Nav Item
 * @param {string} text - nav item text
 * @param {boolean} isActive - is nav item active
 */

export const NavItem: React.FC<NavItemProps> = (props): JSX.Element => {
  const { children, isActive } = props;
  return (
    <div className={classNames(styles.link, isActive ? styles.active : "")}>
      <Text as="div" style="nav-text" color="alku-black" weight={isActive ? "bold" : "semibold"}>
        {children}
      </Text>
    </div>
  );
};
