import { ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "../../icon";
import * as inputStyles from "../input.module.css";
import { LabelProps } from "./types";

const Label = ({ noLabel, id, error, showHighPriorityIcon, required, label }: LabelProps): ReactNode =>
  !noLabel ? (
    <label htmlFor={id} className={classNames(inputStyles["text-input-label"], error && inputStyles["error-label"])}>
      {showHighPriorityIcon && (
        <Icon symbol="ReportProblemOutlinedIcon" color="alku-brand-primary" size="sm" space="right" />
      )}
      {label}
      {required && <span className={inputStyles["text-input-required"]}>*</span>}
    </label>
  ) : (
    <div className={inputStyles["text-input-container-no-label"]} />
  );

export default Label;
