import React, { useState, useEffect, useRef, forwardRef } from "react";
import classNames from "classnames";
import { SelectInputProps } from "./Input.types";
import { Text } from "../typography/Text";
import * as styles from "./input.module.css";
import { Icon } from "../icon/Icon";

/**
 * SelectInputProps
 * @param {string} id - id of the input
 * @param {string} label - label of the input
 * @param {string} value - value of the input
 * @param {(value: string) => void} onChange - callback function when the input value changes
 * @param {boolean} required - if true, the input is required
 * @param {string} errorMessage - error message to be displayed when the input is invalid
 * @param {Array<{ value: string; label: string }>} options - array of options for the select input, where each option is an object with a value and label property
 * @param {boolean} noLabel - if true, the label is not displayed
 * @param {boolean} showHighPriorityIcon - if true, the high priority icon is displayed
 * @param {any} rest - any other props to be passed to the select input
 */
export const SingleSelect = forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    {
      id,
      label,
      value = "",
      onChange,
      required,
      options,
      errorMessage,
      noLabel = false,
      showHighPriorityIcon = false,
      ...rest
    },
    ref,
  ) => {
    const [selectedValue, setSelectedValue] = useState<string>(value);
    const internalRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.value = selectedValue;
      }
    }, [selectedValue]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;
      setSelectedValue(newValue);
      if (onChange) {
        onChange(event);
      }
    };

    // Attach internalRef to forwarded ref
    useEffect(() => {
      if (typeof ref === "function") {
        ref(internalRef.current);
      } else if (ref) {
        (ref as React.MutableRefObject<HTMLSelectElement | null>).current = internalRef.current;
      }
    }, [ref]);

    const ariaLabel = noLabel ? label : undefined;

    return (
      <>
        <div className={styles["select-input-container"]}>
          {!noLabel ? (
            <label
              htmlFor={id}
              className={classNames(styles["select-input-label"], errorMessage && styles["error-label"])}
            >
              {showHighPriorityIcon && (
                <Icon symbol="ReportProblemOutlinedIcon" color="alku-brand-primary" size="sm" space="right" />
              )}
              {label}
              {required && <span className={styles["text-input-required"]}>*</span>}
            </label>
          ) : (
            <div className={styles["text-input-container-no-label"]} />
          )}
          <select
            id={id}
            ref={internalRef}
            className={styles["select-input-field"]}
            required={required}
            onChange={handleChange}
            aria-label={ariaLabel}
            value={selectedValue}
            {...rest}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {errorMessage && (
          <Text style="small" color="alku-brand-primary" weight="semibold">
            {errorMessage}
          </Text>
        )}
      </>
    );
  },
);

SingleSelect.displayName = "SingleSelect";
