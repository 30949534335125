import classNames from "classnames";
import { CactusLoaderProps } from "./CactusLoader.types";
import cactusGif from "./CactusLoadingLandscape.gif";
import * as styles from "./cactus-loader.module.css";

/**
 *
 * @param {"sm" | "md" | "lg" | "xl"} [size] - The size of the loader, defaults to lg
 * @returns {React.ReactElement} The Cactus Loader component
 */
export const CactusLoader: React.FC<CactusLoaderProps> = ({ size = "lg" }) => {
  return (
    <div>
      <img className={classNames(styles["loader"], styles[size])} src={cactusGif} alt="Cactus Loading..." />
    </div>
  );
};
