import { useCallback, useState } from 'react'
import { getAccessToken } from '../../../../auth/msal/helpers'
import downloadCSVFromResponse from '../helpers/downloadCSVFromResponse'
import { type ListType } from '~/src/__generated__/gql-types'

const { REST_API_BASEURL } = process.env

type Success = boolean
interface HookResponse {
  error: Error | undefined
  loading: boolean
  exportCallList: () => Promise<Success>
}

export default function useExportCallListLazy(
  listType: ListType
): HookResponse {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const exportCallList = useCallback(async (): Promise<boolean> => {
    setLoading(true)
    try {
      const token = (await getAccessToken()) as string
      const response = await fetch(`${REST_API_BASEURL}/export`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ listType })
      })
      await downloadCSVFromResponse(response, listType)
      setLoading(false)
      return true
    } catch (error) {
      setError(error as Error)
      setLoading(false)
      return false
    }
  }, [listType])

  return {
    loading,
    error,
    exportCallList
  }
}
