.container {
  max-width: 172.8rem; /* designs are 1440, but this is my 16 inch laptop screen, might want to know what size desktop screens most alku users will be on */
  margin: auto;
  width: 100%;
  padding: 0 3.6rem;
  position: absolute;
  top: 30vh;
}

.img-container {
  max-width: 30.6rem;
  margin: 0 auto;
}

.info {
  margin-top: 5rem;
}

.logo {
  max-width: 30.6rem;
}

.spin-img {
  position: absolute;
  top: 0;
  animation: spin 3.7s infinite;
}

.animation-running {
  animation-play-state: running;
}

.animation-paused {
  animation-play-state: paused;
}

.error-container {
  max-width: 64.6rem;
}

@keyframes spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform-origin: 70% 42%;
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-origin: 70% 42%;
  }
  100% {
    transform: rotate(1800deg);
    transform-origin: 70% 42%;
  }
}
