.summary-string {
  margin-right: 1rem;
}

.status {
  margin-right: 0.3rem;

  &:not(:first-of-type) {
    margin-left: 1.2rem;
  }
}
