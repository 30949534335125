import { type ReactNode, createContext, useState, useContext } from 'react'

interface Error {
  hasError: boolean
  errorMessage: string
}

interface ErrorContextType extends Error {
  setError: (error: Error) => void
}

const ErrorContext = createContext<ErrorContextType>({
  hasError: false,
  errorMessage: '',
  setError: () => {}
})

const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<Error>({
    hasError: false,
    errorMessage: ''
  })

  return (
    <ErrorContext.Provider
      value={{
        hasError: error.hasError,
        errorMessage: error.errorMessage,
        setError
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}

export { ErrorContext, ErrorProvider }

export const useErrorHandler = (): ErrorContextType => {
  return useContext(ErrorContext)
}
