/* eslint-disable */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AWSTimestamp: { input: any; output: any }
}

export type AdGroup = {
  display_name: Scalars['String']['output']
  email: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type AddCompanyInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  bullhorn_id: Scalars['Int']['input']
  city: Scalars['String']['input']
  linkedin?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
  phone2?: InputMaybe<Scalars['String']['input']>
  postal: Scalars['String']['input']
  prospecting_reason?: InputMaybe<Scalars['String']['input']>
  state: Scalars['String']['input']
  timezone?: InputMaybe<TimeZone>
}

export type AddContactInput = {
  address_state?: InputMaybe<Scalars['String']['input']>
  company_id: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  first_name: Scalars['String']['input']
  imported_from?: InputMaybe<Scalars['String']['input']>
  last_name: Scalars['String']['input']
  /**   2 character limit */
  lead_type?: InputMaybe<LeadType>
  notes?: InputMaybe<Scalars['String']['input']>
  phone_direct?: InputMaybe<Scalars['String']['input']>
  phone_mobile?: InputMaybe<Scalars['String']['input']>
  phone_other?: InputMaybe<Scalars['String']['input']>
  preferred_phone?: InputMaybe<PhoneType>
  title?: InputMaybe<Scalars['String']['input']>
}

export type AddExistingDbaInput = {
  bullhorn_id: Scalars['Int']['input']
}

export type AmclDuplicateCompany = {
  contacts_count: Scalars['Int']['output']
  created_ts: Scalars['AWSTimestamp']['output']
  id: Scalars['ID']['output']
  owner: DuplicateOwner
  prospecting_reason?: Maybe<Scalars['String']['output']>
}

export type AmclDuplicateContact = {
  bullhorn_id?: Maybe<Scalars['Int']['output']>
  company_name: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  first_name: Scalars['String']['output']
  id: Scalars['ID']['output']
  last_name: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  owner: DuplicateOwner
  phone?: Maybe<Scalars['String']['output']>
  status: ContactStatus
  title?: Maybe<Scalars['String']['output']>
  type: DuplicateType
}

/**   Represents a company record in Bullhorn. */
export type BullhornCompany = {
  /**   Primary address line */
  address1?: Maybe<Scalars['String']['output']>
  /**   Secondary address line */
  address2?: Maybe<Scalars['String']['output']>
  /**   City name */
  city?: Maybe<Scalars['String']['output']>
  /**   Unique identifier in the Bullhorn system */
  id: Scalars['Int']['output']
  /**   Company name */
  name: Scalars['String']['output']
  /**   Primary phone number */
  phone?: Maybe<Scalars['String']['output']>
  /**   Postal or ZIP code */
  postal?: Maybe<Scalars['String']['output']>
  /**   State or province */
  state?: Maybe<Scalars['String']['output']>
  /**   Company's timezone */
  timezone?: Maybe<TimeZone>
}

export type BullhornDuplicateCompany = {
  address1: Scalars['String']['output']
  address2?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
  postal: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type BullhornDuplicateContact = {
  company_name: Scalars['String']['output']
  email: Scalars['String']['output']
  first_name: Scalars['String']['output']
  id: Scalars['Int']['output']
  last_name: Scalars['String']['output']
  owner: DuplicateOwner
  phone?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/**   Represents a call list in AMCL. */
export type CallList = {
  companies: Array<Company>
  /**   Unique identifier for the call list */
  id: Scalars['ID']['output']
  list_type: ListType
  new_companies: Scalars['Int']['output']
  summary: CallListSummary
  user_ids: EntityIds
}

export type CallListSummary = {
  companies_count: Scalars['Int']['output']
  contacts_count: Scalars['Int']['output']
  dba_count: Scalars['Int']['output']
  disqualified_count: Scalars['Int']['output']
  /**   hotfix, remove this later */
  dnc_companies_count?: Maybe<Scalars['Int']['output']>
  dnc_contacts_count?: Maybe<Scalars['Int']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  last_name?: Maybe<Scalars['String']['output']>
  new_contacts_count: Scalars['Int']['output']
  user_division?: Maybe<Scalars['String']['output']>
  user_ids: EntityIds
}

export type Category = {
  bullhorn_id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

/**   Represents a complete company record in AMCL. */
export type Company = {
  /**   Primary address line */
  address1?: Maybe<Scalars['String']['output']>
  /**   Secondary address line */
  address2?: Maybe<Scalars['String']['output']>
  /**   Identifier in the Bullhorn system */
  bullhorn_id?: Maybe<Scalars['Int']['output']>
  /**   City name */
  city?: Maybe<Scalars['String']['output']>
  /**   List of associated contacts */
  contacts: Array<Contact>
  /**   Timestamp of when the record was created */
  created_ts: Scalars['AWSTimestamp']['output']
  /**   Do Not Call flag (hotfix, to be removed) */
  dnc?: Maybe<Scalars['Boolean']['output']>
  /**   Number of duplicate records */
  duplicates: Scalars['Int']['output']
  /**   Unique identifier for the company in the AMCL system */
  id: Scalars['ID']['output']
  /**
   *   Deprecated: Use `id` instead
   * @deprecated Use `id` instead.
   */
  ids?: Maybe<EntityIds>
  /**   LinkedIn profile URL */
  linkedin?: Maybe<Scalars['String']['output']>
  /**   Company name */
  name: Scalars['String']['output']
  /**   Additional notes about the company */
  notes?: Maybe<Scalars['String']['output']>
  /**   Primary phone number */
  phone?: Maybe<Scalars['String']['output']>
  /**   Secondary phone number */
  phone2?: Maybe<Scalars['String']['output']>
  /**   Postal or ZIP code (required) */
  postal?: Maybe<Scalars['String']['output']>
  /**   Reason for Reason for Prospecting/Project */
  prospecting_reason?: Maybe<Scalars['String']['output']>
  /**   State or province (required) */
  state?: Maybe<Scalars['String']['output']>
  /**   Company's timezone */
  timezone?: Maybe<TimeZone>
}

/**   Represents a contact in AMCL. */
export type Contact = {
  address_state?: Maybe<Scalars['String']['output']>
  bullhorn_id?: Maybe<Scalars['Int']['output']>
  created_ts: Scalars['AWSTimestamp']['output']
  disqualify_notes?: Maybe<Scalars['String']['output']>
  disqualify_reason?: Maybe<Scalars['String']['output']>
  disqualify_type?: Maybe<DisqualifyType>
  /**   hotfix, remove this */
  duplicates: DupCounts
  email?: Maybe<Scalars['String']['output']>
  first_name: Scalars['String']['output']
  has_duplicates: Scalars['Boolean']['output']
  /**   Unique identifier for the contact in the AMCL system */
  id: Scalars['ID']['output']
  /** @deprecated Use `id` instead. */
  ids: EntityIds
  last_contacted?: Maybe<Scalars['AWSTimestamp']['output']>
  last_name: Scalars['String']['output']
  lead_type?: Maybe<LeadType>
  notes?: Maybe<Scalars['String']['output']>
  phone_direct?: Maybe<Scalars['String']['output']>
  phone_mobile?: Maybe<Scalars['String']['output']>
  phone_other?: Maybe<Scalars['String']['output']>
  preferred_phone?: Maybe<PhoneType>
  recruiter?: Maybe<Scalars['String']['output']>
  ref_company?: Maybe<Scalars['String']['output']>
  /**   2 character limit */
  referral?: Maybe<Scalars['Boolean']['output']>
  referral_ts?: Maybe<Scalars['AWSTimestamp']['output']>
  status: ContactStatus
  title?: Maybe<Scalars['String']['output']>
  /**   automatically active to start */
  viewed_duplicates: Scalars['Boolean']['output']
}

export enum ContactSource {
  Billing = 'BILLING',
  ClienteVisit = 'CLIENTE_VISIT',
  ColdCall = 'COLD_CALL',
  HotLead = 'HOT_LEAD',
  Inbound = 'INBOUND',
  Indeed = 'INDEED',
  InHouse = 'IN_HOUSE',
  Linkedin = 'LINKEDIN',
  LostHiringManager = 'LOST_HIRING_MANAGER',
  MarketingEmail = 'MARKETING_EMAIL',
  Marketplace = 'MARKETPLACE',
  Reference = 'REFERENCE',
  Referral = 'REFERRAL',
  SocialMedia = 'SOCIAL_MEDIA',
  Swordfish = 'SWORDFISH',
  Thirdparty = 'THIRDPARTY',
  Tradeshow = 'TRADESHOW',
  Webinar = 'WEBINAR',
  WebsiteHubspot = 'WEBSITE_HUBSPOT',
  YellowLead = 'YELLOW_LEAD',
  Zoominfo = 'ZOOMINFO'
}

export enum ContactStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Dba = 'DBA',
  Disqualified = 'DISQUALIFIED',
  Requalified = 'REQUALIFIED'
}

export type CreateCompanyInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  linkedin?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
  phone2?: InputMaybe<Scalars['String']['input']>
  postal: Scalars['String']['input']
  prospecting_reason?: InputMaybe<Scalars['String']['input']>
  state: Scalars['String']['input']
  timezone?: InputMaybe<TimeZone>
}

export type DbaContactInput = {
  SUPP: Scalars['String']['input']
  action?: InputMaybe<DbaActionType>
  company_bullhorn_id: Scalars['Int']['input']
  division_bullhorn_id: Scalars['Int']['input']
  email: Scalars['String']['input']
  email_subscriptions?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >
  explorers_markets_bullhorn_id?: InputMaybe<Scalars['Int']['input']>
  first_name: Scalars['String']['input']
  intern_origin: InternOrigin
  last_name: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  phone_direct: Scalars['String']['input']
  phone_mobile?: InputMaybe<Scalars['String']['input']>
  phone_other?: InputMaybe<Scalars['String']['input']>
  source: ContactSource
  title: Scalars['String']['input']
  tradeshow?: InputMaybe<DbaTradeshow>
}

export enum DbaActionType {
  AmScreen = 'AM_SCREEN',
  AnnualReview = 'ANNUAL_REVIEW',
  CandidateCall = 'CANDIDATE_CALL',
  ClearanceApprovedHesitation = 'CLEARANCE_APPROVED_HESITATION',
  ClearanceCheckApproved = 'CLEARANCE_CHECK_APPROVED',
  ClearanceCheckRejected = 'CLEARANCE_CHECK_REJECTED',
  ClientVisit = 'CLIENT_VISIT',
  CrossoverApproved = 'CROSSOVER_APPROVED',
  CrossoverRejected = 'CROSSOVER_REJECTED',
  CrossoverUpdate = 'CROSSOVER_UPDATE',
  Email = 'EMAIL',
  ExitInterview = 'EXIT_INTERVIEW',
  FeMeeting = 'FE_MEETING',
  ForwardMarket = 'FORWARD_MARKET',
  GovernmentNotes = 'GOVERNMENT_NOTES',
  InhouseInterview = 'INHOUSE_INTERVIEW',
  InternManagerCellPhone = 'INTERN_MANAGER_CELL_PHONE',
  InternNotes = 'INTERN_NOTES',
  InternScreened = 'INTERN_SCREENED',
  InternSupps = 'INTERN_SUPPS',
  InterviewFeedback = 'INTERVIEW_FEEDBACK',
  InterviewQuestions = 'INTERVIEW_QUESTIONS',
  LeftMessage = 'LEFT_MESSAGE',
  ManagerReferral = 'MANAGER_REFERRAL',
  PhoneConnect = 'PHONE_CONNECT',
  Prescreened = 'PRESCREENED',
  Referral = 'REFERRAL',
  RequalifiedDba = 'REQUALIFIED_DBA',
  ScheduledCall = 'SCHEDULED_CALL',
  SecurityNote = 'SECURITY_NOTE',
  Sup = 'SUP',
  Text = 'TEXT',
  VideoCall = 'VIDEO_CALL'
}

export type DbaContactError = {
  message: Scalars['String']['output']
}

export type DbaEmailError = {
  message: Scalars['String']['output']
}

export type DbaError =
  | DbaContactError
  | DbaEmailError
  | DbaNoteError
  | DbaTaskError

export type DbaNoteError = {
  message: Scalars['String']['output']
}

export type DbaResponse = {
  contact?: Maybe<Contact>
  error?: Maybe<DbaError>
}

export type DbaTaskError = {
  message: Scalars['String']['output']
}

export enum DbaTradeshow {
  CernerHealthSummit_2024 = 'CERNER_HEALTH_SUMMIT_2024',
  EpicOchsnerPartnerSummit_2024 = 'EPIC_OCHSNER_PARTNER_SUMMIT_2024',
  EpicUgm_2024 = 'EPIC_UGM_2024',
  GlobalPrivacySummit_2024 = 'GLOBAL_PRIVACY_SUMMIT_2024',
  Himss_2024 = 'HIMSS_2024',
  InforConnect_2024 = 'INFOR_CONNECT_2024',
  MedicalDeviceConference_2024 = 'MEDICAL_DEVICE_CONFERENCE_2024',
  OdtugKscope_2024 = 'ODTUG_KSCOPE_2024',
  OracleAscend_2024 = 'ORACLE_ASCEND_2024',
  OracleCloudWorld_2024 = 'ORACLE_CLOUD_WORLD_2024',
  Raps_2024 = 'RAPS_2024',
  Rsa_2024 = 'RSA_2024',
  SalesforceDreamforce_2024 = 'SALESFORCE_DREAMFORCE_2024',
  SapphireNow_2024 = 'SAPPHIRE_NOW_2024',
  SapBostonWorldTour_2024 = 'SAP_BOSTON_WORLD_TOUR_2024',
  UkgAspire_2024 = 'UKG_ASPIRE_2024',
  UkgCampaign_2024 = 'UKG_CAMPAIGN_2024',
  Unavailable = 'UNAVAILABLE',
  WorkdayRising_2024 = 'WORKDAY_RISING_2024'
}

export enum DisqualifyType {
  BadFit = 'BAD_FIT',
  LacksSkills = 'LACKS_SKILLS',
  NoExperience = 'NO_EXPERIENCE',
  Other = 'OTHER'
}

export type DupCounts = {
  amcl: Scalars['Int']['output']
  bullhorn: Scalars['Int']['output']
}

export type DuplicateCompaniesResponse = {
  /**   Can be null if no result found */
  amcl: Array<AmclDuplicateCompany>
  bullhorn?: Maybe<BullhornDuplicateCompany>
}

export type DuplicateContactResponse = {
  amcl: Array<AmclDuplicateContact>
  bullhorn: Array<BullhornDuplicateContact>
}

export type DuplicateOwner = {
  division?: Maybe<Scalars['String']['output']>
  /**   AMCL or Bullhorn ID */
  first_name: Scalars['String']['output']
  id: Scalars['ID']['output']
  last_name: Scalars['String']['output']
}

/**
 *  ########
 *  Enums #
 * ########
 */
export enum DuplicateType {
  AmclCompany = 'AMCL_COMPANY',
  AmclHardContact = 'AMCL_HARD_CONTACT',
  AmclSoftContact = 'AMCL_SOFT_CONTACT',
  BullhornContact = 'BULLHORN_CONTACT'
}

export type EntityIds = {
  bullhorn_id?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
}

export type ExplorersMarkets = {
  bullhorn_id: Scalars['Int']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export enum InternOrigin {
  No = 'NO',
  Yes = 'YES'
}

export type Lead = {
  assigned_ts: Scalars['AWSTimestamp']['output']
  created_ts: Scalars['AWSTimestamp']['output']
  /**   Might not be needed */
  hiring_manager_name?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  manager_phone?: Maybe<Scalars['String']['output']>
  recruiter_id: Scalars['Int']['output']
  recruiter_name: Scalars['String']['output']
  ref_company: Scalars['ID']['output']
  type: LeadType
}

export enum LeadType {
  Hot = 'HOT',
  MarketplaceInfo = 'MARKETPLACE_INFO',
  Reference = 'REFERENCE',
  Yellow = 'YELLOW'
}

export enum ListType {
  Active = 'ACTIVE',
  Leads = 'LEADS',
  NotCalling = 'NOT_CALLING'
}

export type Mutation = {
  addCompany?: Maybe<Company>
  addContact?: Maybe<Contact>
  /**
   *   Adds an existing DBA to a contact in the AMCL system.
   *  This mutation does the following:
   *  1. Verifies the user has a Bullhorn ID.
   *  2. Retrieves the existing DBA contact from Snowflake using the provided Bullhorn ID.
   *  3. Updates the AMCL contact with the Bullhorn ID and sets its status to DBA.
   *  4. If the contact is associated with a company in the leads list, it moves the contact to the active list.
   *  The id parameter is the unique identifier for the contact in the AMCL database.
   *  The input contains the Bullhorn ID of the existing DBA to be added.
   *  Returns the updated Contact object.
   */
  addExistingDba?: Maybe<Contact>
  archiveList: CallList
  copyCompany?: Maybe<Company>
  /**   Verify company has required fields, insert into Bullhorn, set Bullhorn ID in database, and return the Company. */
  createBhCompany: Company
  createBhCompanyFromLead?: Maybe<Company>
  createCompany?: Maybe<Company>
  /**
   *   Converts a contact into a DBA status and creates a corresponding entry in Bullhorn.
   *  This mutation performs the following actions:
   *  1. Verifies the user.
   *  2. Begins a database transaction.
   *  3. Prepares the contact data for Bullhorn, mapping AMCL fields to Bullhorn fields.
   *  4. Creates a new client contact in Bullhorn using the prepared data.
   *  5. Updates the AMCL contact with DBA status, new Bullhorn ID, and timestamp.
   *  6. If the contact is associated with a company in the leads list, moves the contact to the active list.
   *  7. Updates duplicate counts for the contact.
   *  The id parameter is the unique identifier for the contact in the AMCL database.
   *  The input contains all necessary information to create the DBA contact in Bullhorn and update AMCL.
   *  Returns the updated DbaResponse object with new DBA status and Bullhorn information.
   *  Note: This operation is transactional and will rollback all AMCL database related changes if any part fails.
   */
  dbaContact: DbaResponse
  moveCompany?: Maybe<Company>
  moveContact?: Maybe<Contact>
  moveLeadToActive?: Maybe<Company>
  /**
   *   Toggles the referral status of a contact in the AMCL database.
   *  This mutation performs the following actions:
   *  1. Verifies that a valid contact ID is provided.
   *  2. Begins a database transaction.
   *  3. Updates the 'referral' field of the specified contact in the AMCL database.
   *  4. If the contact is not found, throws an error.
   *  5. Commits the transaction if successful.
   *  6. Retrieves updated duplicate counts for the contact.
   *  The id parameter is the unique identifier for the contact in the AMCL database.
   *  The flag parameter determines whether to set (true) or unset (false) the referral status.
   *  Returns the updated Contact object with the new referral status.
   *  Note: This operation is transactional within the AMCL database. If any part of the
   *  database operation fails, all changes will be rolled back to ensure data consistency.
   */
  referContact?: Maybe<Contact>
  /**
   *   Requalifies a contact, updating their status in both AMCL and Bullhorn systems.
   *  This mutation performs the following actions:
   *  1. Verifies the user is enabled and has a valid Bullhorn ID.
   *  2. Begins a database transaction.
   *  3. Checks if the contact exists and is not archived in the AMCL system.
   *  4. Adds a "Requalified DBA" note to the contact in Bullhorn with the provided notes.
   *  5. Updates the contact's owner in Bullhorn to the current user.
   *  6. Updates the contact's status to 'REQUALIFIED' in the AMCL database, sets the new Bullhorn ID, and records the requalification timestamp.
   *  7. Moves the contact to the active list if it's not already there.
   *  8. Updates duplicate counts for the contact.
   *  The id parameter is the unique identifier for the contact in the AMCL database.
   *  The input contains the new Bullhorn ID for the contact and any notes about the requalification.
   *  Returns the updated Contact object with the new 'REQUALIFIED' status and updated information.
   *  Note: This operation is transactional and will rollback all AMCL database related changes if any part fails.
   */
  requalifyContact: DbaResponse
  resetNewCompaniesCount: CallList
  /**
   *   Updates a company's information.
   *  The id parameter is the unique identifier for the company in the AMCL database.
   */
  updateCompany?: Maybe<Company>
  /**
   *   Updates a contact's information.
   *  The id parameter is the unique identifier for the contact in the AMCL database.
   */
  updateContact?: Maybe<Contact>
  /**
   *   Updates an incomplete company record in AMCL with data from a Bullhorn company.
   *  This mutation fetches data from Bullhorn using the provided bullhorn_id,
   *  updates the AMCL company record identified by company_id,
   *  and assigns the Bullhorn ID to the AMCL company.
   */
  updateIncompleteCompanyFromBh?: Maybe<Company>
  updateProfile: UserProfile
}

export type MutationAddCompanyArgs = {
  input: AddCompanyInput
}

export type MutationAddContactArgs = {
  input: AddContactInput
}

export type MutationAddExistingDbaArgs = {
  id: Scalars['ID']['input']
  input: AddExistingDbaInput
}

export type MutationArchiveListArgs = {
  listType: ListType
  userId: Scalars['ID']['input']
}

export type MutationCopyCompanyArgs = {
  companyId: Scalars['ID']['input']
  listType: ListType
}

export type MutationCreateBhCompanyArgs = {
  companyId: Scalars['String']['input']
}

export type MutationCreateBhCompanyFromLeadArgs = {
  company_id: Scalars['String']['input']
  input: CreateCompanyInput
}

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

export type MutationDbaContactArgs = {
  id: Scalars['ID']['input']
  input: DbaContactInput
}

export type MutationMoveCompanyArgs = {
  companyId: Scalars['ID']['input']
  listType: ListType
}

export type MutationMoveContactArgs = {
  contactId: Scalars['ID']['input']
  listType: ListType
}

export type MutationMoveLeadToActiveArgs = {
  companyId: Scalars['String']['input']
  prospectingReason?: InputMaybe<Scalars['String']['input']>
}

export type MutationReferContactArgs = {
  flag: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}

export type MutationRequalifyContactArgs = {
  id: Scalars['ID']['input']
  input: RequalifyContactInput
}

export type MutationResetNewCompaniesCountArgs = {
  listType: ListType
}

export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']['input']
  input: UpdateCompanyInput
}

export type MutationUpdateContactArgs = {
  contact: UpdateContactInput
  id: Scalars['ID']['input']
}

export type MutationUpdateIncompleteCompanyFromBhArgs = {
  bullhorn_id: Scalars['Int']['input']
  companyId: Scalars['ID']['input']
  linkedin?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phone2?: InputMaybe<Scalars['String']['input']>
  prospecting_reason?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<TimeZone>
}

export type MutationUpdateProfileArgs = {
  profile: UpdateProfileInput
}

export enum PhoneType {
  Direct = 'DIRECT',
  Mobile = 'MOBILE',
  Other = 'OTHER'
}

export type Query = {
  allCallListsSummaries: Array<Maybe<CallListSummary>>
  companies: Array<Maybe<BullhornCompany>>
  contactCategories: Array<Maybe<Category>>
  /** @deprecated Not implemented */
  contacts?: Maybe<Array<Maybe<Contact>>>
  currentUser: UserProfile
  findDuplicateCompanies: DuplicateCompaniesResponse
  findDuplicateContacts: DuplicateContactResponse
  /** @deprecated Use getList instead. */
  getActiveList: CallList
  getDistributionListOptions: Array<AdGroup>
  getDuplicateCompanies: Array<AmclDuplicateCompany>
  getDuplicateContacts: DuplicateContactResponse
  getExplorersMarkets: Array<ExplorersMarkets>
  getFavoriteSummaries: Array<Maybe<CallListSummary>>
  getLeadsData: Array<Lead>
  /** @deprecated Use getList instead. */
  getLeadsList: CallList
  getList: CallList
  /** @deprecated Use getList instead. */
  getNotCallingList: CallList
}

export type QueryCompaniesArgs = {
  text?: InputMaybe<Scalars['String']['input']>
}

export type QueryContactsArgs = {
  id: Scalars['Int']['input']
}

export type QueryFindDuplicateCompaniesArgs = {
  input: CreateCompanyInput
}

export type QueryFindDuplicateContactsArgs = {
  input: AddContactInput
}

export type QueryGetActiveListArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGetDuplicateCompaniesArgs = {
  bullhornId: Scalars['Int']['input']
}

export type QueryGetDuplicateContactsArgs = {
  contactId: Scalars['ID']['input']
}

export type QueryGetLeadsDataArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGetLeadsListArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGetListArgs = {
  listType: ListType
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGetNotCallingListArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type RequalifyContactInput = {
  action?: InputMaybe<DbaActionType>
  bullhorn_id: Scalars['Int']['input']
  email_subscriptions?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >
  notes: Scalars['String']['input']
}

export enum SortType {
  Alpha = 'ALPHA',
  AlphaReversed = 'ALPHA_REVERSED',
  Count = 'COUNT',
  NewOld = 'NEW_OLD',
  OldNew = 'OLD_NEW',
  Timezone = 'TIMEZONE'
}

export type Subscription = {
  addedCompany?: Maybe<Company>
}

/**   Todo: Rename to `Timezone` */
export enum TimeZone {
  Ast = 'AST',
  Cst = 'CST',
  Est = 'EST',
  Hst = 'HST',
  Mst = 'MST',
  Pst = 'PST',
  Sst = 'SST'
}

export type UpdateCompanyInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  is_archived?: InputMaybe<Scalars['Boolean']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phone2?: InputMaybe<Scalars['String']['input']>
  postal?: InputMaybe<Scalars['String']['input']>
  prospecting_reason?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<TimeZone>
}

export type UpdateContactInput = {
  address_state?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phone_direct?: InputMaybe<Scalars['String']['input']>
  phone_mobile?: InputMaybe<Scalars['String']['input']>
  phone_other?: InputMaybe<Scalars['String']['input']>
  preferred_phone?: InputMaybe<PhoneType>
  status?: InputMaybe<ContactStatus>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateProfileInput = {
  call_list_sort_by?: InputMaybe<SortType>
  favorites?: InputMaybe<Array<Scalars['String']['input']>>
  zi_credits_used?: InputMaybe<Scalars['Int']['input']>
  zi_max_credits?: InputMaybe<Scalars['Int']['input']>
}

/**   Represents a user's profile in AMCL. */
export type UserProfile = {
  bullhorn_id: Scalars['Int']['output']
  call_list_sort_by: SortType
  current_call_list?: Maybe<Scalars['String']['output']>
  current_leads_list?: Maybe<Scalars['String']['output']>
  current_not_calling_list?: Maybe<Scalars['String']['output']>
  favorites?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  first_name: Scalars['String']['output']
  /**   Unique identifier for the user in the AMCL system */
  id: Scalars['ID']['output']
  /** @deprecated Use `id` instead. */
  ids: EntityIds
  last_name: Scalars['String']['output']
  /**   User IDs */
  zoominfo_credits?: Maybe<ZoominfoCredits>
}

export type ZoominfoCredits = {
  max?: Maybe<Scalars['Int']['output']>
  used?: Maybe<Scalars['Int']['output']>
}

export type CompanyDetailsFragment = {
  __typename: 'Company'
  id: string
  bullhorn_id?: number | null
  name: string
  address1?: string | null
  address2?: string | null
  created_ts: any
  city?: string | null
  state?: string | null
  postal?: string | null
  timezone?: TimeZone | null
  phone?: string | null
  phone2?: string | null
  linkedin?: string | null
  notes?: string | null
  duplicates: number
  contacts: Array<{
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  }>
}

export type CompanyOnlyDetailsFragment = {
  __typename: 'Company'
  id: string
  bullhorn_id?: number | null
  name: string
  created_ts: any
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  postal?: string | null
  timezone?: TimeZone | null
  phone?: string | null
  phone2?: string | null
  linkedin?: string | null
  notes?: string | null
  duplicates: number
}

export type ContactDetailsFragment = {
  __typename: 'Contact'
  id: string
  bullhorn_id?: number | null
  ref_company?: string | null
  first_name: string
  last_name: string
  title?: string | null
  email?: string | null
  notes?: string | null
  disqualify_type?: DisqualifyType | null
  disqualify_notes?: string | null
  disqualify_reason?: string | null
  last_contacted?: any | null
  status: ContactStatus
  viewed_duplicates: boolean
  has_duplicates: boolean
  phone_direct?: string | null
  phone_mobile?: string | null
  phone_other?: string | null
  preferred_phone?: PhoneType | null
  address_state?: string | null
  referral?: boolean | null
  referral_ts?: any | null
  duplicates: { bullhorn: number; amcl: number }
}

export type DuplicatesFragment = {
  __typename: 'Contact'
  duplicates: { bullhorn: number; amcl: number }
}

export type LeadsListSummaryDetailsFragment = {
  __typename: 'CallListSummary'
  id: string
  first_name?: string | null
  last_name?: string | null
  user_division?: string | null
  companies_count: number
  contacts_count: number
}

export type CallListSummaryDetailsFragment = {
  __typename: 'CallListSummary'
  id: string
  first_name?: string | null
  last_name?: string | null
  user_division?: string | null
  companies_count: number
  contacts_count: number
  dba_count: number
  disqualified_count: number
}

export type UserIdsFragment = {
  __typename: 'EntityIds'
  id: string
  bullhorn_id?: number | null
}

export type AddCompanyMutationVariables = Exact<{
  input: AddCompanyInput
}>

export type AddCompanyMutation = {
  addCompany?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type AddContactMutationVariables = Exact<{
  input: AddContactInput
}>

export type AddContactMutation = {
  addContact?: {
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  } | null
}

export type AddContactForCxtMutationVariables = Exact<{
  input: AddContactInput
}>

export type AddContactForCxtMutation = {
  addContact?: {
    __typename: 'Contact'
    id: string
    first_name: string
    last_name: string
  } | null
}

export type AddExistingDbaMutationVariables = Exact<{
  contactId: Scalars['ID']['input']
  input: AddExistingDbaInput
}>

export type AddExistingDbaMutation = {
  addExistingDba?: {
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  } | null
}

export type ArchiveListMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  listType: ListType
}>

export type ArchiveListMutation = {
  archiveList: {
    __typename: 'CallList'
    id: string
    new_companies: number
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      timezone?: TimeZone | null
      phone?: string | null
      phone2?: string | null
      linkedin?: string | null
      notes?: string | null
      duplicates: number
      contacts: Array<{
        __typename: 'Contact'
        id: string
        bullhorn_id?: number | null
        ref_company?: string | null
        first_name: string
        last_name: string
        title?: string | null
        email?: string | null
        notes?: string | null
        disqualify_type?: DisqualifyType | null
        disqualify_notes?: string | null
        disqualify_reason?: string | null
        last_contacted?: any | null
        status: ContactStatus
        viewed_duplicates: boolean
        has_duplicates: boolean
        phone_direct?: string | null
        phone_mobile?: string | null
        phone_other?: string | null
        preferred_phone?: PhoneType | null
        address_state?: string | null
        referral?: boolean | null
        referral_ts?: any | null
        duplicates: { bullhorn: number; amcl: number }
      }>
    }>
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
  }
}

export type CopyCompanyMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  listType: ListType
}>

export type CopyCompanyMutation = {
  copyCompany?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput
}>

export type CreateCompanyMutation = {
  createCompany?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type CreateBhCompanyFromLeadMutationVariables = Exact<{
  company_id: Scalars['String']['input']
  input: CreateCompanyInput
}>

export type CreateBhCompanyFromLeadMutation = {
  createBhCompanyFromLead?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type DbaContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: DbaContactInput
}>

export type DbaContactMutation = {
  dbaContact: {
    contact?: {
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    } | null
    error?:
      | { __typename: 'DbaContactError'; message: string }
      | { __typename: 'DbaEmailError'; message: string }
      | { __typename: 'DbaNoteError'; message: string }
      | { __typename: 'DbaTaskError'; message: string }
      | null
  }
}

type DbaErrorDetails_DbaContactError_Fragment = {
  __typename: 'DbaContactError'
  message: string
}

type DbaErrorDetails_DbaEmailError_Fragment = {
  __typename: 'DbaEmailError'
  message: string
}

type DbaErrorDetails_DbaNoteError_Fragment = {
  __typename: 'DbaNoteError'
  message: string
}

type DbaErrorDetails_DbaTaskError_Fragment = {
  __typename: 'DbaTaskError'
  message: string
}

export type DbaErrorDetailsFragment =
  | DbaErrorDetails_DbaContactError_Fragment
  | DbaErrorDetails_DbaEmailError_Fragment
  | DbaErrorDetails_DbaNoteError_Fragment
  | DbaErrorDetails_DbaTaskError_Fragment

export type FindDuplicateCompaniesQueryVariables = Exact<{
  input: CreateCompanyInput
}>

export type FindDuplicateCompaniesQuery = {
  findDuplicateCompanies: {
    amcl: Array<{
      contacts_count: number
      created_ts: any
      id: string
      owner: {
        division?: string | null
        first_name: string
        id: string
        last_name: string
      }
    }>
    bullhorn?: {
      address1: string
      address2?: string | null
      city: string
      id: number
      name: string
      phone?: string | null
      postal: string
      state: string
    } | null
  }
}

export type FindDuplicateContactsQueryVariables = Exact<{
  company_id: Scalars['ID']['input']
  first_name: Scalars['String']['input']
  last_name: Scalars['String']['input']
  email?: InputMaybe<Scalars['String']['input']>
}>

export type FindDuplicateContactsQuery = {
  findDuplicateContacts: {
    bullhorn: Array<{
      id: number
      first_name: string
      last_name: string
      company_name: string
      title?: string | null
      phone?: string | null
      email: string
      owner: {
        id: string
        first_name: string
        last_name: string
        division?: string | null
      }
    }>
    amcl: Array<{
      id: string
      bullhorn_id?: number | null
      first_name: string
      last_name: string
      company_name: string
      title?: string | null
      phone?: string | null
      email?: string | null
      status: ContactStatus
      notes?: string | null
      type: DuplicateType
      owner: {
        id: string
        first_name: string
        last_name: string
        division?: string | null
      }
    }>
  }
}

export type GetActiveListQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetActiveListQuery = {
  getActiveList: {
    __typename: 'CallList'
    id: string
    new_companies: number
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      timezone?: TimeZone | null
      phone?: string | null
      phone2?: string | null
      linkedin?: string | null
      notes?: string | null
      duplicates: number
      contacts: Array<{
        __typename: 'Contact'
        id: string
        bullhorn_id?: number | null
        ref_company?: string | null
        first_name: string
        last_name: string
        title?: string | null
        email?: string | null
        notes?: string | null
        disqualify_type?: DisqualifyType | null
        disqualify_notes?: string | null
        disqualify_reason?: string | null
        last_contacted?: any | null
        status: ContactStatus
        viewed_duplicates: boolean
        has_duplicates: boolean
        phone_direct?: string | null
        phone_mobile?: string | null
        phone_other?: string | null
        preferred_phone?: PhoneType | null
        address_state?: string | null
        referral?: boolean | null
        referral_ts?: any | null
        duplicates: { bullhorn: number; amcl: number }
      }>
    }>
    user_ids: { bullhorn_id?: number | null; id: string }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
      dba_count: number
      disqualified_count: number
    }
  }
}

export type GetAllCallListSummariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAllCallListSummariesQuery = {
  allCallListsSummaries: Array<{
    __typename: 'CallListSummary'
    companies_count: number
    contacts_count: number
    dba_count: number
    disqualified_count: number
    user_division?: string | null
    first_name?: string | null
    id: string
    last_name?: string | null
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
  } | null>
}

export type GetCallListCompaniesForCxtQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetCallListCompaniesForCxtQuery = {
  getActiveList: {
    __typename: 'CallList'
    id: string
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      phone?: string | null
      duplicates: number
      contacts: Array<{ id: string }>
    }>
    user_ids: { bullhorn_id?: number | null; id: string }
  }
}

export type GetActiveListSummaryQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetActiveListSummaryQuery = {
  getActiveList: {
    __typename: 'CallList'
    id: string
    new_companies: number
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
      dba_count: number
      disqualified_count: number
    }
  }
}

export type GetCompaniesQueryVariables = Exact<{
  text?: InputMaybe<Scalars['String']['input']>
}>

export type GetCompaniesQuery = {
  companies: Array<{
    id: number
    name: string
    address1?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    postal?: string | null
    phone?: string | null
    timezone?: TimeZone | null
  } | null>
}

export type GetContactCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetContactCategoriesQuery = {
  contactCategories: Array<{ bullhorn_id: number; name: string } | null>
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  currentUser: {
    __typename: 'UserProfile'
    id: string
    bullhorn_id: number
    first_name: string
    last_name: string
    current_call_list?: string | null
    current_leads_list?: string | null
    call_list_sort_by: SortType
    favorites?: Array<string | null> | null
  }
}

export type GetDistributionListOptionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDistributionListOptionsQuery = {
  getDistributionListOptions: Array<{
    id: string
    display_name: string
    email: string
  }>
}

export type GetDuplicateCompaniesQueryVariables = Exact<{
  bullhornId: Scalars['Int']['input']
}>

export type GetDuplicateCompaniesQuery = {
  getDuplicateCompanies: Array<{
    contacts_count: number
    created_ts: any
    id: string
    owner: {
      division?: string | null
      first_name: string
      id: string
      last_name: string
    }
  }>
}

export type GetDuplicateContactsQueryVariables = Exact<{
  contactId: Scalars['ID']['input']
}>

export type GetDuplicateContactsQuery = {
  getDuplicateContacts: {
    bullhorn: Array<{
      id: number
      first_name: string
      last_name: string
      company_name: string
      title?: string | null
      phone?: string | null
      email: string
      owner: {
        id: string
        first_name: string
        last_name: string
        division?: string | null
      }
    }>
    amcl: Array<{
      id: string
      bullhorn_id?: number | null
      first_name: string
      last_name: string
      company_name: string
      title?: string | null
      phone?: string | null
      email?: string | null
      status: ContactStatus
      notes?: string | null
      type: DuplicateType
      owner: {
        id: string
        first_name: string
        last_name: string
        division?: string | null
      }
    }>
  }
}

export type GetExplorersMarketsQueryVariables = Exact<{ [key: string]: never }>

export type GetExplorersMarketsQuery = {
  getExplorersMarkets: Array<{
    __typename: 'ExplorersMarkets'
    bullhorn_id: number
    name: string
  }>
}

export type GetFavoriteSummariesQueryVariables = Exact<{ [key: string]: never }>

export type GetFavoriteSummariesQuery = {
  getFavoriteSummaries: Array<{
    __typename: 'CallListSummary'
    id: string
    contacts_count: number
    dba_count: number
    disqualified_count: number
    first_name?: string | null
    last_name?: string | null
    user_division?: string | null
    user_ids: { id: string }
  } | null>
}

export type GetLeadsDataQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetLeadsDataQuery = {
  getLeadsData: Array<{
    __typename: 'Lead'
    id: string
    type: LeadType
    ref_company: string
    recruiter_id: number
    recruiter_name: string
    created_ts: any
    assigned_ts: any
    hiring_manager_name?: string | null
    manager_phone?: string | null
  }>
}

export type GetLeadsListQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetLeadsListQuery = {
  getLeadsList: {
    __typename: 'CallList'
    id: string
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      timezone?: TimeZone | null
      phone?: string | null
      phone2?: string | null
      linkedin?: string | null
      notes?: string | null
      duplicates: number
      contacts: Array<{
        __typename: 'Contact'
        id: string
        bullhorn_id?: number | null
        ref_company?: string | null
        first_name: string
        last_name: string
        title?: string | null
        email?: string | null
        notes?: string | null
        disqualify_type?: DisqualifyType | null
        disqualify_notes?: string | null
        disqualify_reason?: string | null
        last_contacted?: any | null
        status: ContactStatus
        viewed_duplicates: boolean
        has_duplicates: boolean
        phone_direct?: string | null
        phone_mobile?: string | null
        phone_other?: string | null
        preferred_phone?: PhoneType | null
        address_state?: string | null
        referral?: boolean | null
        referral_ts?: any | null
        duplicates: { bullhorn: number; amcl: number }
      }>
    }>
    user_ids: { bullhorn_id?: number | null; id: string }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
    }
  }
}

export type GetLeadsListSummaryQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetLeadsListSummaryQuery = {
  getLeadsList: {
    __typename: 'CallList'
    id: string
    new_companies: number
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
    }
  }
}

export type GetNotCallingListQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetNotCallingListQuery = {
  getNotCallingList: {
    __typename: 'CallList'
    id: string
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      timezone?: TimeZone | null
      phone?: string | null
      phone2?: string | null
      linkedin?: string | null
      notes?: string | null
      duplicates: number
      contacts: Array<{
        __typename: 'Contact'
        id: string
        bullhorn_id?: number | null
        ref_company?: string | null
        first_name: string
        last_name: string
        title?: string | null
        email?: string | null
        notes?: string | null
        disqualify_type?: DisqualifyType | null
        disqualify_notes?: string | null
        disqualify_reason?: string | null
        last_contacted?: any | null
        status: ContactStatus
        viewed_duplicates: boolean
        has_duplicates: boolean
        phone_direct?: string | null
        phone_mobile?: string | null
        phone_other?: string | null
        preferred_phone?: PhoneType | null
        address_state?: string | null
        referral?: boolean | null
        referral_ts?: any | null
        duplicates: { bullhorn: number; amcl: number }
      }>
    }>
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
      dba_count: number
      disqualified_count: number
    }
  }
}

export type GetNotCallingListSummaryQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type GetNotCallingListSummaryQuery = {
  getNotCallingList: {
    __typename: 'CallList'
    id: string
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
      dba_count: number
      disqualified_count: number
    }
  }
}

export type MoveCompanyMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  listType: ListType
}>

export type MoveCompanyMutation = {
  moveCompany?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type MoveContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  listType: ListType
}>

export type MoveContactMutation = {
  moveContact?: {
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  } | null
}

export type MoveLeadToActiveMutationVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type MoveLeadToActiveMutation = {
  moveLeadToActive?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type ReferContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  flag: Scalars['Boolean']['input']
}>

export type ReferContactMutation = {
  referContact?: {
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  } | null
}

export type RequalifyContactMutationVariables = Exact<{
  contactId: Scalars['ID']['input']
  input: RequalifyContactInput
}>

export type RequalifyContactMutation = {
  requalifyContact: {
    contact?: {
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    } | null
    error?:
      | { __typename: 'DbaContactError'; message: string }
      | { __typename: 'DbaEmailError'; message: string }
      | { __typename: 'DbaNoteError'; message: string }
      | { __typename: 'DbaTaskError'; message: string }
      | null
  }
}

export type ResetNewCompaniesCountMutationVariables = Exact<{
  listType: ListType
}>

export type ResetNewCompaniesCountMutation = {
  resetNewCompaniesCount: {
    __typename: 'CallList'
    id: string
    new_companies: number
    companies: Array<{
      __typename: 'Company'
      id: string
      bullhorn_id?: number | null
      name: string
      address1?: string | null
      address2?: string | null
      created_ts: any
      city?: string | null
      state?: string | null
      postal?: string | null
      timezone?: TimeZone | null
      phone?: string | null
      phone2?: string | null
      linkedin?: string | null
      notes?: string | null
      duplicates: number
      contacts: Array<{
        __typename: 'Contact'
        id: string
        bullhorn_id?: number | null
        ref_company?: string | null
        first_name: string
        last_name: string
        title?: string | null
        email?: string | null
        notes?: string | null
        disqualify_type?: DisqualifyType | null
        disqualify_notes?: string | null
        disqualify_reason?: string | null
        last_contacted?: any | null
        status: ContactStatus
        viewed_duplicates: boolean
        has_duplicates: boolean
        phone_direct?: string | null
        phone_mobile?: string | null
        phone_other?: string | null
        preferred_phone?: PhoneType | null
        address_state?: string | null
        referral?: boolean | null
        referral_ts?: any | null
        duplicates: { bullhorn: number; amcl: number }
      }>
    }>
    user_ids: {
      __typename: 'EntityIds'
      id: string
      bullhorn_id?: number | null
    }
    summary: {
      __typename: 'CallListSummary'
      id: string
      first_name?: string | null
      last_name?: string | null
      user_division?: string | null
      companies_count: number
      contacts_count: number
      dba_count: number
      disqualified_count: number
    }
  }
}

export type UpdateActiveListSortPreferenceMutationVariables = Exact<{
  sortType: SortType
}>

export type UpdateActiveListSortPreferenceMutation = {
  updateProfile: {
    __typename: 'UserProfile'
    id: string
    call_list_sort_by: SortType
    current_call_list?: string | null
  }
}

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateCompanyInput
}>

export type UpdateCompanyMutation = {
  updateCompany?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    created_ts: any
    address1?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
  } | null
}

export type UpdateContactMutationVariables = Exact<{
  contact: UpdateContactInput
  id: Scalars['ID']['input']
}>

export type UpdateContactMutation = {
  updateContact?: {
    __typename: 'Contact'
    id: string
    bullhorn_id?: number | null
    ref_company?: string | null
    first_name: string
    last_name: string
    title?: string | null
    email?: string | null
    notes?: string | null
    disqualify_type?: DisqualifyType | null
    disqualify_notes?: string | null
    disqualify_reason?: string | null
    last_contacted?: any | null
    status: ContactStatus
    viewed_duplicates: boolean
    has_duplicates: boolean
    phone_direct?: string | null
    phone_mobile?: string | null
    phone_other?: string | null
    preferred_phone?: PhoneType | null
    address_state?: string | null
    referral?: boolean | null
    referral_ts?: any | null
    duplicates: { bullhorn: number; amcl: number }
  } | null
}

export type UpdateIncompleteCompanyFromBhMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  bullhorn_id: Scalars['Int']['input']
  linkedin?: InputMaybe<Scalars['String']['input']>
  phone2?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<TimeZone>
  notes?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateIncompleteCompanyFromBhMutation = {
  updateIncompleteCompanyFromBh?: {
    __typename: 'Company'
    id: string
    bullhorn_id?: number | null
    name: string
    address1?: string | null
    address2?: string | null
    created_ts: any
    city?: string | null
    state?: string | null
    postal?: string | null
    timezone?: TimeZone | null
    phone?: string | null
    phone2?: string | null
    linkedin?: string | null
    notes?: string | null
    duplicates: number
    contacts: Array<{
      __typename: 'Contact'
      id: string
      bullhorn_id?: number | null
      ref_company?: string | null
      first_name: string
      last_name: string
      title?: string | null
      email?: string | null
      notes?: string | null
      disqualify_type?: DisqualifyType | null
      disqualify_notes?: string | null
      disqualify_reason?: string | null
      last_contacted?: any | null
      status: ContactStatus
      viewed_duplicates: boolean
      has_duplicates: boolean
      phone_direct?: string | null
      phone_mobile?: string | null
      phone_other?: string | null
      preferred_phone?: PhoneType | null
      address_state?: string | null
      referral?: boolean | null
      referral_ts?: any | null
      duplicates: { bullhorn: number; amcl: number }
    }>
  } | null
}

export type UpdateProfileFavoritesMutationVariables = Exact<{
  favorites?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type UpdateProfileFavoritesMutation = {
  updateProfile: {
    __typename: 'UserProfile'
    id: string
    favorites?: Array<string | null> | null
    current_call_list?: string | null
  }
}

export type UpdateProfileZoomInfoCreditsMutationVariables = Exact<{
  credits?: InputMaybe<Scalars['Int']['input']>
}>

export type UpdateProfileZoomInfoCreditsMutation = {
  updateProfile: {
    __typename: 'UserProfile'
    id: string
    current_call_list?: string | null
    zoominfo_credits?: { used?: number | null; max?: number | null } | null
  }
}

export const DuplicatesFragmentDoc = gql`
  fragment Duplicates on Contact {
    __typename
    duplicates {
      bullhorn
      amcl
    }
  }
`
export const ContactDetailsFragmentDoc = gql`
  fragment ContactDetails on Contact {
    __typename
    id
    bullhorn_id
    ref_company
    first_name
    last_name
    title
    email
    notes
    disqualify_type
    disqualify_notes
    disqualify_reason
    last_contacted
    status
    viewed_duplicates
    has_duplicates
    phone_direct
    phone_mobile
    phone_other
    preferred_phone
    address_state
    referral
    referral_ts
    ...Duplicates
  }
  ${DuplicatesFragmentDoc}
`
export const CompanyDetailsFragmentDoc = gql`
  fragment CompanyDetails on Company {
    __typename
    id
    bullhorn_id
    name
    address1
    address2
    created_ts
    city
    state
    postal
    timezone
    phone
    phone2
    linkedin
    notes
    contacts {
      ...ContactDetails
    }
    duplicates
  }
  ${ContactDetailsFragmentDoc}
`
export const CompanyOnlyDetailsFragmentDoc = gql`
  fragment CompanyOnlyDetails on Company {
    __typename
    id
    bullhorn_id
    name
    created_ts
    address1
    address2
    city
    state
    postal
    timezone
    phone
    phone2
    linkedin
    notes
    duplicates
  }
`
export const LeadsListSummaryDetailsFragmentDoc = gql`
  fragment LeadsListSummaryDetails on CallListSummary {
    __typename
    id
    first_name
    last_name
    user_division
    companies_count
    contacts_count
  }
`
export const CallListSummaryDetailsFragmentDoc = gql`
  fragment CallListSummaryDetails on CallListSummary {
    __typename
    id
    first_name
    last_name
    user_division
    companies_count
    contacts_count
    dba_count
    disqualified_count
  }
`
export const UserIdsFragmentDoc = gql`
  fragment UserIds on EntityIds {
    __typename
    id
    bullhorn_id
  }
`
export const DbaErrorDetailsFragmentDoc = gql`
  fragment DbaErrorDetails on DbaError {
    ... on DbaContactError {
      __typename
      message
    }
    ... on DbaEmailError {
      __typename
      message
    }
    ... on DbaNoteError {
      __typename
      message
    }
    ... on DbaTaskError {
      __typename
      message
    }
  }
`
export const AddCompanyDocument = gql`
  mutation AddCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type AddCompanyMutationFn = Apollo.MutationFunction<
  AddCompanyMutation,
  AddCompanyMutationVariables
>

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyMutation,
    AddCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(
    AddCompanyDocument,
    options
  )
}
export type AddCompanyMutationHookResult = ReturnType<
  typeof useAddCompanyMutation
>
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyMutation,
  AddCompanyMutationVariables
>
export const AddContactDocument = gql`
  mutation addContact($input: AddContactInput!) {
    addContact(input: $input) {
      ...ContactDetails
    }
  }
  ${ContactDetailsFragmentDoc}
`
export type AddContactMutationFn = Apollo.MutationFunction<
  AddContactMutation,
  AddContactMutationVariables
>

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContactMutation,
    AddContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddContactMutation, AddContactMutationVariables>(
    AddContactDocument,
    options
  )
}
export type AddContactMutationHookResult = ReturnType<
  typeof useAddContactMutation
>
export type AddContactMutationResult = Apollo.MutationResult<AddContactMutation>
export type AddContactMutationOptions = Apollo.BaseMutationOptions<
  AddContactMutation,
  AddContactMutationVariables
>
export const AddContactForCxtDocument = gql`
  mutation addContactForCxt($input: AddContactInput!) {
    addContact(input: $input) {
      __typename
      id
      first_name
      last_name
    }
  }
`
export type AddContactForCxtMutationFn = Apollo.MutationFunction<
  AddContactForCxtMutation,
  AddContactForCxtMutationVariables
>

/**
 * __useAddContactForCxtMutation__
 *
 * To run a mutation, you first call `useAddContactForCxtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactForCxtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactForCxtMutation, { data, loading, error }] = useAddContactForCxtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactForCxtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContactForCxtMutation,
    AddContactForCxtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddContactForCxtMutation,
    AddContactForCxtMutationVariables
  >(AddContactForCxtDocument, options)
}
export type AddContactForCxtMutationHookResult = ReturnType<
  typeof useAddContactForCxtMutation
>
export type AddContactForCxtMutationResult =
  Apollo.MutationResult<AddContactForCxtMutation>
export type AddContactForCxtMutationOptions = Apollo.BaseMutationOptions<
  AddContactForCxtMutation,
  AddContactForCxtMutationVariables
>
export const AddExistingDbaDocument = gql`
  mutation AddExistingDba($contactId: ID!, $input: AddExistingDbaInput!) {
    addExistingDba(id: $contactId, input: $input) {
      ...ContactDetails
    }
  }
  ${ContactDetailsFragmentDoc}
`
export type AddExistingDbaMutationFn = Apollo.MutationFunction<
  AddExistingDbaMutation,
  AddExistingDbaMutationVariables
>

/**
 * __useAddExistingDbaMutation__
 *
 * To run a mutation, you first call `useAddExistingDbaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExistingDbaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExistingDbaMutation, { data, loading, error }] = useAddExistingDbaMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddExistingDbaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddExistingDbaMutation,
    AddExistingDbaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddExistingDbaMutation,
    AddExistingDbaMutationVariables
  >(AddExistingDbaDocument, options)
}
export type AddExistingDbaMutationHookResult = ReturnType<
  typeof useAddExistingDbaMutation
>
export type AddExistingDbaMutationResult =
  Apollo.MutationResult<AddExistingDbaMutation>
export type AddExistingDbaMutationOptions = Apollo.BaseMutationOptions<
  AddExistingDbaMutation,
  AddExistingDbaMutationVariables
>
export const ArchiveListDocument = gql`
  mutation archiveList($userId: ID!, $listType: ListType!) {
    archiveList(userId: $userId, listType: $listType) {
      __typename
      id
      new_companies
      companies {
        ...CompanyDetails
      }
      user_ids {
        ...UserIds
      }
    }
  }
  ${CompanyDetailsFragmentDoc}
  ${UserIdsFragmentDoc}
`
export type ArchiveListMutationFn = Apollo.MutationFunction<
  ArchiveListMutation,
  ArchiveListMutationVariables
>

/**
 * __useArchiveListMutation__
 *
 * To run a mutation, you first call `useArchiveListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveListMutation, { data, loading, error }] = useArchiveListMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useArchiveListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveListMutation,
    ArchiveListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveListMutation, ArchiveListMutationVariables>(
    ArchiveListDocument,
    options
  )
}
export type ArchiveListMutationHookResult = ReturnType<
  typeof useArchiveListMutation
>
export type ArchiveListMutationResult =
  Apollo.MutationResult<ArchiveListMutation>
export type ArchiveListMutationOptions = Apollo.BaseMutationOptions<
  ArchiveListMutation,
  ArchiveListMutationVariables
>
export const CopyCompanyDocument = gql`
  mutation copyCompany($companyId: ID!, $listType: ListType!) {
    copyCompany(companyId: $companyId, listType: $listType) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type CopyCompanyMutationFn = Apollo.MutationFunction<
  CopyCompanyMutation,
  CopyCompanyMutationVariables
>

/**
 * __useCopyCompanyMutation__
 *
 * To run a mutation, you first call `useCopyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCompanyMutation, { data, loading, error }] = useCopyCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useCopyCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyCompanyMutation,
    CopyCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyCompanyMutation, CopyCompanyMutationVariables>(
    CopyCompanyDocument,
    options
  )
}
export type CopyCompanyMutationHookResult = ReturnType<
  typeof useCopyCompanyMutation
>
export type CopyCompanyMutationResult =
  Apollo.MutationResult<CopyCompanyMutation>
export type CopyCompanyMutationOptions = Apollo.BaseMutationOptions<
  CopyCompanyMutation,
  CopyCompanyMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options)
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const CreateBhCompanyFromLeadDocument = gql`
  mutation CreateBhCompanyFromLead(
    $company_id: String!
    $input: CreateCompanyInput!
  ) {
    createBhCompanyFromLead(company_id: $company_id, input: $input) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type CreateBhCompanyFromLeadMutationFn = Apollo.MutationFunction<
  CreateBhCompanyFromLeadMutation,
  CreateBhCompanyFromLeadMutationVariables
>

/**
 * __useCreateBhCompanyFromLeadMutation__
 *
 * To run a mutation, you first call `useCreateBhCompanyFromLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBhCompanyFromLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBhCompanyFromLeadMutation, { data, loading, error }] = useCreateBhCompanyFromLeadMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBhCompanyFromLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBhCompanyFromLeadMutation,
    CreateBhCompanyFromLeadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBhCompanyFromLeadMutation,
    CreateBhCompanyFromLeadMutationVariables
  >(CreateBhCompanyFromLeadDocument, options)
}
export type CreateBhCompanyFromLeadMutationHookResult = ReturnType<
  typeof useCreateBhCompanyFromLeadMutation
>
export type CreateBhCompanyFromLeadMutationResult =
  Apollo.MutationResult<CreateBhCompanyFromLeadMutation>
export type CreateBhCompanyFromLeadMutationOptions = Apollo.BaseMutationOptions<
  CreateBhCompanyFromLeadMutation,
  CreateBhCompanyFromLeadMutationVariables
>
export const DbaContactDocument = gql`
  mutation dbaContact($id: ID!, $input: DBAContactInput!) {
    dbaContact(id: $id, input: $input) {
      contact {
        ...ContactDetails
      }
      error {
        ...DbaErrorDetails
      }
    }
  }
  ${ContactDetailsFragmentDoc}
  ${DbaErrorDetailsFragmentDoc}
`
export type DbaContactMutationFn = Apollo.MutationFunction<
  DbaContactMutation,
  DbaContactMutationVariables
>

/**
 * __useDbaContactMutation__
 *
 * To run a mutation, you first call `useDbaContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDbaContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dbaContactMutation, { data, loading, error }] = useDbaContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDbaContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DbaContactMutation,
    DbaContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DbaContactMutation, DbaContactMutationVariables>(
    DbaContactDocument,
    options
  )
}
export type DbaContactMutationHookResult = ReturnType<
  typeof useDbaContactMutation
>
export type DbaContactMutationResult = Apollo.MutationResult<DbaContactMutation>
export type DbaContactMutationOptions = Apollo.BaseMutationOptions<
  DbaContactMutation,
  DbaContactMutationVariables
>
export const FindDuplicateCompaniesDocument = gql`
  query FindDuplicateCompanies($input: CreateCompanyInput!) {
    findDuplicateCompanies(input: $input) {
      amcl {
        contacts_count
        created_ts
        id
        owner {
          division
          first_name
          id
          last_name
        }
      }
      bullhorn {
        address1
        address2
        city
        id
        name
        phone
        postal
        state
      }
    }
  }
`

/**
 * __useFindDuplicateCompaniesQuery__
 *
 * To run a query within a React component, call `useFindDuplicateCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDuplicateCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDuplicateCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindDuplicateCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDuplicateCompaniesQuery,
    FindDuplicateCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindDuplicateCompaniesQuery,
    FindDuplicateCompaniesQueryVariables
  >(FindDuplicateCompaniesDocument, options)
}
export function useFindDuplicateCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDuplicateCompaniesQuery,
    FindDuplicateCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindDuplicateCompaniesQuery,
    FindDuplicateCompaniesQueryVariables
  >(FindDuplicateCompaniesDocument, options)
}
export type FindDuplicateCompaniesQueryHookResult = ReturnType<
  typeof useFindDuplicateCompaniesQuery
>
export type FindDuplicateCompaniesLazyQueryHookResult = ReturnType<
  typeof useFindDuplicateCompaniesLazyQuery
>
export type FindDuplicateCompaniesQueryResult = Apollo.QueryResult<
  FindDuplicateCompaniesQuery,
  FindDuplicateCompaniesQueryVariables
>
export const FindDuplicateContactsDocument = gql`
  query FindDuplicateContacts(
    $company_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String
  ) {
    findDuplicateContacts(
      input: {
        company_id: $company_id
        first_name: $first_name
        last_name: $last_name
        email: $email
      }
    ) {
      bullhorn {
        id
        first_name
        last_name
        company_name
        title
        phone
        email
        owner {
          id
          first_name
          last_name
          division
        }
      }
      amcl {
        id
        bullhorn_id
        first_name
        last_name
        company_name
        title
        phone
        email
        status
        notes
        type
        owner {
          id
          first_name
          last_name
          division
        }
      }
    }
  }
`

/**
 * __useFindDuplicateContactsQuery__
 *
 * To run a query within a React component, call `useFindDuplicateContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDuplicateContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDuplicateContactsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindDuplicateContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDuplicateContactsQuery,
    FindDuplicateContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindDuplicateContactsQuery,
    FindDuplicateContactsQueryVariables
  >(FindDuplicateContactsDocument, options)
}
export function useFindDuplicateContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDuplicateContactsQuery,
    FindDuplicateContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindDuplicateContactsQuery,
    FindDuplicateContactsQueryVariables
  >(FindDuplicateContactsDocument, options)
}
export type FindDuplicateContactsQueryHookResult = ReturnType<
  typeof useFindDuplicateContactsQuery
>
export type FindDuplicateContactsLazyQueryHookResult = ReturnType<
  typeof useFindDuplicateContactsLazyQuery
>
export type FindDuplicateContactsQueryResult = Apollo.QueryResult<
  FindDuplicateContactsQuery,
  FindDuplicateContactsQueryVariables
>
export const GetActiveListDocument = gql`
  query GetActiveList($userId: ID!) {
    getActiveList(userId: $userId) {
      __typename
      id
      new_companies
      companies {
        ...CompanyDetails
      }
      user_ids {
        bullhorn_id
        id
      }
      summary {
        ...CallListSummaryDetails
      }
    }
  }
  ${CompanyDetailsFragmentDoc}
  ${CallListSummaryDetailsFragmentDoc}
`

/**
 * __useGetActiveListQuery__
 *
 * To run a query within a React component, call `useGetActiveListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActiveListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveListQuery,
    GetActiveListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetActiveListQuery, GetActiveListQueryVariables>(
    GetActiveListDocument,
    options
  )
}
export function useGetActiveListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveListQuery,
    GetActiveListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetActiveListQuery, GetActiveListQueryVariables>(
    GetActiveListDocument,
    options
  )
}
export type GetActiveListQueryHookResult = ReturnType<
  typeof useGetActiveListQuery
>
export type GetActiveListLazyQueryHookResult = ReturnType<
  typeof useGetActiveListLazyQuery
>
export type GetActiveListQueryResult = Apollo.QueryResult<
  GetActiveListQuery,
  GetActiveListQueryVariables
>
export const GetAllCallListSummariesDocument = gql`
  query getAllCallListSummaries {
    allCallListsSummaries {
      __typename
      companies_count
      contacts_count
      dba_count
      disqualified_count
      user_division
      first_name
      id
      last_name
      user_ids {
        ...UserIds
      }
    }
  }
  ${UserIdsFragmentDoc}
`

/**
 * __useGetAllCallListSummariesQuery__
 *
 * To run a query within a React component, call `useGetAllCallListSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCallListSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCallListSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCallListSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCallListSummariesQuery,
    GetAllCallListSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllCallListSummariesQuery,
    GetAllCallListSummariesQueryVariables
  >(GetAllCallListSummariesDocument, options)
}
export function useGetAllCallListSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCallListSummariesQuery,
    GetAllCallListSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllCallListSummariesQuery,
    GetAllCallListSummariesQueryVariables
  >(GetAllCallListSummariesDocument, options)
}
export type GetAllCallListSummariesQueryHookResult = ReturnType<
  typeof useGetAllCallListSummariesQuery
>
export type GetAllCallListSummariesLazyQueryHookResult = ReturnType<
  typeof useGetAllCallListSummariesLazyQuery
>
export type GetAllCallListSummariesQueryResult = Apollo.QueryResult<
  GetAllCallListSummariesQuery,
  GetAllCallListSummariesQueryVariables
>
export const GetCallListCompaniesForCxtDocument = gql`
  query GetCallListCompaniesForCxt($userId: ID!) {
    getActiveList(userId: $userId) {
      __typename
      id
      companies {
        __typename
        id
        bullhorn_id
        name
        address1
        address2
        created_ts
        city
        state
        postal
        phone
        duplicates
        contacts {
          id
        }
      }
      user_ids {
        bullhorn_id
        id
      }
    }
  }
`

/**
 * __useGetCallListCompaniesForCxtQuery__
 *
 * To run a query within a React component, call `useGetCallListCompaniesForCxtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallListCompaniesForCxtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallListCompaniesForCxtQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCallListCompaniesForCxtQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCallListCompaniesForCxtQuery,
    GetCallListCompaniesForCxtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCallListCompaniesForCxtQuery,
    GetCallListCompaniesForCxtQueryVariables
  >(GetCallListCompaniesForCxtDocument, options)
}
export function useGetCallListCompaniesForCxtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCallListCompaniesForCxtQuery,
    GetCallListCompaniesForCxtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCallListCompaniesForCxtQuery,
    GetCallListCompaniesForCxtQueryVariables
  >(GetCallListCompaniesForCxtDocument, options)
}
export type GetCallListCompaniesForCxtQueryHookResult = ReturnType<
  typeof useGetCallListCompaniesForCxtQuery
>
export type GetCallListCompaniesForCxtLazyQueryHookResult = ReturnType<
  typeof useGetCallListCompaniesForCxtLazyQuery
>
export type GetCallListCompaniesForCxtQueryResult = Apollo.QueryResult<
  GetCallListCompaniesForCxtQuery,
  GetCallListCompaniesForCxtQueryVariables
>
export const GetActiveListSummaryDocument = gql`
  query GetActiveListSummary($userId: ID!) {
    getActiveList(userId: $userId) {
      __typename
      id
      new_companies
      user_ids {
        ...UserIds
      }
      summary {
        ...CallListSummaryDetails
      }
    }
  }
  ${UserIdsFragmentDoc}
  ${CallListSummaryDetailsFragmentDoc}
`

/**
 * __useGetActiveListSummaryQuery__
 *
 * To run a query within a React component, call `useGetActiveListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveListSummaryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActiveListSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveListSummaryQuery,
    GetActiveListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetActiveListSummaryQuery,
    GetActiveListSummaryQueryVariables
  >(GetActiveListSummaryDocument, options)
}
export function useGetActiveListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveListSummaryQuery,
    GetActiveListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetActiveListSummaryQuery,
    GetActiveListSummaryQueryVariables
  >(GetActiveListSummaryDocument, options)
}
export type GetActiveListSummaryQueryHookResult = ReturnType<
  typeof useGetActiveListSummaryQuery
>
export type GetActiveListSummaryLazyQueryHookResult = ReturnType<
  typeof useGetActiveListSummaryLazyQuery
>
export type GetActiveListSummaryQueryResult = Apollo.QueryResult<
  GetActiveListSummaryQuery,
  GetActiveListSummaryQueryVariables
>
export const GetCompaniesDocument = gql`
  query GetCompanies($text: String) {
    companies(text: $text) {
      id
      name
      address1
      address2
      city
      state
      postal
      phone
      timezone
    }
  }
`

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesQuery,
    GetCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options
  )
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesQuery,
    GetCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options
  )
}
export type GetCompaniesQueryHookResult = ReturnType<
  typeof useGetCompaniesQuery
>
export type GetCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesLazyQuery
>
export type GetCompaniesQueryResult = Apollo.QueryResult<
  GetCompaniesQuery,
  GetCompaniesQueryVariables
>
export const GetContactCategoriesDocument = gql`
  query getContactCategories {
    contactCategories {
      bullhorn_id
      name
    }
  }
`

/**
 * __useGetContactCategoriesQuery__
 *
 * To run a query within a React component, call `useGetContactCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactCategoriesQuery,
    GetContactCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactCategoriesQuery,
    GetContactCategoriesQueryVariables
  >(GetContactCategoriesDocument, options)
}
export function useGetContactCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactCategoriesQuery,
    GetContactCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactCategoriesQuery,
    GetContactCategoriesQueryVariables
  >(GetContactCategoriesDocument, options)
}
export type GetContactCategoriesQueryHookResult = ReturnType<
  typeof useGetContactCategoriesQuery
>
export type GetContactCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetContactCategoriesLazyQuery
>
export type GetContactCategoriesQueryResult = Apollo.QueryResult<
  GetContactCategoriesQuery,
  GetContactCategoriesQueryVariables
>
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      __typename
      id
      bullhorn_id
      first_name
      last_name
      current_call_list
      current_leads_list
      call_list_sort_by
      favorites
    }
  }
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GetDistributionListOptionsDocument = gql`
  query getDistributionListOptions {
    getDistributionListOptions {
      id
      display_name
      email
    }
  }
`

/**
 * __useGetDistributionListOptionsQuery__
 *
 * To run a query within a React component, call `useGetDistributionListOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionListOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionListOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDistributionListOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDistributionListOptionsQuery,
    GetDistributionListOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDistributionListOptionsQuery,
    GetDistributionListOptionsQueryVariables
  >(GetDistributionListOptionsDocument, options)
}
export function useGetDistributionListOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistributionListOptionsQuery,
    GetDistributionListOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDistributionListOptionsQuery,
    GetDistributionListOptionsQueryVariables
  >(GetDistributionListOptionsDocument, options)
}
export type GetDistributionListOptionsQueryHookResult = ReturnType<
  typeof useGetDistributionListOptionsQuery
>
export type GetDistributionListOptionsLazyQueryHookResult = ReturnType<
  typeof useGetDistributionListOptionsLazyQuery
>
export type GetDistributionListOptionsQueryResult = Apollo.QueryResult<
  GetDistributionListOptionsQuery,
  GetDistributionListOptionsQueryVariables
>
export const GetDuplicateCompaniesDocument = gql`
  query GetDuplicateCompanies($bullhornId: Int!) {
    getDuplicateCompanies(bullhornId: $bullhornId) {
      contacts_count
      created_ts
      id
      owner {
        division
        first_name
        id
        last_name
      }
    }
  }
`

/**
 * __useGetDuplicateCompaniesQuery__
 *
 * To run a query within a React component, call `useGetDuplicateCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateCompaniesQuery({
 *   variables: {
 *      bullhornId: // value for 'bullhornId'
 *   },
 * });
 */
export function useGetDuplicateCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDuplicateCompaniesQuery,
    GetDuplicateCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDuplicateCompaniesQuery,
    GetDuplicateCompaniesQueryVariables
  >(GetDuplicateCompaniesDocument, options)
}
export function useGetDuplicateCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDuplicateCompaniesQuery,
    GetDuplicateCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDuplicateCompaniesQuery,
    GetDuplicateCompaniesQueryVariables
  >(GetDuplicateCompaniesDocument, options)
}
export type GetDuplicateCompaniesQueryHookResult = ReturnType<
  typeof useGetDuplicateCompaniesQuery
>
export type GetDuplicateCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetDuplicateCompaniesLazyQuery
>
export type GetDuplicateCompaniesQueryResult = Apollo.QueryResult<
  GetDuplicateCompaniesQuery,
  GetDuplicateCompaniesQueryVariables
>
export const GetDuplicateContactsDocument = gql`
  query GetDuplicateContacts($contactId: ID!) {
    getDuplicateContacts(contactId: $contactId) {
      bullhorn {
        id
        first_name
        last_name
        company_name
        title
        phone
        email
        owner {
          id
          first_name
          last_name
          division
        }
      }
      amcl {
        id
        bullhorn_id
        first_name
        last_name
        company_name
        title
        phone
        email
        status
        notes
        type
        owner {
          id
          first_name
          last_name
          division
        }
      }
    }
  }
`

/**
 * __useGetDuplicateContactsQuery__
 *
 * To run a query within a React component, call `useGetDuplicateContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateContactsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetDuplicateContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDuplicateContactsQuery,
    GetDuplicateContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDuplicateContactsQuery,
    GetDuplicateContactsQueryVariables
  >(GetDuplicateContactsDocument, options)
}
export function useGetDuplicateContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDuplicateContactsQuery,
    GetDuplicateContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDuplicateContactsQuery,
    GetDuplicateContactsQueryVariables
  >(GetDuplicateContactsDocument, options)
}
export type GetDuplicateContactsQueryHookResult = ReturnType<
  typeof useGetDuplicateContactsQuery
>
export type GetDuplicateContactsLazyQueryHookResult = ReturnType<
  typeof useGetDuplicateContactsLazyQuery
>
export type GetDuplicateContactsQueryResult = Apollo.QueryResult<
  GetDuplicateContactsQuery,
  GetDuplicateContactsQueryVariables
>
export const GetExplorersMarketsDocument = gql`
  query GetExplorersMarkets {
    getExplorersMarkets {
      __typename
      bullhorn_id
      name
    }
  }
`

/**
 * __useGetExplorersMarketsQuery__
 *
 * To run a query within a React component, call `useGetExplorersMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExplorersMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExplorersMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExplorersMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExplorersMarketsQuery,
    GetExplorersMarketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetExplorersMarketsQuery,
    GetExplorersMarketsQueryVariables
  >(GetExplorersMarketsDocument, options)
}
export function useGetExplorersMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExplorersMarketsQuery,
    GetExplorersMarketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetExplorersMarketsQuery,
    GetExplorersMarketsQueryVariables
  >(GetExplorersMarketsDocument, options)
}
export type GetExplorersMarketsQueryHookResult = ReturnType<
  typeof useGetExplorersMarketsQuery
>
export type GetExplorersMarketsLazyQueryHookResult = ReturnType<
  typeof useGetExplorersMarketsLazyQuery
>
export type GetExplorersMarketsQueryResult = Apollo.QueryResult<
  GetExplorersMarketsQuery,
  GetExplorersMarketsQueryVariables
>
export const GetFavoriteSummariesDocument = gql`
  query GetFavoriteSummaries {
    getFavoriteSummaries {
      __typename
      id
      contacts_count
      dba_count
      disqualified_count
      first_name
      last_name
      user_division
      user_ids {
        id
      }
    }
  }
`

/**
 * __useGetFavoriteSummariesQuery__
 *
 * To run a query within a React component, call `useGetFavoriteSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavoriteSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFavoriteSummariesQuery,
    GetFavoriteSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFavoriteSummariesQuery,
    GetFavoriteSummariesQueryVariables
  >(GetFavoriteSummariesDocument, options)
}
export function useGetFavoriteSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoriteSummariesQuery,
    GetFavoriteSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFavoriteSummariesQuery,
    GetFavoriteSummariesQueryVariables
  >(GetFavoriteSummariesDocument, options)
}
export type GetFavoriteSummariesQueryHookResult = ReturnType<
  typeof useGetFavoriteSummariesQuery
>
export type GetFavoriteSummariesLazyQueryHookResult = ReturnType<
  typeof useGetFavoriteSummariesLazyQuery
>
export type GetFavoriteSummariesQueryResult = Apollo.QueryResult<
  GetFavoriteSummariesQuery,
  GetFavoriteSummariesQueryVariables
>
export const GetLeadsDataDocument = gql`
  query GetLeadsData($userId: ID!) {
    getLeadsData(userId: $userId) {
      __typename
      id
      type
      ref_company
      recruiter_id
      recruiter_name
      created_ts
      assigned_ts
      hiring_manager_name
      manager_phone
    }
  }
`

/**
 * __useGetLeadsDataQuery__
 *
 * To run a query within a React component, call `useGetLeadsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLeadsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeadsDataQuery,
    GetLeadsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLeadsDataQuery, GetLeadsDataQueryVariables>(
    GetLeadsDataDocument,
    options
  )
}
export function useGetLeadsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeadsDataQuery,
    GetLeadsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLeadsDataQuery, GetLeadsDataQueryVariables>(
    GetLeadsDataDocument,
    options
  )
}
export type GetLeadsDataQueryHookResult = ReturnType<
  typeof useGetLeadsDataQuery
>
export type GetLeadsDataLazyQueryHookResult = ReturnType<
  typeof useGetLeadsDataLazyQuery
>
export type GetLeadsDataQueryResult = Apollo.QueryResult<
  GetLeadsDataQuery,
  GetLeadsDataQueryVariables
>
export const GetLeadsListDocument = gql`
  query GetLeadsList($userId: ID!) {
    getLeadsList(userId: $userId) {
      __typename
      id
      companies {
        ...CompanyDetails
      }
      user_ids {
        bullhorn_id
        id
      }
      summary {
        ...LeadsListSummaryDetails
      }
    }
  }
  ${CompanyDetailsFragmentDoc}
  ${LeadsListSummaryDetailsFragmentDoc}
`

/**
 * __useGetLeadsListQuery__
 *
 * To run a query within a React component, call `useGetLeadsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLeadsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeadsListQuery,
    GetLeadsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLeadsListQuery, GetLeadsListQueryVariables>(
    GetLeadsListDocument,
    options
  )
}
export function useGetLeadsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeadsListQuery,
    GetLeadsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLeadsListQuery, GetLeadsListQueryVariables>(
    GetLeadsListDocument,
    options
  )
}
export type GetLeadsListQueryHookResult = ReturnType<
  typeof useGetLeadsListQuery
>
export type GetLeadsListLazyQueryHookResult = ReturnType<
  typeof useGetLeadsListLazyQuery
>
export type GetLeadsListQueryResult = Apollo.QueryResult<
  GetLeadsListQuery,
  GetLeadsListQueryVariables
>
export const GetLeadsListSummaryDocument = gql`
  query GetLeadsListSummary($userId: ID!) {
    getLeadsList(userId: $userId) {
      id
      new_companies
      __typename
      user_ids {
        ...UserIds
      }
      summary {
        ...LeadsListSummaryDetails
      }
    }
  }
  ${UserIdsFragmentDoc}
  ${LeadsListSummaryDetailsFragmentDoc}
`

/**
 * __useGetLeadsListSummaryQuery__
 *
 * To run a query within a React component, call `useGetLeadsListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsListSummaryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLeadsListSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeadsListSummaryQuery,
    GetLeadsListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLeadsListSummaryQuery,
    GetLeadsListSummaryQueryVariables
  >(GetLeadsListSummaryDocument, options)
}
export function useGetLeadsListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeadsListSummaryQuery,
    GetLeadsListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLeadsListSummaryQuery,
    GetLeadsListSummaryQueryVariables
  >(GetLeadsListSummaryDocument, options)
}
export type GetLeadsListSummaryQueryHookResult = ReturnType<
  typeof useGetLeadsListSummaryQuery
>
export type GetLeadsListSummaryLazyQueryHookResult = ReturnType<
  typeof useGetLeadsListSummaryLazyQuery
>
export type GetLeadsListSummaryQueryResult = Apollo.QueryResult<
  GetLeadsListSummaryQuery,
  GetLeadsListSummaryQueryVariables
>
export const GetNotCallingListDocument = gql`
  query GetNotCallingList($userId: ID!) {
    getNotCallingList(userId: $userId) {
      __typename
      id
      companies {
        ...CompanyDetails
      }
      user_ids {
        ...UserIds
      }
      summary {
        ...CallListSummaryDetails
      }
    }
  }
  ${CompanyDetailsFragmentDoc}
  ${UserIdsFragmentDoc}
  ${CallListSummaryDetailsFragmentDoc}
`

/**
 * __useGetNotCallingListQuery__
 *
 * To run a query within a React component, call `useGetNotCallingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotCallingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotCallingListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotCallingListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotCallingListQuery,
    GetNotCallingListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNotCallingListQuery,
    GetNotCallingListQueryVariables
  >(GetNotCallingListDocument, options)
}
export function useGetNotCallingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotCallingListQuery,
    GetNotCallingListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotCallingListQuery,
    GetNotCallingListQueryVariables
  >(GetNotCallingListDocument, options)
}
export type GetNotCallingListQueryHookResult = ReturnType<
  typeof useGetNotCallingListQuery
>
export type GetNotCallingListLazyQueryHookResult = ReturnType<
  typeof useGetNotCallingListLazyQuery
>
export type GetNotCallingListQueryResult = Apollo.QueryResult<
  GetNotCallingListQuery,
  GetNotCallingListQueryVariables
>
export const GetNotCallingListSummaryDocument = gql`
  query GetNotCallingListSummary($userId: ID!) {
    getNotCallingList(userId: $userId) {
      __typename
      id
      user_ids {
        ...UserIds
      }
      summary {
        ...CallListSummaryDetails
      }
    }
  }
  ${UserIdsFragmentDoc}
  ${CallListSummaryDetailsFragmentDoc}
`

/**
 * __useGetNotCallingListSummaryQuery__
 *
 * To run a query within a React component, call `useGetNotCallingListSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotCallingListSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotCallingListSummaryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotCallingListSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotCallingListSummaryQuery,
    GetNotCallingListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetNotCallingListSummaryQuery,
    GetNotCallingListSummaryQueryVariables
  >(GetNotCallingListSummaryDocument, options)
}
export function useGetNotCallingListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotCallingListSummaryQuery,
    GetNotCallingListSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetNotCallingListSummaryQuery,
    GetNotCallingListSummaryQueryVariables
  >(GetNotCallingListSummaryDocument, options)
}
export type GetNotCallingListSummaryQueryHookResult = ReturnType<
  typeof useGetNotCallingListSummaryQuery
>
export type GetNotCallingListSummaryLazyQueryHookResult = ReturnType<
  typeof useGetNotCallingListSummaryLazyQuery
>
export type GetNotCallingListSummaryQueryResult = Apollo.QueryResult<
  GetNotCallingListSummaryQuery,
  GetNotCallingListSummaryQueryVariables
>
export const MoveCompanyDocument = gql`
  mutation moveCompany($companyId: ID!, $listType: ListType!) {
    moveCompany(companyId: $companyId, listType: $listType) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type MoveCompanyMutationFn = Apollo.MutationFunction<
  MoveCompanyMutation,
  MoveCompanyMutationVariables
>

/**
 * __useMoveCompanyMutation__
 *
 * To run a mutation, you first call `useMoveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCompanyMutation, { data, loading, error }] = useMoveCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useMoveCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveCompanyMutation,
    MoveCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveCompanyMutation, MoveCompanyMutationVariables>(
    MoveCompanyDocument,
    options
  )
}
export type MoveCompanyMutationHookResult = ReturnType<
  typeof useMoveCompanyMutation
>
export type MoveCompanyMutationResult =
  Apollo.MutationResult<MoveCompanyMutation>
export type MoveCompanyMutationOptions = Apollo.BaseMutationOptions<
  MoveCompanyMutation,
  MoveCompanyMutationVariables
>
export const MoveContactDocument = gql`
  mutation MoveContact($id: ID!, $listType: ListType!) {
    moveContact(contactId: $id, listType: $listType) {
      ...ContactDetails
    }
  }
  ${ContactDetailsFragmentDoc}
`
export type MoveContactMutationFn = Apollo.MutationFunction<
  MoveContactMutation,
  MoveContactMutationVariables
>

/**
 * __useMoveContactMutation__
 *
 * To run a mutation, you first call `useMoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveContactMutation, { data, loading, error }] = useMoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useMoveContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveContactMutation,
    MoveContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveContactMutation, MoveContactMutationVariables>(
    MoveContactDocument,
    options
  )
}
export type MoveContactMutationHookResult = ReturnType<
  typeof useMoveContactMutation
>
export type MoveContactMutationResult =
  Apollo.MutationResult<MoveContactMutation>
export type MoveContactMutationOptions = Apollo.BaseMutationOptions<
  MoveContactMutation,
  MoveContactMutationVariables
>
export const MoveLeadToActiveDocument = gql`
  mutation moveLeadToActive($companyId: String!) {
    moveLeadToActive(companyId: $companyId) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type MoveLeadToActiveMutationFn = Apollo.MutationFunction<
  MoveLeadToActiveMutation,
  MoveLeadToActiveMutationVariables
>

/**
 * __useMoveLeadToActiveMutation__
 *
 * To run a mutation, you first call `useMoveLeadToActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveLeadToActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveLeadToActiveMutation, { data, loading, error }] = useMoveLeadToActiveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMoveLeadToActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveLeadToActiveMutation,
    MoveLeadToActiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveLeadToActiveMutation,
    MoveLeadToActiveMutationVariables
  >(MoveLeadToActiveDocument, options)
}
export type MoveLeadToActiveMutationHookResult = ReturnType<
  typeof useMoveLeadToActiveMutation
>
export type MoveLeadToActiveMutationResult =
  Apollo.MutationResult<MoveLeadToActiveMutation>
export type MoveLeadToActiveMutationOptions = Apollo.BaseMutationOptions<
  MoveLeadToActiveMutation,
  MoveLeadToActiveMutationVariables
>
export const ReferContactDocument = gql`
  mutation ReferContact($id: ID!, $flag: Boolean!) {
    referContact(id: $id, flag: $flag) {
      ...ContactDetails
    }
  }
  ${ContactDetailsFragmentDoc}
`
export type ReferContactMutationFn = Apollo.MutationFunction<
  ReferContactMutation,
  ReferContactMutationVariables
>

/**
 * __useReferContactMutation__
 *
 * To run a mutation, you first call `useReferContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referContactMutation, { data, loading, error }] = useReferContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useReferContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReferContactMutation,
    ReferContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReferContactMutation,
    ReferContactMutationVariables
  >(ReferContactDocument, options)
}
export type ReferContactMutationHookResult = ReturnType<
  typeof useReferContactMutation
>
export type ReferContactMutationResult =
  Apollo.MutationResult<ReferContactMutation>
export type ReferContactMutationOptions = Apollo.BaseMutationOptions<
  ReferContactMutation,
  ReferContactMutationVariables
>
export const RequalifyContactDocument = gql`
  mutation RequalifyContact($contactId: ID!, $input: RequalifyContactInput!) {
    requalifyContact(id: $contactId, input: $input) {
      contact {
        ...ContactDetails
      }
      error {
        ...DbaErrorDetails
      }
    }
  }
  ${ContactDetailsFragmentDoc}
  ${DbaErrorDetailsFragmentDoc}
`
export type RequalifyContactMutationFn = Apollo.MutationFunction<
  RequalifyContactMutation,
  RequalifyContactMutationVariables
>

/**
 * __useRequalifyContactMutation__
 *
 * To run a mutation, you first call `useRequalifyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequalifyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requalifyContactMutation, { data, loading, error }] = useRequalifyContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequalifyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequalifyContactMutation,
    RequalifyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequalifyContactMutation,
    RequalifyContactMutationVariables
  >(RequalifyContactDocument, options)
}
export type RequalifyContactMutationHookResult = ReturnType<
  typeof useRequalifyContactMutation
>
export type RequalifyContactMutationResult =
  Apollo.MutationResult<RequalifyContactMutation>
export type RequalifyContactMutationOptions = Apollo.BaseMutationOptions<
  RequalifyContactMutation,
  RequalifyContactMutationVariables
>
export const ResetNewCompaniesCountDocument = gql`
  mutation resetNewCompaniesCount($listType: ListType!) {
    resetNewCompaniesCount(listType: $listType) {
      __typename
      id
      new_companies
      companies {
        ...CompanyDetails
      }
      user_ids {
        ...UserIds
      }
      summary {
        ...CallListSummaryDetails
      }
    }
  }
  ${CompanyDetailsFragmentDoc}
  ${UserIdsFragmentDoc}
  ${CallListSummaryDetailsFragmentDoc}
`
export type ResetNewCompaniesCountMutationFn = Apollo.MutationFunction<
  ResetNewCompaniesCountMutation,
  ResetNewCompaniesCountMutationVariables
>

/**
 * __useResetNewCompaniesCountMutation__
 *
 * To run a mutation, you first call `useResetNewCompaniesCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetNewCompaniesCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetNewCompaniesCountMutation, { data, loading, error }] = useResetNewCompaniesCountMutation({
 *   variables: {
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useResetNewCompaniesCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetNewCompaniesCountMutation,
    ResetNewCompaniesCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetNewCompaniesCountMutation,
    ResetNewCompaniesCountMutationVariables
  >(ResetNewCompaniesCountDocument, options)
}
export type ResetNewCompaniesCountMutationHookResult = ReturnType<
  typeof useResetNewCompaniesCountMutation
>
export type ResetNewCompaniesCountMutationResult =
  Apollo.MutationResult<ResetNewCompaniesCountMutation>
export type ResetNewCompaniesCountMutationOptions = Apollo.BaseMutationOptions<
  ResetNewCompaniesCountMutation,
  ResetNewCompaniesCountMutationVariables
>
export const UpdateActiveListSortPreferenceDocument = gql`
  mutation UpdateActiveListSortPreference($sortType: SortType!) {
    updateProfile(profile: { call_list_sort_by: $sortType }) {
      __typename
      id
      call_list_sort_by
      current_call_list
    }
  }
`
export type UpdateActiveListSortPreferenceMutationFn = Apollo.MutationFunction<
  UpdateActiveListSortPreferenceMutation,
  UpdateActiveListSortPreferenceMutationVariables
>

/**
 * __useUpdateActiveListSortPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateActiveListSortPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveListSortPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveListSortPreferenceMutation, { data, loading, error }] = useUpdateActiveListSortPreferenceMutation({
 *   variables: {
 *      sortType: // value for 'sortType'
 *   },
 * });
 */
export function useUpdateActiveListSortPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActiveListSortPreferenceMutation,
    UpdateActiveListSortPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateActiveListSortPreferenceMutation,
    UpdateActiveListSortPreferenceMutationVariables
  >(UpdateActiveListSortPreferenceDocument, options)
}
export type UpdateActiveListSortPreferenceMutationHookResult = ReturnType<
  typeof useUpdateActiveListSortPreferenceMutation
>
export type UpdateActiveListSortPreferenceMutationResult =
  Apollo.MutationResult<UpdateActiveListSortPreferenceMutation>
export type UpdateActiveListSortPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateActiveListSortPreferenceMutation,
    UpdateActiveListSortPreferenceMutationVariables
  >
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($id: ID!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      ...CompanyOnlyDetails
    }
  }
  ${CompanyOnlyDetailsFragmentDoc}
`
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UpdateCompanyDocument, options)
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>
export type UpdateCompanyMutationResult =
  Apollo.MutationResult<UpdateCompanyMutation>
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const UpdateContactDocument = gql`
  mutation updateContact($contact: UpdateContactInput!, $id: ID!) {
    updateContact(contact: $contact, id: $id) {
      ...ContactDetails
    }
  }
  ${ContactDetailsFragmentDoc}
`
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, options)
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>
export type UpdateContactMutationResult =
  Apollo.MutationResult<UpdateContactMutation>
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>
export const UpdateIncompleteCompanyFromBhDocument = gql`
  mutation UpdateIncompleteCompanyFromBh(
    $companyId: ID!
    $bullhorn_id: Int!
    $linkedin: String
    $phone2: String
    $timezone: TimeZone
    $notes: String
  ) {
    updateIncompleteCompanyFromBh(
      companyId: $companyId
      bullhorn_id: $bullhorn_id
      linkedin: $linkedin
      phone2: $phone2
      timezone: $timezone
      notes: $notes
    ) {
      ...CompanyDetails
    }
  }
  ${CompanyDetailsFragmentDoc}
`
export type UpdateIncompleteCompanyFromBhMutationFn = Apollo.MutationFunction<
  UpdateIncompleteCompanyFromBhMutation,
  UpdateIncompleteCompanyFromBhMutationVariables
>

/**
 * __useUpdateIncompleteCompanyFromBhMutation__
 *
 * To run a mutation, you first call `useUpdateIncompleteCompanyFromBhMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncompleteCompanyFromBhMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncompleteCompanyFromBhMutation, { data, loading, error }] = useUpdateIncompleteCompanyFromBhMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      bullhorn_id: // value for 'bullhorn_id'
 *      linkedin: // value for 'linkedin'
 *      phone2: // value for 'phone2'
 *      timezone: // value for 'timezone'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateIncompleteCompanyFromBhMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIncompleteCompanyFromBhMutation,
    UpdateIncompleteCompanyFromBhMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIncompleteCompanyFromBhMutation,
    UpdateIncompleteCompanyFromBhMutationVariables
  >(UpdateIncompleteCompanyFromBhDocument, options)
}
export type UpdateIncompleteCompanyFromBhMutationHookResult = ReturnType<
  typeof useUpdateIncompleteCompanyFromBhMutation
>
export type UpdateIncompleteCompanyFromBhMutationResult =
  Apollo.MutationResult<UpdateIncompleteCompanyFromBhMutation>
export type UpdateIncompleteCompanyFromBhMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateIncompleteCompanyFromBhMutation,
    UpdateIncompleteCompanyFromBhMutationVariables
  >
export const UpdateProfileFavoritesDocument = gql`
  mutation UpdateProfileFavorites($favorites: [String!]) {
    updateProfile(profile: { favorites: $favorites }) {
      __typename
      id
      favorites
      current_call_list
    }
  }
`
export type UpdateProfileFavoritesMutationFn = Apollo.MutationFunction<
  UpdateProfileFavoritesMutation,
  UpdateProfileFavoritesMutationVariables
>

/**
 * __useUpdateProfileFavoritesMutation__
 *
 * To run a mutation, you first call `useUpdateProfileFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileFavoritesMutation, { data, loading, error }] = useUpdateProfileFavoritesMutation({
 *   variables: {
 *      favorites: // value for 'favorites'
 *   },
 * });
 */
export function useUpdateProfileFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileFavoritesMutation,
    UpdateProfileFavoritesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProfileFavoritesMutation,
    UpdateProfileFavoritesMutationVariables
  >(UpdateProfileFavoritesDocument, options)
}
export type UpdateProfileFavoritesMutationHookResult = ReturnType<
  typeof useUpdateProfileFavoritesMutation
>
export type UpdateProfileFavoritesMutationResult =
  Apollo.MutationResult<UpdateProfileFavoritesMutation>
export type UpdateProfileFavoritesMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileFavoritesMutation,
  UpdateProfileFavoritesMutationVariables
>
export const UpdateProfileZoomInfoCreditsDocument = gql`
  mutation UpdateProfileZoomInfoCredits($credits: Int) {
    updateProfile(profile: { zi_credits_used: $credits }) {
      __typename
      id
      zoominfo_credits {
        used
        max
      }
      current_call_list
    }
  }
`
export type UpdateProfileZoomInfoCreditsMutationFn = Apollo.MutationFunction<
  UpdateProfileZoomInfoCreditsMutation,
  UpdateProfileZoomInfoCreditsMutationVariables
>

/**
 * __useUpdateProfileZoomInfoCreditsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileZoomInfoCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileZoomInfoCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileZoomInfoCreditsMutation, { data, loading, error }] = useUpdateProfileZoomInfoCreditsMutation({
 *   variables: {
 *      credits: // value for 'credits'
 *   },
 * });
 */
export function useUpdateProfileZoomInfoCreditsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileZoomInfoCreditsMutation,
    UpdateProfileZoomInfoCreditsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProfileZoomInfoCreditsMutation,
    UpdateProfileZoomInfoCreditsMutationVariables
  >(UpdateProfileZoomInfoCreditsDocument, options)
}
export type UpdateProfileZoomInfoCreditsMutationHookResult = ReturnType<
  typeof useUpdateProfileZoomInfoCreditsMutation
>
export type UpdateProfileZoomInfoCreditsMutationResult =
  Apollo.MutationResult<UpdateProfileZoomInfoCreditsMutation>
export type UpdateProfileZoomInfoCreditsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProfileZoomInfoCreditsMutation,
    UpdateProfileZoomInfoCreditsMutationVariables
  >

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    DbaError: [
      'DbaContactError',
      'DbaEmailError',
      'DbaNoteError',
      'DbaTaskError'
    ]
  }
}
export default result
