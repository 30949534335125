.subtitle {
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.separator {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    width: -webkit-fill-available;
    height: 1px;
    background: #ddd;
    margin-left: 2rem;
  }
}
