import { ReactNode } from "react";

export interface FlyoutProps {
  type?: "account" | "default";
  /** flyout menu items */
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  width: "md" | "lg";
}
