.alert {
  margin-bottom: 1.8rem;
}

.form {
  width: 90%;
}

.form-element {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
}

.modal-title {
  margin-bottom: 3.6rem;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.loading-container {
  margin-bottom: 4.2rem;
  width: 100%;
}

.loading {
  margin: 0 auto;
}
