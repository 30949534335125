import classNames from 'classnames'
import * as styles from './apploading.module.css'
import * as animationStyles from '../../animation.module.css'
import { useState } from 'react'
import { Alert, AlertType, Heading } from '@alku/ui-kit'

const AppLoading = ({
  errorMessage
}: {
  errorMessage?: string | boolean
}): JSX.Element => {
  const [animationPlaying, setAnimationPlaying] = useState<boolean>(true)
  const imageUrl = new URL('assets/only-c-logo.svg', import.meta.url)

  const noCimageUrl = new URL('assets/no-c-logo.svg', import.meta.url)
  return (
    <div className={styles.container}>
      <div className={styles['img-container']}>
        <img
          className={styles.logo}
          src={noCimageUrl.toString()}
          alt='AMCL The AM Call List'
        />
        <img
          className={classNames(
            styles.logo,
            styles['spin-img'],
            animationPlaying
              ? styles['animation-running']
              : styles['animation-paused']
          )}
          onAnimationIteration={() => {
            // do 1 more full animation before ending
            if (errorMessage !== undefined) setAnimationPlaying(false)
          }}
          src={imageUrl.toString()}
          alt='AMCL The AM Call List'
        />
      </div>
      <center className={styles.info}>
        {errorMessage === undefined ? (
          <Heading as='h1' level='one'>
            Loading
            <span className={animationStyles['ellipsis-anim']}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </Heading>
        ) : (
          <div className={styles['error-container']}>
            <Alert type={AlertType.Error} id='signup-error'>
              {`Error retrieving user data from AMCL database${
                typeof errorMessage === 'string' ? `: ${errorMessage}` : ''
              }`}
            </Alert>
          </div>
        )}
      </center>
    </div>
  )
}

export default AppLoading
