.flyout {
  padding: 1rem 2rem;
  box-shadow: var(--shadow-flyout from global);
  border-radius: 0.5rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  white-space: normal;
  text-align: left;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }

  &.default {
    background: var(--white from global);
    border: 0.1rem solid var(--grey-7 from global);
    border-radius: 0.5rem;
  }

  &.default,
  &.account {
    &.md {
      width: 15.6rem;
      max-width: 15.6rem;
    }

    &.lg {
      width: 25.4rem;
      max-width: 25.4rem;
    }

    &.xl {
      width: 35rem;
      max-width: 35rem;
    }
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  &.account {
    background-color: var(--grey-2 from global); /* consolidated from #f8f8f8 */

    &::after {
      width: 1.5rem;
      height: 1.5rem;
      background: var(--grey-2 from global);
      transform: rotate(45deg);
      position: absolute;
      right: 2rem;
      content: '';
      top: -0.8rem;
      border-bottom-right-radius: 0.5rem;
      box-shadow: -0.2rem -0.2rem 0.5rem -0.3rem rgba(0, 0, 0, 0.3);
    }
  }
}

.flyout-list-item-btn {
  width: 100%;
}

.list-extra {
  margin-left: 3.2rem;
}
