.amcl-fuze-number {
  font-family: inherit;
}
.amcl-fuze-logo {
  cursor: pointer !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 0 !important;
  display: inline !important;
  height: 14px !important;
  width: 14px !important;
}
