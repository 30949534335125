.company-container {
  margin-bottom: 2rem;
}

.wrapper {
  border: var(--border-call-list from global);
  border-radius: 0.1rem;

  &.open {
    border-bottom: 0;
  }
}

.warningWrapper {
  border-top: var(--border-call-list from global);
  border-bottom: 0;
  box-shadow: none;
}

.company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--white from global);
}

.companySummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--white from global);
  border-top: var(--border-call-list from global);
  position: relative;
  flex-wrap: wrap;
}

.date {
  width: 100%;
}

.companyAddContact {
  padding: 0 2rem 2rem;
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.duplicate {
  margin-left: 1rem;
}

.edit {
  padding-left: 0.6rem;
  position: relative;
}

.contacts-count {
  padding-left: 2rem;
  width: -webkit-fill-available;
}

.contacts-count button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.details {
  display: flex;
  align-items: flex-start;
}

.address {
  line-height: 1.8rem;
}

.phone {
  padding-left: 2rem;
}

.recruiter {
  padding-left: 2rem;
}

.phoneContainer {
  display: flex;
  flex-direction: column;
}

.leadContainer {
  display: flex;
  flex-direction: column;
}

.leadType {
  padding-left: 2rem;
  display: flex;
}

.leadType .leadIcon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.hqlink {
  padding-left: 0.5rem;
  text-decoration: underline;
  color: var(--dark-blue from global);
}

.link-flyout {
  position: relative;
}

.timezone {
  text-transform: uppercase;
  padding: 0.2rem 1rem;
  border-radius: 0.1rem;
  background-color: var(--timezone-tag from global);
  box-shadow: var(--shadow-tag from global);
  margin-left: 1.5rem;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.01rem;
}

.notes {
  max-height: 7.2rem;
  overflow-y: auto;
  max-width: 33rem;
  width: 100%;
  text-align: left;
  color: var(--black from global);
  font-size: 1.35rem;
  letter-spacing: -0.01rem;
  line-height: 1.33;
  margin-right: 7rem;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 0.1rem rgba(255, 255, 255, 0.5);
  }
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.leadsListContainer {
  width: 100%;
  border-bottom: var(--border-table from global);
}

.leadsListContainer table thead {
  border-left: 0;
}

.companyPlaceholder {
  font-style: italic;
}

.hidden {
  display: none;
}
