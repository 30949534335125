.default-wrapper {
  position: absolute;
  left: -19rem;
  width: 15.6rem;
}

.sticky-flyout-pos-top {
  top: 100%;
}

.sticky-flyout-pos-bottom {
  bottom: 100%;
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1.2rem;
}

.list-extra {
  margin-left: 2.4rem;
}

.btn-full-width {
  width: 100%;
}
