import { AccordionTypes } from '../../../components/call-list/company/CompanySection'

export const contactExpandedFilterData: Array<{
  label: string
  value: AccordionTypes
}> = [
  {
    label: 'Active Contacts',
    value: AccordionTypes.ActiveContacts
  },
  {
    label: 'DBA Contacts',
    value: AccordionTypes.DBAContacts
  },
  {
    label: 'Disqualified Contacts',
    value: AccordionTypes.DisqualifiedContacts
  }
]
