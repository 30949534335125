.alert {
  margin-bottom: 1.8rem;
}

.form {
  width: 70%;
}

.form.min-height {
  min-height: 40rem;
}

.form-element {
  margin-bottom: 2rem;
  width: 100%;
}

.back {
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.hide {
  display: none;
}

.note-link {
  appearance: none;
  background: none;
  border: none;
  padding: 0 0 0 0.2rem;
  text-decoration: underline;
  color: var(--bright-blue from global);
}

@custom-media --md (min-width: 62em); /* 992px */

@media (--md) {
  .group {
    display: flex;
    align-items: start;
  }

  .city {
    max-width: 44%;
  }

  .time {
    padding-left: 1.6rem;
    max-width: calc(44% - 1.1rem);
  }

  .country {
    max-width: calc(30% - 1.1rem);
  }

  .state {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .state,
  .zip {
    max-width: calc(33% - 1.6rem);
  }
}

.search {
  :global(.react-select__menu) {
    box-shadow: 0 0 1rem 0 rgba(9, 55, 124, 0.5);
    /* border: 0.1rem solid var(--grey-5 from global);
    border-top: none; */
  }

  :global(.react-select__option),
  :global(.react-select__menu-notice) {
    border: var(--border-add-contact from global);
    transition: 0.3s ease all;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    transition: all 0.3s;
    height: 4.6rem;
    display: flex;
    align-items: center;
    text-wrap: pretty;
  }
}

.duplicate-item {
  margin-bottom: 1.2rem;
}
