import { type SelectValue } from '~/src/components/shared/select/types'
import {
  type Company,
  type AddCompanyInput,
  type BullhornCompany
} from '../../../../__generated__/gql-types'
import * as Yup from 'yup'

/* Yup Add Company Schema */
export const AddCompanySchema = Yup.object().shape({
  name: Yup.string().trim().required().label('Company Name').max(100),
  address1: Yup.string().trim().required().label('Address').max(100),
  address2: Yup.string().label('Address Line 2').max(100),
  city: Yup.string().trim().required('City Is required').label('City').max(100),
  state: Yup.string().trim().required('Is required').label('State').max(50),
  country: Yup.string(),
  postal: Yup.string().when('country', {
    is: 'US',
    then: (AddCompanySchema: any) =>
      AddCompanySchema.trim()
        .required('Is required')
        .label('Zip')
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
          message:
            'Input must be a valid US “12345-1234" or "12345" postal code'
        }),
    otherwise: (AddCompanySchema: any) =>
      AddCompanySchema.trim()
        .required('Is required')
        .label('Zip')
        .matches(
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
          {
            message: 'Input must be a valid Canada ("X1X 1X1") postal code'
          }
        )
  }),
  timeZone: Yup.string(),
  phone: Yup.string()
    .trim()
    .label('Headquarters Phone Number')
    .max(40)
    .required('Headquarters Phone Number is required'), // TODO: awaiting PO decision about required fields that are missing data
  phone2: Yup.string().trim().label('Other Company Phone Number').max(40),
  notes: Yup.string(),
  linkedin: Yup.string()
    .url()
    .label('Company Linkedin Profile')
    .matches(/https?:\/\/(?:www\.)?linkedin\.com\/.*/, {
      message: 'URL must be a valid  https://linkedin.com URL'
    })
})

/* Yup Edit Company Schema */
export const UpdateCompanySchema = Yup.object({
  timeZone: Yup.string(),
  notes: Yup.string(),
  linkedin: Yup.string()
    .url()
    .label('Company Linkedin Profile')
    .matches(/https?:\/\/(?:www\.)?linkedin\.com\/.*/)
})

export const getCompanySelectData = <C extends Company>(
  companies?: C[] | null
): Array<SelectValue<string>> => {
  if (companies == null) return []

  const selectData: Array<SelectValue<string>> = companies.map((company) => {
    // This helper is used in the chrome extension company dropdown as well as the AMCL Add Company dropdown
    // The response from the companies query uses `id` as the bullhorn id where as the Active List response uses `bullhorn_id`
    // for the bullhorn id on the company response. Because of this the label logic is a little convoluted
    const companyIdString: string = company?.id ?? ''
    const companyBhIdString: number | undefined | null = company?.bullhorn_id

    return {
      value: companyIdString,
      label: `${company?.name ?? 'Unknown'} - BH: ${
        companyBhIdString ?? companyIdString
      }`
    }
  })
  return selectData
}

export type AddCompanyWithCountryInput = AddCompanyInput & { country?: string }
export const emptyValues: AddCompanyWithCountryInput = {
  name: '',
  bullhorn_id: 0,
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal: '',
  timezone: null,
  phone: '',
  phone2: '',
  notes: '',
  country: 'US'
}

export const FORM_STATE = {
  EDITING: 'EDITING',
  EDITING_LEAD: 'EDITING_LEAD',
  ADDING: 'ADDING',
  CREATING: 'CREATING',
  SEARCHING: 'SEARCHING'
} as const

export type CompanyWithId = Company & {
  id?: number
  bullhorn_id?: number
}

export type BullhornCompanyExtended = Company & BullhornCompany

export const hasError = (error: string | undefined): boolean => {
  if (error === undefined || error === '') return false
  if (error.length > 0) return true
  return false
}
