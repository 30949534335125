import { type FC } from 'react'
import { Button, Modal, Text } from '@alku/ui-kit'
import * as sharedStyles from '~/src/styles.module.css'
interface ArchiveDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onConfirm: () => void
  entityName: string
  type: 'company' | 'contact'
}

export const ArchiveModal: FC<ArchiveDialogProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  entityName,
  type
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title={
        type === 'company' ? 'Confirm Remove Company' : 'Confirm Remove Contact'
      }
      theme='alert'
    >
      {type === 'company' ? (
        <Text as='p' style='detail' color='alku-black' weight='semibold'>
          {`You are about to remove ${entityName} from your Call List along with
        all contacts associated. Are you sure you want to continue?`}
        </Text>
      ) : (
        <Text as='p' style='detail' color='alku-black' weight='semibold'>
          {`Removing a contact from your call list cannot be undone. Do you wish to proceed with removing ${entityName}?`}
        </Text>
      )}

      <br />
      <br />
      <Button
        as='button'
        type='button'
        style='primary-impact'
        onClick={() => {
          onConfirm()
          setIsOpen(false)
        }}
        ariaLabel={`Confirm Remove ${type}: ${entityName}`}
      >
        Confirm
      </Button>

      <Button
        as='button'
        type='button'
        style='primary-default'
        onClick={() => {
          setIsOpen(false)
        }}
        className={sharedStyles['cancel-button']}
      >
        Cancel
      </Button>
    </Modal>
  )
}
