.modal {
  box-shadow: var(--shadow-modal from global);
  border-radius: 0.1rem;
  border: none;
  max-width: 80rem;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .modal-close {
    position: absolute;
    top: 1.9rem;
    right: 2.1rem;
  }

  .modal-container {
    padding: 4.4rem 4.3rem;
  }

  .modal-title {
    margin-bottom: 3.6rem;
    display: flex;
    align-items: center;
  }
}
