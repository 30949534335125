import React, { useState } from "react";
import * as styles from "./tooltip.module.css";
import classNames from "classnames";
import { TooltipProps } from "./Tooltip.types";

/**
 *
 * Tooltip component
 * @param {ReactNode} children - The element to be wrapped
 * @param {ReactNode} content - The content to be displayed in the tooltip
 * @param {"top" | "bottom" | "left" | "right"} direction - The direction of the tooltip
 * @param {number} delay - The delay before the tooltip appears
 * @returns {JSX.Element} - The tooltip component
 */
export const Tooltip: React.FC<TooltipProps> = ({ children, content, direction = "top", delay = 400 }) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearTimeout(timeout);
    setActive(false);
  };

  return (
    <div className={styles["tooltip-wrapper"]} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <div className={classNames(styles[`tooltip-tip-${direction || "top"}`], styles["tooltip-tip"])}>{content}</div>
      )}
    </div>
  );
};
