.flyout-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.flyout-container > *:not(:nth-last-child(-n + 2)) {
  margin-right: 1.6rem;
}

.filters {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1.6rem;
  }

  :global(.react-select__control) {
    /* 2.2rem is equating to 22px here for some reason */
    width: 22rem;
  }
}

.position-left {
  left: 0;
}

.company-container {
  margin-top: 2rem;
  border: var(--border-call-list from global);
  border-radius: 0.1rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.company-container button {
  margin: 2rem 2rem 0 0;
}

.callList-subtitle {
  margin-top: 1.2rem;
  display: flex;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
}

/* large tab dropdown icon has too much space around it for proper alignment with back button */
.icon-top-offset {
  margin-top: -0.5rem;
}
