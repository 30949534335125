import type { FC, ReactNode } from "react";
import * as styles from "./flyout.module.css";
import { Button } from "../button/Button";
import { type ButtonProps } from "../button/Button.types";
import { Icon } from "../icon/Icon";
import { type IconProps, type IconOptions } from "../icon/Icon.types";
import { Text } from "../typography";

interface FlyoutListItemProps {
  buttonText: string;
  iconSymbol: IconOptions;
  iconProps?: Omit<Partial<IconProps>, "symbol">;
  buttonProps?: Omit<Partial<ButtonProps>, "children" | "onClick">;
  afterButton?: ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
}

export const FlyoutListItem: FC<FlyoutListItemProps> = (props) => {
  const { onClick, buttonProps, iconSymbol, iconProps, buttonText, afterButton, isDisabled = false } = props;

  return (
    <li className={styles["flyout-item"]}>
      <Button
        as={buttonProps?.as ?? "button"}
        style="icon-only"
        onClick={onClick}
        {...buttonProps}
        disabled={isDisabled}
        className={styles["flyout-list-item-btn"]}
      >
        <Icon
          space="right"
          color={isDisabled ? "disabled-check" : "alku-brand-primary"}
          size="sm"
          symbol={iconSymbol}
          {...iconProps}
        />
        <Text as="span" style="detail" color={isDisabled ? "disabled-check" : "alku-black"} weight="semibold">
          {buttonText}
        </Text>
      </Button>
      {afterButton}
    </li>
  );
};
