import { components } from "react-select";
import { Text } from "../../../typography";
import { type SelectValue } from "../types";

type O = (typeof components)["Option"];

export const makeOption = (): O => {
  const Option: O = ({ children, innerProps, ...props }) => {
    const data = props.data as SelectValue;

    return (
      <components.Option innerProps={{ ...innerProps, role: "option" }} {...props}>
        {data.phones ? (
          <>
            <Text style="detail" weight="semibold" as="div">
              {children}
            </Text>
            {data.phones.direct && (
              <Text style="note" weight="regular" as="div">
                D: {data.phones.direct}
              </Text>
            )}
            {data.phones.mobile && (
              <Text style="note" weight="regular" as="div">
                M: {data.phones.mobile}
              </Text>
            )}
            {data.phones.other && (
              <Text style="note" weight="regular" as="div">
                O: {data.phones.other}
              </Text>
            )}
          </>
        ) : (
          <>{children}</>
        )}
      </components.Option>
    );
  };
  return Option;
};
