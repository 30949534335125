.select {
  font-size: 1.35rem;
  width: 100%;
  line-height: 1;

  :global(.react-select__value-container),
  :global(.react-select__indicators) {
    border-bottom: 0.2rem solid var(--alku-black from global);
  }

  :global(.react-select__control) {
    min-height: 0;
  }

  :global(.react-select__control--is-focused + .react-select__menu) {
    outline: inherit;
  }

  :global(.react-select__control--is-disabled) {
    background-color: var(--grey-1 from global);
    color: var(--grey-6 from global);
  }

  :global(.react-select__value-container) {
    padding: 1.25rem 1.3rem;
  }

  :global(.react-select__value-container--is-multi) {
    gap: 0.3rem;
  }

  :global(.react-select__group-heading) {
    border: var(--border-add-contact from global);
    padding: 1.5rem 1.6rem;
    font-size: 1.4rem;
    transition: all 0.3s;
    font-weight: bold;
  }

  :global(.react-select__multi-value) {
    padding-inline: 0.4rem 0.2rem;
    line-height: 1.42857;
    border: 0.1rem solid var(--grey-7 from global);
    border-radius: 0.2rem;
  }

  :global(.react-select__group .react-select__option) {
    padding-left: 3rem;
  }

  :global(.react-select__indicators) {
    padding-right: 1rem;
  }

  :global(.react-select__menu) {
    background: var(--white from global);
  }

  :global(.react-select__option),
  :global(.react-select__menu-notice) {
    border: var(--border-add-contact from global);
    transition: 0.3s ease all;
    padding: 1.5rem 1.6rem;
    font-size: 1.4rem;

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    &:hover,
    &:global(.react-select__option--is-selected) {
      background-color: var(--grey-3 from global);
    }
  }

  &:not(.search) {
    :global(.react-select__option),
    :global(.react-select__menu-notice) {
      &:last-of-type {
        border-bottom-right-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }
    }
  }

  &.search {
    :global(.react-select__option),
    :global(.react-select__menu-notice) {
      border-left: 0;
      border-right: 0;
    }

    :global(
        .react-select__value-container--has-value + .react-select__indicators
      ) {
      :global(.react-select__dropdown-indicator) {
        display: none;
      }
    }

    :global(.react-select__placeholder) {
      color: var(--grey-5 from global);
    }
  }
}
