import {
  type ContactPhones,
  type SelectValue
} from '~/src/components/shared/select/types'
import { ContactStatus, type Company } from '../../../__generated__/gql-types'

// TODO: strong case for unit testing in this file

export const trimExtraCharacters = (str: string): string => {
  let trimmedString = str ?? ''
  // remove anything thats not a number or a letter including underscores (except for * and +)
  trimmedString = trimmedString.replace(/[^\w\s/*/+]/gi, '')
  // after characters are removed then remove spaces between digits, but NOT between letters
  trimmedString = trimmedString.replace(/(?<=\d) (?=\d)/g, '')

  return trimmedString
}
const checkForMatch = (
  existingOptions: Array<SelectValue<string>>,
  option: SelectValue<string>
): SelectValue[] => {
  return existingOptions.filter((entry) => {
    return Object.keys(option).every((key) => {
      if (key === 'phones') {
        const phones = entry.phones ?? {}
        const newPhones = option.phones ?? {}
        return Object.keys(phones).every((key) => {
          return (
            phones[key as keyof ContactPhones] ===
            newPhones[key as keyof ContactPhones]
          )
        })
      } else {
        return (
          entry[key as keyof SelectValue] === option[key as keyof SelectValue]
        )
      }
    })
  })
}

export const getContactNamesAndNumbersFromCompanies = (
  companies: Company[] | null
): Array<SelectValue<string>> => {
  const contactOptions: SelectValue[] = []

  // iterate over all the companies to get all contacts
  if (companies !== undefined && companies !== null) {
    companies.forEach((company) => {
      if (company !== null && Array.isArray(company?.contacts)) {
        company.contacts.forEach((contact) => {
          // if contact is not archived they're a valid search option
          if (contact?.status !== ContactStatus.Archived) {
            const name = `${contact?.first_name} ${contact?.last_name}`
            const phoneDirect = contact?.phone_direct as string
            const phoneMobile = contact?.phone_mobile as string
            const phoneOther = contact?.phone_other as string

            const directValue = trimExtraCharacters(phoneDirect ?? '')
            const mobileValue = trimExtraCharacters(phoneMobile ?? '')
            const otherValue = trimExtraCharacters(phoneOther ?? '')

            const option: SelectValue<string> = {
              label: name,
              value: `${name} ${directValue} ${mobileValue} ${otherValue}`,
              phones: {
                direct: phoneDirect ?? '',
                mobile: phoneMobile ?? '',
                other: phoneOther ?? ''
              }
            }

            // ensure no identical options already exist
            const matchingOptions = checkForMatch(contactOptions, option)
            if (matchingOptions.length === 0) contactOptions.push(option)
          }
        })
      }
    })
  }
  contactOptions.sort((contactA: SelectValue, contactB: SelectValue) =>
    (contactA.label ?? '').localeCompare(contactB.label ?? '')
  )
  return contactOptions
}
