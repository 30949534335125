import {
  type Contact,
  ContactStatus,
  type Company
} from '~/src/__generated__/gql-types'

export function getContactFilters(
  contactSearchQuery: string | null | undefined,
  data: Company
): any {
  const filterActive =
    typeof contactSearchQuery === 'string' && contactSearchQuery.length > 0

  const filteredContacts: Contact[] | null = filterActive
    ? (data?.contacts ?? []).filter((x: Contact | null) => {
        if (x?.status === ContactStatus.Archived) return false
        return `${x?.first_name as string} ${x?.last_name as string}`
          .toLowerCase()
          .includes(contactSearchQuery.toLowerCase())
      })
    : null

  const hidden = filteredContacts !== null && filteredContacts.length === 0

  const filteredActiveList: Contact[] = (filteredContacts ?? []).filter(
    (x: Contact | null) => x?.status === ContactStatus.Active
  )

  const filteredDbaList: Contact[] = (filteredContacts ?? []).filter(
    (x: Contact | null) =>
      x?.status === ContactStatus.Dba || x?.status === ContactStatus.Requalified
  )

  const filteredDisqualifiedList: Contact[] = (filteredContacts ?? []).filter(
    (x: Contact | null) => x?.status === ContactStatus.Disqualified
  )

  const showActive = Array.isArray(filteredContacts)
    ? filteredActiveList.length > 0
    : true

  const showDba = Array.isArray(filteredContacts)
    ? filteredDbaList.length > 0
    : true

  const showDisqualified = Array.isArray(filteredContacts)
    ? filteredDisqualifiedList.length > 0
    : true

  return {
    filterActive,
    hidden,
    filteredActiveList,
    filteredDbaList,
    filteredDisqualifiedList,
    showActive,
    showDba,
    showDisqualified
  }
}
