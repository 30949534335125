// https://react-select.com/home
import { useEffect, useMemo } from "react";
import Select, { components } from "react-select";
import classNames from "classnames";

import * as styles from "./select.module.css";
import { Text } from "../../typography";
import Label from "./Label";
import { VirtualizedMenuList } from "./VirtualizedMenuList";
import { type SelectValue, type SelectProps, type SelectValueValue } from "./types";
import { makeClearIndicator } from "./helpers/makeClearIndicator";
import { makeDropdownIndicator } from "./helpers/makeDropdownIndicator";
import { makeOption } from "./helpers/makeOption";
import { makeNoOptionsMessage } from "./helpers/makeNoOptionsMessage";
import { makeSingleValue } from "./helpers/makeSingleValue";

export const SelectDropdown = <T extends SelectValueValue = string>({
  defaultOptionSelected = false,
  isSearchable = false,
  required = false,
  name = "",
  readOnly = false,
  value,
  label,
  ariaLabel,
  className,
  onChange,
  inputLabelPrefix,
  screenReaderOnlyLabel = false,
  virtualize = false,
  maxMenuHeight = 250,
  noOptionsText = "No Options",
  error,
  onClear,
  isMulti = false,
  ...restProps
}: SelectProps<T>): JSX.Element => {
  const DropdownIndicator = useMemo(() => makeDropdownIndicator({ isSearchable }), [isSearchable]);
  const ClearIndicator = useMemo(() => makeClearIndicator({ name, isSearchable }), [isSearchable, name]);
  const NoOptionsMessage = useMemo(() => makeNoOptionsMessage({ noOptionsText }), [noOptionsText]);
  const SingleValue = useMemo(() => makeSingleValue({ inputLabelPrefix }), [inputLabelPrefix]);
  const Option = useMemo(() => makeOption(), []);

  const handleChange = (selection: Array<SelectValue<T>> | SelectValue<T>): void => {
    if (onChange) {
      if (Array.isArray(selection)) {
        const values: T[] = selection?.map((s) => s.value);
        onChange(values);
      } else {
        onChange(selection?.value ?? null);
      }
    }

    if (onClear && selection === null) onClear();
  };

  return (
    <>
      {label && <Label id={name} label={label} required={required} error={error} />}
      <Select
        {...restProps}
        components={{
          DropdownIndicator,
          ClearIndicator,
          NoOptionsMessage,
          SingleValue,
          Option,
          MenuList: virtualize ? VirtualizedMenuList : components.MenuList,
        }}
        className={classNames(styles.select, isSearchable ? styles.search : "", className)}
        aria-label={ariaLabel}
        unstyled
        styles={{
          control: (base, state) => ({
            ...base,
            outline: "inherit",
            "&:focus-within": {
              outline: "-webkit-focus-ring-color auto 1px",
              "box-shadow": "0 0 1rem 0 rgba(9, 55, 124, 0.5)",
            },
          }),
          option: (base, props) => ({
            ...base,
            ...(props.isFocused && {
              outline: "-webkit-focus-ring-color auto 1px",
            }),
          }),
        }}
        onChange={(v) => {
          handleChange(v as Array<SelectValue<T>>);
        }}
        isSearchable={isSearchable}
        classNamePrefix="react-select"
        isClearable={isSearchable}
        name={name}
        isDisabled={readOnly || restProps.isDisabled}
        maxMenuHeight={maxMenuHeight}
        isMulti={isMulti}
        value={value}
        // menuIsOpen // helpful for testing styles
      />
      {error && (
        <Text style="small" color="alku-brand-primary" weight="semibold">
          {error}
        </Text>
      )}
    </>
  );
};

export type SelectFieldProps<T extends SelectValueValue = string> = SelectProps<T> & {
  touched?: boolean;
  setValue?: (fieldName: string, value: SelectValueValue | T[]) => void | Promise<void>;
};

/**
 * SelectDropdownField is a dropdown that is part of a Form
 */

export const SelectDropdownField = <T extends SelectValueValue = string>({
  name = "",
  value = undefined,
  touched = false,
  onChange,
  maxMenuHeight = 250,
  noOptionsText = "No Options",
  setValue, // allows for different form implementations
  ...restProps
}: SelectFieldProps<T>): JSX.Element => {
  useEffect(() => {
    if (value != null && setValue) {
      if (Array.isArray(value)) {
        value.forEach((val) => setValue(name, val.value));
      } else {
        setValue(name, value.value);
      }
    }
  }, [setValue, value]);

  const handleChange: SelectProps<T>["onChange"] = (e) => {
    if (e != null && setValue) void setValue(name, e);
    if (typeof onChange === "function" && e !== null) onChange(e);
  };

  return (
    <>
      <SelectDropdown<T>
        {...restProps}
        value={value}
        touched={touched}
        onChange={handleChange}
        name={name}
        maxMenuHeight={maxMenuHeight}
        noOptionsText={noOptionsText}
      />
    </>
  );
};
