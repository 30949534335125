export const isDevEnvironment = (): boolean => {
  return Boolean(
    ['localhost', '-dev'].some((hostname) =>
      window?.location?.hostname?.includes?.(hostname)
    )
  )
}

export const isInExtension = (): boolean => {
  if (process.env.PLAYWRIGHT_TEST === 'true') return false
  return Boolean(typeof chrome !== 'undefined' && chrome?.runtime?.id)
}
