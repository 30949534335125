.popover {
  position: fixed;
  left: calc(50% - 13rem);
  width: 26rem;
  border: var(--border-add-button from global);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.5rem;
  text-align: center;
  border-radius: 0.4rem;
  background: var(--white from global);
  z-index: 10;
  box-shadow: var(--shadow-flyout from global);
  text-wrap: balance;
}
