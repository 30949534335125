import { useEffect, useRef, useState } from "react";
import { App, CactusProps, Link } from "./Cactus.types";
import { Icon } from "../icon";
import styles from "./cactus.module.css";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { getInitials } from "../helpers/getInitials";

export const Cactus = ({ userName, userRole, handleSignout, links }: CactusProps) => {
  const imageUrl = new URL("./cactus-logo.png", import.meta.url);

  const [flyoutVisible, setFlyoutVisible] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const firstName = userName.split(" ")[0];
  const lastName = userName.split(" ")[1];
  const [appName, setAppName] = useState("");
  const [currentRole, setCurrentRole] = useState<Link["role"]>(
    userRole === "Non-Producer" ? "Account Manager" : userRole,
  ); // Default role for non-producers is Account Manager
  const lastScrollY = useRef(0);
  const stickyHeaderRef = useRef<HTMLDivElement | null>(null);
  const threshold = 200;

  useEffect(() => {
    const handleScroll = () => {
      const stickyHeader = stickyHeaderRef.current;
      const currentScrollY = window.scrollY;

      if (stickyHeader) {
        if (currentScrollY > threshold) {
          if (currentScrollY > lastScrollY.current) {
            stickyHeader.classList.add(styles["header-hidden"]);
          } else {
            stickyHeader.classList.remove(styles["header-hidden"]);
          }
        }
        lastScrollY.current = currentScrollY;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const initials = getInitials(firstName, lastName);
    setUserInitials(initials);
  }, []);

  const toggleFlyout = () => {
    setFlyoutVisible(!flyoutVisible);
  };

  const userRoleData = links.find((role: Link) => role.role === currentRole);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const app = userRoleData?.apps.find((app: App) => app.route === currentPath);
    setAppName(app ? app.name : "");
  }, [currentRole]);

  const handleRoleChange = (role: Link["role"]) => {
    setCurrentRole(role);
  };

  return (
    <header className={styles["sticky-header"]} id="stickyHeader" ref={stickyHeaderRef}>
      <div className={styles["logo"]}>
        <img src={imageUrl.toString()} alt="Cactus Logo" />
      </div>
      <nav className={styles["header-nav"]}>
        <div className={styles["app-links"]}>
          {userRoleData?.apps.map((app: App) => (
            <a key={app.name} className={appName === app.name ? styles["app-selected"] : ""} href={app.route}>
              {app.name}
            </a>
          ))}
        </div>
        <div className={styles["user-icon"]} onClick={toggleFlyout}>
          <div className={styles["user-initials"]}>{userInitials}</div>
          <div className={styles["user-name"]}>{userName}</div>
          <div className="icon-container">
            <Icon symbol="ExpandMoreOutlinedIcon" color="cactus-green" size="md" rotate={flyoutVisible} />
          </div>
        </div>
        {flyoutVisible && (
          <div className={styles["flyout-menu"]}>
            <div className={styles["arrow-up"]}></div>
            {userRole === "Non-Producer" && (
              <div>
                <div
                  className={`${styles["flyout-item"]} ${
                    currentRole === "Account Manager" ? styles["flyout-item-selected"] : ""
                  }`}
                  onClick={() => handleRoleChange("Account Manager")}
                >
                  <Icon color={"cactus-green"} size="md" space="right">
                    <BusinessOutlinedIcon fontSize="large" />
                  </Icon>
                  Account Management View
                </div>
                <div
                  className={`${styles["flyout-item"]} ${
                    currentRole === "Recruiter" ? styles["flyout-item-selected"] : ""
                  }`}
                  onClick={() => handleRoleChange("Recruiter")}
                >
                  <Icon color="cactus-green" size="md" space="right">
                    <AccountBoxOutlinedIcon fontSize="large" />
                  </Icon>
                  Recruiter View
                </div>
              </div>
            )}
            <div onClick={handleSignout} className={styles["flyout-item"]}>
              <Icon symbol="LogoutOutlinedIcon" color="cactus-green" size="md" space="right" />
              Sign Out
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};
