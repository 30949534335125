.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color from global);
  background: #fff;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* CSS border triangles */
.tooltip-tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}

/* Top positioning */
.tooltip-tip-top {
  top: calc(30px * -1);
}

.tooltip-tip-top::before {
  top: 100%;
  border-top-color: #fff;
}

/* Right positioning */
.tooltip-tip-right {
  left: calc(100% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-tip-right::before {
  left: calc(6px * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: #fff;
}

/* Bottom positioning */
.tooltip-tip-bottom {
  bottom: calc(30px * -1);
}

.tooltip-tip-bottom::before {
  bottom: 100%;
  border-bottom-color: #fff;
}

/* Left positioning */
.tooltip-tip-left {
  left: auto;
  right: calc(100% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-tip-left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: #fff;
}
