import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { getApolloLinks, msalInstance } from './auth/msal/helpers'

import { App } from './App'
import { AuthProvider } from './auth/hooks/useAuth'
import { ErrorProvider } from './auth/hooks/useErrorHandler'
import { GlobalProvider } from './auth/hooks/useGlobal'
import { MsalProvider } from '@azure/msal-react'
import { createRoot } from 'react-dom/client'

export const client = new ApolloClient({
  link: getApolloLinks(),
  cache: new InMemoryCache({
    // NOTE: id fields _must_ be returned in responses for the cache to work
    addTypename: true
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all'
    }
  }
})

const container = document.getElementById('app')

if (container !== null) {
  const root = createRoot(container)
  root.render(
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <ErrorProvider>
          <AuthProvider>
            <GlobalProvider>
              <App />
            </GlobalProvider>
          </AuthProvider>
        </ErrorProvider>
      </ApolloProvider>
    </MsalProvider>
  )
} else {
  console.error('Cannot Find Element with #app')
}
