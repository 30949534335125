.loader {
  display: block;
  margin: 0 auto;
}

.sm {
  width: 100px;
}

.md {
  width: 200px;
}

.lg {
  width: 300px;
}

.xl {
  width: 400px;
}
