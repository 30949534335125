/** ONLY INTENDED FOR USE ON DEV ENVIRONMENTS
 * Component used for tel: links to simulate the HTML markup from the Fuze extension
 */

import { type FC, type HTMLAttributes } from 'react'
import * as styles from './fuze-link.module.css'

const imageUrl = new URL('./fuze-logo.svg', import.meta.url)

export const FuzeLink: FC<HTMLAttributes<HTMLAnchorElement>> = (props) => {
  const { children, ...restProps } = props

  if (!children) return null

  return (
    <a {...restProps}>
      <span className={styles['amcl-fuze-number']}>{children}</span>
      <img
        src={imageUrl.toString()}
        className={styles['amcl-fuze-logo']}
        alt=''
      />
    </a>
  )
}
