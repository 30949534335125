import { useMsal } from '@azure/msal-react'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { Link, NavLink } from 'react-router-dom'
import type { ReactNode } from 'react'
import {
  Avatar,
  FlyoutIconButton,
  FlyoutListItem,
  NavItem,
  Text
} from '@alku/ui-kit'
import * as styles from './header.module.css'
import { useAuth } from '../../auth/hooks/useAuth'
import featureFlags, { environment } from '~/src/config/config'

/**
 * Header
 */

export const Header = (): JSX.Element => {
  const { instance } = useMsal()
  const imageUrl = new URL('assets/amcl-logo.svg', import.meta.url)

  const handleLogout = (
    logoutType: string,
    instance: IPublicClientApplication
  ): void => {
    if (logoutType === 'popup') {
      void instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      })
    } else if (logoutType === 'redirect') {
      void instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      })
    }
  }

  const { first_name, last_name } = useAuth()

  const getInitials = (first_name: string, last_name: string): string => {
    return (
      first_name.substring(0, 1).toUpperCase() +
      last_name.substring(0, 1).toUpperCase()
    )
  }

  const profileButtonChildren = (): ReactNode => {
    return (
      <Text as='div' style='detail' color='alku-black'>
        <div className={styles.avatar}>
          <Avatar initials={getInitials(first_name, last_name)} size='sm' />
          {first_name} {last_name}{' '}
        </div>
      </Text>
    )
  }

  return (
    <div className={styles.header}>
      <div className={styles['logo-container']}>
        <Link to='/' className={styles.logo}>
          <img
            className={styles.img}
            src={imageUrl.toString()}
            alt='AMCL The AM Call List'
          />
        </Link>
        {featureFlags.showEnvironmentBadge && (
          <div className={styles.environment}>
            <span>{environment.toUpperCase()}</span>
          </div>
        )}
      </div>

      <nav aria-label='Main' id='main-nav' className={styles.nav}>
        <Link to='/' className={styles['logo-mobile']}>
          <img
            className={styles.img}
            src={imageUrl.toString()}
            alt='AMCL The AM Call List'
          />
        </Link>
        <div className={styles['account-wrapper']}>
          <FlyoutIconButton
            id='user-profile-menu'
            label='Sign Out'
            buttonProps={{
              style: 'icon-only',
              children: profileButtonChildren()
            }}
            iconProps={{
              symbol: 'ArrowDropDownOutlinedIcon',
              color: 'icon-red'
            }}
            position='left'
            type='account'
            flyoutWidth='md'
            flyoutChildren={[
              <FlyoutListItem
                key='header-sign-out'
                buttonText='Sign Out'
                iconSymbol='LogoutOutlinedIcon'
                buttonProps={{
                  id: 'profile-flyout-btn'
                }}
                onClick={() => {
                  handleLogout('redirect', instance)
                }}
              />
            ]}
          />
        </div>

        <ul className={styles.menu}>
          <li>
            <NavLink to='/'>
              {({ isActive }) => (
                <NavItem isActive={isActive}>My Lists</NavItem>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to='/directory'>
              {({ isActive }) => (
                <NavItem isActive={isActive}>Directory</NavItem>
              )}
            </NavLink>
          </li>
          {featureFlags.uiKit && (
            <li>
              <NavLink to='/ui-kit'>
                {({ isActive }) => (
                  <NavItem isActive={isActive}>UI Kit</NavItem>
                )}
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}
