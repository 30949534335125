import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import BlockIcon from "@mui/icons-material/Block";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarIcon from "@mui/icons-material/Star";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import CircleIcon from "@mui/icons-material/Circle";

import { ReactNode } from "react";

// TODO: use keyof instead of type?
// is there a way we can really only rely on the icon that needs to be imported
// (i.e. passing in the material JSX component to a prop instead of the string..)

export type IconOptions =
  | "QuestionMarkOutlinedIcon"
  | "AddOutlinedIcon"
  | "MoreVertOutlinedIcon"
  | "ArrowBackIosIcon"
  | "ArrowDropDownOutlinedIcon"
  | "LogoutOutlinedIcon"
  | "ExpandMoreOutlinedIcon"
  | "ChevronRightOutlinedIcon"
  | "CloseOutlinedIcon"
  | "ReportProblemOutlinedIcon"
  | "InfoOutlinedIcon"
  | "CheckCircleOutlineOutlinedIcon"
  | "ErrorOutlineOutlinedIcon"
  | "FileDownloadOutlinedIcon"
  | "CancelIcon"
  | "BusinessOutlinedIcon"
  | "WorkOutlineOutlinedIcon"
  | "ExitToAppOutlinedIcon"
  | "DeleteOutlinedIcon"
  | "EditOutlinedIcon"
  | "ChevronLeftOutlinedIcon"
  | "ExpandLessOutlinedIcon"
  | "PersonAddAlt1OutlinedIcon"
  | "AutorenewOutlinedIcon"
  | "CheckCircleOutlinedIcon"
  | "WarningAmberRoundedIcon"
  | "VisibilityOutlinedIcon"
  | "VerticalAlignBottomIcon"
  | "BlockIcon"
  | "RadioButtonCheckedOutlinedIcon"
  | "RadioButtonUncheckedOutlinedIcon"
  | "ReplayIcon"
  | "ThreePOutlinedIcon"
  | "PostAddOutlinedIcon"
  | "ContentCopyOutlinedIcon"
  | "StarBorderOutlinedIcon"
  | "StarIcon"
  | "SearchIcon"
  | "FileUploadOutlinedIcon"
  | "AttachFileOutlinedIcon"
  | "PhoneDisabledIcon"
  | "AddCommentOutlinedIcon"
  | "SpeakerNotesOffOutlinedIcon"
  | "ControlPointIcon"
  | "SyncIcon"
  | "LocalFireDepartmentIcon"
  | "MyLocationOutlinedIcon"
  | "StoreOutlinedIcon"
  | "ConnectWithoutContactOutlinedIcon"
  | "AssignmentOutlinedIcon"
  | "CircleIcon"
  | "PeopleOutlinedIcon";

export const iconOptions = {
  QuestionMarkOutlinedIcon,
  AddOutlinedIcon,
  MoreVertOutlinedIcon,
  ArrowBackIosIcon,
  ArrowDropDownOutlinedIcon,
  LogoutOutlinedIcon,
  ExpandMoreOutlinedIcon,
  ChevronRightOutlinedIcon,
  CloseOutlinedIcon,
  ReportProblemOutlinedIcon,
  InfoOutlinedIcon,
  CheckCircleOutlineOutlinedIcon,
  ErrorOutlineOutlinedIcon,
  FileDownloadOutlinedIcon,
  CancelIcon,
  BusinessOutlinedIcon,
  WorkOutlineOutlinedIcon,
  ExitToAppOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  ChevronLeftOutlinedIcon,
  ExpandLessOutlinedIcon,
  PersonAddAlt1OutlinedIcon,
  AutorenewOutlinedIcon,
  CheckCircleOutlinedIcon,
  WarningAmberRoundedIcon,
  VisibilityOutlinedIcon,
  VerticalAlignBottomIcon,
  BlockIcon,
  RadioButtonCheckedOutlinedIcon,
  RadioButtonUncheckedOutlinedIcon,
  ReplayIcon,
  ThreePOutlinedIcon,
  PostAddOutlinedIcon,
  ContentCopyOutlinedIcon,
  StarBorderOutlinedIcon,
  StarIcon,
  SearchIcon,
  FileUploadOutlinedIcon,
  AttachFileOutlinedIcon,
  PhoneDisabledIcon,
  AddCommentOutlinedIcon,
  SpeakerNotesOffOutlinedIcon,
  ControlPointIcon,
  SyncIcon,
  LocalFireDepartmentIcon,
  MyLocationOutlinedIcon,
  StoreOutlinedIcon,
  ConnectWithoutContactOutlinedIcon,
  AssignmentOutlinedIcon,
  CircleIcon,
  PeopleOutlinedIcon,
};

export interface SymbolProps {
  fontSize: "inherit";
  color: "inherit";
  symbol: IconOptions;
  style: {
    height: string;
    width: string;
  };
}

export interface IconProps {
  children?: ReactNode;
  symbol?: IconOptions;
  color: string;
  size: string;
  space?: "left" | "right" | "center" | "none";
  rotate?: boolean;
}
