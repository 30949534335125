import {
  type MutationUpdaterFunction,
  type DefaultContext,
  type ApolloCache,
  type StoreObject
} from '@apollo/client'
import {
  type AddCompanyMutation,
  type AddCompanyInput,
  GetActiveListDocument,
  type GetActiveListQuery,
  type CreateCompanyMutation,
  type CreateCompanyInput,
  type CreateBhCompanyFromLeadMutation,
  type CreateBhCompanyFromLeadMutationVariables,
  type UpdateIncompleteCompanyFromBhMutation,
  type UpdateIncompleteCompanyFromBhMutationVariables,
  type Contact
} from '~/src/__generated__/gql-types'

/**
 * Factory function used to update a calllist cache by inserting a new
 * company cache item to the calllist `companies` array
 */
export default function createUpdateCallListCache<
  T extends 'add' | 'create',
  M extends AddCompanyMutation | CreateCompanyMutation = T extends 'add'
    ? AddCompanyMutation
    : CreateCompanyMutation,
  I extends AddCompanyInput | CreateCompanyInput = T extends 'add'
    ? AddCompanyInput
    : CreateCompanyInput
>(
  callListId: string,
  resultKey: T
): MutationUpdaterFunction<M, { input: I }, DefaultContext, ApolloCache<any>> {
  return (cache, result) => {
    const rootKey = `${resultKey}Company` as
      | 'addCompany'
      | 'createCompany' as keyof M

    /**
     * TODO: This will always return because the lookup is incorrect.
     * If this function returns, nothing is done to modify cache and the
     * cache is updated with the responses from mutations and queries.
     *
     * Should be !result?.data?[rootKey]
     **/
    if (!result?.data?.[rootKey]) return

    // newly created company cache key. used for updating the calllist cache
    const companyCacheKey = cache.identify(result.data[rootKey] as StoreObject)
    // callList data used to retrieve the cache key. `cache.modify` needs to
    // know which cache object to modify (insert now company)
    const { getActiveList } = cache.readQuery({
      query: GetActiveListDocument,
      variables: {
        userId: callListId
      }
    }) as GetActiveListQuery

    // add new company ref to other company refs in the calllist cache's `companies` array
    cache.modify({
      id: cache.identify(getActiveList),
      fields: {
        companies(existingCompanyRefs) {
          return [...existingCompanyRefs, { __ref: companyCacheKey }]
        }
      }
    })
  }
} // Asegúrate de importar correctamente los tipos

export function createUpdateLeadsListCache<
  T extends 'create' | 'update',
  M extends
    | CreateBhCompanyFromLeadMutation
    | UpdateIncompleteCompanyFromBhMutation = T extends 'create'
    ? CreateBhCompanyFromLeadMutation
    : UpdateIncompleteCompanyFromBhMutation,
  V extends
    | CreateBhCompanyFromLeadMutationVariables
    | UpdateIncompleteCompanyFromBhMutationVariables = T extends 'create'
    ? CreateBhCompanyFromLeadMutationVariables
    : UpdateIncompleteCompanyFromBhMutationVariables
>(
  callListId: string,
  resultKey: T
): MutationUpdaterFunction<M, V, DefaultContext, ApolloCache<any>> {
  return (cache, result) => {
    const rootKey = `${resultKey}Company` as
      | 'createBhCompanyFromLead'
      | 'updateIncompleteCompanyFromBh' as keyof M

    /**
     * TODO: This will always return because the lookup is incorrect.
     * If this function returns, nothing is done to modify cache and the
     * cache is updated with the responses from mutations and queries.
     *
     * Should be !result?.data?[rootKey]
     **/
    if (!result?.data?.[rootKey]) return

    const companyData = result.data[rootKey] as StoreObject
    const companyCacheKey = cache.identify(companyData)

    // Modify the getLeadsList field
    cache.modify({
      fields: {
        getLeadsList(existingLeads = []) {
          if (resultKey === 'create')
            return [...existingLeads, { __ref: companyCacheKey }]

          if (resultKey === 'update') {
            return existingLeads.map((lead: { __ref: string | undefined }) =>
              lead.__ref === companyCacheKey ? { __ref: companyCacheKey } : lead
            )
          }

          return existingLeads
        }
      }
    })

    // Evict old contacts and add new contacts directly in the cache
    cache.modify({
      id: companyCacheKey,
      fields: {
        contacts(existingContacts = [], { readField }) {
          const newContacts = (companyData.contacts as Contact[]) ?? []

          // Evict existing contacts that match the new ones
          existingContacts.forEach((contact: any) => {
            const contactId = readField<string>('id', contact)
            if (
              newContacts.some(
                (newContact: any) => readField('id', newContact) === contactId
              )
            )
              cache.evict({ id: contact.__ref })
          })

          // Return the combined list of old (non-evicted) and new contacts
          return [
            ...existingContacts.filter((contact: any) => {
              const contactId = readField<string>('id', contact)
              return !newContacts.some(
                (newContact: any) => readField('id', newContact) === contactId
              )
            }),
            ...newContacts
          ]
        }
      }
    })

    // Optionally garbage collect evicted IDs
    cache.gc()
  }
}
