import { Ref, forwardRef } from "react";
import * as styles from "./input.module.css";
import { TextInputProps } from "./Input.types";
import { Text } from "../typography/Text";
import { Icon } from "../icon/Icon";
import classNames from "classnames";

/**
 * TextInputProps
 * @param {string} id - id of the input
 * @param {string} label - label of the input
 * @param {string} value - value of the input
 * @param {(value: string) => void} onChange - callback function when the input value changes
 * @param {boolean} required - if true, the input is required
 * @param {string} errorMessage - error message to be displayed when the input is invalid
 * @param {boolean} multiline - if true, the input is a multiline text input
 * @param {number} rows - number of rows for the multiline text input
 * @param {boolean} noLabel - if true, the label is not displayed
 * @param {boolean} showHighPriorityIcon - if true, the high priority icon is displayed
 * @param {any} inputProps - any other props to be passed to the input
 */

export const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
  (
    {
      id,
      label,
      value,
      onChange,
      required = false,
      errorMessage,
      multiline = false,
      rows = 3,
      noLabel = false,
      showHighPriorityIcon = false,
      ...inputProps
    },
    ref,
  ): JSX.Element => {
    const ariaLabel = noLabel ? label : undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <>
        <div className={styles["text-input-container"]}>
          {!noLabel ? (
            <label
              htmlFor={id}
              className={classNames(styles["text-input-label"], errorMessage && styles["error-label"])}
            >
              {showHighPriorityIcon && (
                <Icon symbol="ReportProblemOutlinedIcon" color="alku-brand-primary" size="sm" space="right" />
              )}
              {label}
              {required && <span className={styles["text-input-required"]}>*</span>}
            </label>
          ) : (
            <div className={styles["text-input-container-no-label"]} />
          )}
          {multiline ? (
            <textarea
              ref={ref as Ref<HTMLTextAreaElement>}
              id={id}
              className={styles["textarea-field"]}
              value={value}
              onChange={handleChange}
              rows={rows}
              aria-label={ariaLabel}
              {...inputProps}
            />
          ) : (
            <input
              ref={ref as Ref<HTMLInputElement>}
              id={id}
              className={styles["text-input-field"]}
              value={value}
              onChange={handleChange}
              aria-label={ariaLabel}
              {...inputProps}
            />
          )}
        </div>
        {errorMessage && (
          <Text style="small" color="alku-brand-primary" weight="semibold">
            {errorMessage}
          </Text>
        )}
      </>
    );
  },
);

TextInput.displayName = "TextInput";
