import {
  type FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react'
import classNames from 'classnames'
import {
  Formik,
  Form,
  type FormikHelpers,
  type FormikProps,
  type FormikTouched,
  type FormikValues,
  setNestedObjectValues
} from 'formik'
import { type BaseMutationOptions } from '@apollo/client'
import {
  Alert,
  AlertType,
  Button,
  Icon,
  Text,
  SelectDropdown,
  SelectDropdownField
} from '@alku/ui-kit'
import debounce from 'lodash/debounce'

/* form elements */
import { TextInput, InfoNote } from '../../shared/forms/form-elements'

/* static (timezones and states)  */
import {
  countriesData,
  provinceData,
  stateData,
  timezoneData
} from './constants/formData'

/* helpers */
import {
  AddCompanySchema,
  type AddCompanyWithCountryInput,
  type BullhornCompanyExtended,
  type CompanyWithId,
  FORM_STATE,
  UpdateCompanySchema,
  emptyValues,
  getCompanySelectData,
  hasError
} from './helpers/companyFormHelpers'

/* gql types */
import {
  type UpdateCompanyInput,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useCreateCompanyMutation,
  type AddCompanyInput,
  GetActiveListSummaryDocument,
  useGetDuplicateCompaniesLazyQuery,
  useFindDuplicateCompaniesLazyQuery,
  type CreateCompanyInput,
  useGetCompaniesLazyQuery
} from '../../../__generated__/gql-types'

/* styles */
import * as styles from './companyForm.module.css'
import createUpdateCallListCache from './helpers/createUpdateCallListCache'
import { useAuth } from '~/src/auth/hooks/useAuth'
import { CompanyDupeRow } from '../../shared/dupes/CompanyDupeRow'
import { CompanyDupeCard } from '../../shared/dupes/CompanyDupeCard'
import { generateSubTitle } from '../DuplicateCompanyModal/DuplicateCompanyModal'
import { ErrorAlert } from './ErrorAlert'
import { type SCREEN_STATE } from '~/src/screens/listScreens/helpers/list.helper'

interface CompanyFormProps {
  toggleCreateNew: (status: boolean) => void
  createNewCompany: boolean
  setCreateNewCompany: (isNew: boolean) => void
  companyData?: CompanyWithId | null
  editMode?: boolean
  closeDialog: () => void
  setScreenState: (state: keyof typeof SCREEN_STATE) => void
  callListId: string
}
export const CompanyForm: FC<CompanyFormProps> = ({
  toggleCreateNew,
  createNewCompany,
  companyData,
  editMode = false,
  closeDialog,
  setScreenState,
  setCreateNewCompany,
  callListId
}) => {
  const [isSearching, setIsSearching] = useState<boolean>(!editMode)
  const [showAddDuplicates, setShowAddDuplicates] = useState<boolean>(false)
  const [showCreateDuplicates, setShowCreateDuplicates] =
    useState<boolean>(false)
  const [isCheckingAddDupes, setIsCheckingAddDupes] = useState<boolean>(false)
  const [isCheckingCreateDupes, setIsCheckingCreateDupes] =
    useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [company, setCompany] = useState<CompanyWithId | null>(
    companyData as CompanyWithId
  )
  const formRef = useRef<FormikProps<AddCompanyWithCountryInput>>(null)

  const { id } = useAuth()

  const initialValues: AddCompanyWithCountryInput = useMemo(
    () =>
      editMode || company
        ? {
            name: company?.name ?? '',
            bullhorn_id: company?.bullhorn_id as number,
            address1: company?.address1 ?? '',
            address2: company?.address2 ?? '',
            city: company?.city ?? '',
            state: company?.state ?? '',
            postal: company?.postal ?? '',
            timezone: company?.timezone ?? null,
            phone: company?.phone ?? '',
            phone2: company?.phone2 ?? '',
            notes: company?.notes ?? '',
            linkedin: company?.linkedin ?? '',
            country: company?.postal
              ? /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(
                  company?.postal
                )
                ? 'CA'
                : 'US'
              : 'US'
          }
        : emptyValues,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode, company]
  )

  const formState: keyof typeof FORM_STATE = (() => {
    if (editMode) return FORM_STATE.EDITING
    if (createNewCompany) return FORM_STATE.CREATING
    if (isSearching) return FORM_STATE.SEARCHING
    return FORM_STATE.ADDING
  })()

  const debouncedGetCompanies = debounce((value) => {
    void getCompanies({
      variables: {
        text: value
      }
    })
  }, 500) // Adjust the debounce delay as needed

  // #region gql
  const mutationOptions: Pick<BaseMutationOptions, 'onCompleted' | 'onError'> =
    useMemo(
      () => ({
        onCompleted: (): void => {
          const triggerGtmEvent = (): void => {
            // pass in formState if you want to be specific between adding and creating
            window.dataLayer.push({
              event: editMode
                ? 'ga-edit-company'
                : createNewCompany
                ? 'ga-add-new-company'
                : 'ga-add-existing-company'
            })
          }
          triggerGtmEvent()
          setScreenState('DEFAULT')
        },
        onError: () => {
          setScreenState('ERROR')
        }
      }),
      [setScreenState, createNewCompany, editMode]
    )
  const [addCompany, { error: addError }] = useAddCompanyMutation({
    ...mutationOptions,
    update: createUpdateCallListCache(callListId, 'add')
  })
  const [updateCompany, { error: updateError }] =
    useUpdateCompanyMutation(mutationOptions)
  const [createCompany, { error: createError }] = useCreateCompanyMutation({
    ...mutationOptions,
    update: createUpdateCallListCache(callListId, 'create')
  })
  const [getCompanies, { data: companiesData, loading: loadingCompaniesData }] =
    useGetCompaniesLazyQuery()

  const [
    getDuplicateCompanies,
    {
      data: amclDuplicateCompanies,
      loading: amclDuplicatesLoading,
      error: amclDuplicatesError
    }
  ] = useGetDuplicateCompaniesLazyQuery()

  const [
    findDuplicateCompanies,
    {
      data: createDuplicateCompanies,
      loading: createDuplicatesLoading,
      error: createDuplicatesError
    }
  ] = useFindDuplicateCompaniesLazyQuery()
  // #endregion gql

  /* update company state when company data changes */
  useEffect(() => {
    setCompany(companyData ?? null)
  }, [companyData])

  // #region handlers

  const handleAddDupeCheck = (values: AddCompanyInput): void => {
    void getDuplicateCompanies({
      variables: {
        bullhornId: values.bullhorn_id ?? 0
      }
    })
  }

  const handleCreateDupeCheck = (values: AddCompanyInput): void => {
    const input: CreateCompanyInput & {
      bullhorn_id?: number
      country?: string
    } = { ...values }
    delete input.bullhorn_id
    delete input.country
    void findDuplicateCompanies({
      variables: { input }
    })
  }

  useEffect(() => {
    if (
      amclDuplicateCompanies &&
      amclDuplicateCompanies.getDuplicateCompanies.length > 0
    ) {
      // if dupes found show them
      setShowAddDuplicates(true)
      setIsCheckingAddDupes(false)
    } else if (
      // don't trigger submit if amclDuplicateCompanies response is undefined
      amclDuplicateCompanies !== undefined &&
      amclDuplicateCompanies.getDuplicateCompanies.length === 0
    ) {
      // proceed with add mutation
      if (formRef.current) formRef.current.handleSubmit()
    }
  }, [amclDuplicateCompanies, isCheckingAddDupes])

  useEffect(() => {
    if (
      createDuplicateCompanies &&
      (createDuplicateCompanies.findDuplicateCompanies.bullhorn !== null ||
        createDuplicateCompanies.findDuplicateCompanies.amcl.length > 0)
    ) {
      // if dupes found show them
      setShowCreateDuplicates(true)
      setIsCheckingCreateDupes(false)
    } else if (
      createDuplicateCompanies !== undefined &&
      createDuplicateCompanies.findDuplicateCompanies.bullhorn === null &&
      createDuplicateCompanies.findDuplicateCompanies.amcl.length === 0
    ) {
      // proceed with create mutation
      if (formRef.current) formRef.current.handleSubmit()
    }
  }, [createDuplicateCompanies, isCheckingCreateDupes])

  const handleCompanySelect = useCallback(
    (value: string | string[]): void => {
      const selected = companiesData?.companies?.find(
        (company) => company?.id.toString() === value?.toString()
      ) as CompanyWithId

      if (selected) {
        /* set selected company */
        setCompany(selected)

        /* show create new company form */

        /* set company state */
        setCompany({
          ...selected,
          bullhorn_id: Number(value)
        })
        setIsSearching(false)
      } else {
        setCompany(null)

        /* hide create new company form */
        setCreateNewCompany(false)
      }
    },
    [companiesData?.companies, setCreateNewCompany]
  )

  const companiesOptions = useMemo(() => {
    return getCompanySelectData(
      companiesData?.companies as BullhornCompanyExtended[]
    )
  }, [companiesData?.companies])

  const canEditCompany: boolean =
    formState === FORM_STATE.EDITING && !companyData?.bullhorn_id

  const close = (): void => {
    setCreateNewCompany(false)
    closeDialog()
  }
  const handleSubmit = (
    values: AddCompanyWithCountryInput,
    actions: FormikHelpers<AddCompanyWithCountryInput>
  ): void => {
    // removing, since the schema doesn't support it
    delete values.country
    actions.setSubmitting(false)

    setShowAddDuplicates(false)
    setShowCreateDuplicates(false)

    switch (formState) {
      case 'CREATING': {
        // if existing bh company was found, add it instead of creating it
        if (createDuplicateCompanies?.findDuplicateCompanies.bullhorn?.id) {
          const bhCompanyData =
            createDuplicateCompanies?.findDuplicateCompanies.bullhorn
          const input: AddCompanyWithCountryInput = {
            address1: bhCompanyData.address1,
            address2: bhCompanyData.address2,
            city: bhCompanyData.city,
            linkedin: values.linkedin,
            name: bhCompanyData.name,
            notes: values.notes,
            phone: bhCompanyData.phone ?? '',
            phone2: values.phone2,
            postal: bhCompanyData.postal,
            state: bhCompanyData.state,
            timezone: values.timezone,
            bullhorn_id:
              createDuplicateCompanies?.findDuplicateCompanies.bullhorn?.id
          }
          void addCompany({
            variables: {
              input
            },
            refetchQueries: [
              {
                query: GetActiveListSummaryDocument,
                variables: { userId: id }
              }
            ],
            awaitRefetchQueries: true
          })
        } else {
          const createValues = values as CreateCompanyInput & {
            bullhorn_id?: number
          }
          delete createValues.bullhorn_id
          const input: CreateCompanyInput = {
            ...createValues
          }
          void createCompany({
            variables: {
              input
            },
            refetchQueries: [
              {
                query: GetActiveListSummaryDocument,
                variables: { userId: id }
              }
            ],
            awaitRefetchQueries: true
          })
        }

        setScreenState('ADDING')
        break
      }
      case 'ADDING': {
        const input: AddCompanyInput = {
          ...values,
          bullhorn_id: company?.bullhorn_id as number
        }
        void addCompany({
          variables: {
            input
          },
          refetchQueries: [
            {
              query: GetActiveListSummaryDocument,
              variables: { userId: id }
            }
          ],
          awaitRefetchQueries: true
        })
        break
      }
      case 'EDITING': {
        void updateCompany({
          variables: {
            id: company?.id ?? '',
            input: {
              phone2: values.phone2,
              notes: values.notes,
              timezone: values.timezone,
              linkedin: values.linkedin
            } satisfies UpdateCompanyInput
          }
        })

        setScreenState('UPDATING')
        break
      }
      default:
        break
    }

    actions.resetForm({ values: emptyValues })
    close()
  }
  // #endregion handlers

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editMode ? UpdateCompanySchema : AddCompanySchema}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({
        errors,
        handleChange,
        setFieldValue,
        resetForm,
        touched,
        values
      }) => (
        <>
          {formState !== 'SEARCHING' && formState !== 'EDITING' && (
            <div className={styles.back}>
              <Button
                as='button'
                style='icon-only'
                onClick={() => {
                  setCreateNewCompany(false)
                  setIsSearching(true)
                  setCompany(null)
                  setShowAddDuplicates(false)
                  setShowCreateDuplicates(false)
                }}
                id='back-to-choose-button'
              >
                <Icon
                  symbol='ArrowBackIosIcon'
                  space='right'
                  color='icon-red'
                  size='sm'
                />
                <Text as='p' style='detail' color='dark-blue'>
                  Back to Choose Company
                </Text>
              </Button>
              <br />
              {formState === 'CREATING' && !showCreateDuplicates && (
                <div className={styles.alert}>
                  <Alert type={AlertType.Info} id='add-bh-company'>
                    You are adding a company to Bullhorn which can’t be edited
                    in AMCL
                  </Alert>
                </div>
              )}
            </div>
          )}
          <ErrorAlert errorMessage={addError?.message} />
          <ErrorAlert errorMessage={updateError?.message} />
          <ErrorAlert errorMessage={createError?.message} />

          {showAddDuplicates && (
            <>
              <div data-testid='company-duplicate-warning'>
                <Text
                  style='subheading'
                  weight='bold'
                  color='alku-brand-primary'
                >
                  {generateSubTitle(
                    amclDuplicateCompanies?.getDuplicateCompanies.length ?? 0
                  )}
                </Text>
              </div>
              <br />
              <CompanyDupeCard type='input' data={values} />
              <br />
              {amclDuplicatesError && (
                <Alert type={AlertType.Error} id='company-dupe-error'>
                  {amclDuplicatesError.message}
                </Alert>
              )}
              {amclDuplicatesLoading && <>Loading...</>}

              {!amclDuplicatesError && !amclDuplicatesLoading && (
                <>
                  {amclDuplicateCompanies?.getDuplicateCompanies.map(
                    (duplicate) => (
                      <div
                        key={duplicate.id}
                        className={styles['duplicate-item']}
                      >
                        <CompanyDupeRow data={duplicate} />
                      </div>
                    )
                  )}
                </>
              )}
            </>
          )}

          {showCreateDuplicates && (
            <>
              {createDuplicatesError && (
                <Alert type={AlertType.Error} id='company-dupe-error'>
                  {createDuplicatesError.message}
                </Alert>
              )}
              {createDuplicatesLoading && <>Loading...</>}

              {/* This company already exists in Bullhorn and on other lists */}
              {/* This company already exists in Bullhorn. Please review: */}

              {!createDuplicatesError && !createDuplicatesLoading && (
                <>
                  {/* Bullhorn and Amcl duplicates found */}
                  {createDuplicateCompanies?.findDuplicateCompanies.bullhorn &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl
                      .length > 0 && (
                      <div data-testid='company-duplicate-warning'>
                        <Text
                          style='subheading'
                          weight='bold'
                          color='alku-brand-primary'
                        >
                          This company already exists in Bullhorn and on other
                          lists
                        </Text>
                      </div>
                    )}

                  {/* Only Bullhorn Duplicates Found */}
                  {createDuplicateCompanies?.findDuplicateCompanies.bullhorn &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl
                      .length === 0 && (
                      <div data-testid='company-duplicate-warning'>
                        <Text style='subheading' weight='bold' color='alku-red'>
                          This company already exists in Bullhorn. Please
                          review:
                        </Text>
                      </div>
                    )}

                  {/* Only AMCL Duplicates Found */}
                  {!createDuplicateCompanies?.findDuplicateCompanies.bullhorn &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl
                      .length > 0 && (
                      <div data-testid='company-duplicate-warning'>
                        <Text style='subheading' weight='bold' color='alku-red'>
                          {generateSubTitle(
                            createDuplicateCompanies?.findDuplicateCompanies
                              .amcl.length ?? 0
                          )}
                        </Text>
                      </div>
                    )}

                  {createDuplicateCompanies?.findDuplicateCompanies
                    .bullhorn && (
                    <>
                      <br />
                      <CompanyDupeCard
                        type='bullhorn'
                        data={
                          createDuplicateCompanies?.findDuplicateCompanies
                            .bullhorn
                        }
                      />
                      <br />
                    </>
                  )}

                  {createDuplicateCompanies?.findDuplicateCompanies.amcl &&
                    createDuplicateCompanies?.findDuplicateCompanies.amcl
                      .length > 0 && (
                      <>
                        {createDuplicateCompanies?.findDuplicateCompanies.amcl.map(
                          (duplicate, i) => (
                            <div
                              key={duplicate.id}
                              className={styles['duplicate-item']}
                            >
                              <CompanyDupeRow data={duplicate} />
                            </div>
                          )
                        )}
                      </>
                    )}
                </>
              )}
            </>
          )}

          <Form
            id='add-edit-company'
            className={classNames(
              styles.form,
              formState === 'SEARCHING' ? styles['min-height'] : ''
            )}
          >
            {formState === 'SEARCHING' && (
              <div className={styles['form-element']}>
                <SelectDropdown
                  virtualize
                  name='name'
                  id='company_name'
                  label='Search Company in Bullhorn'
                  placeholder='Enter company name...'
                  inputValue={inputValue}
                  required
                  options={companiesOptions ?? []}
                  isSearchable
                  isLoading={loadingCompaniesData}
                  loadingMessage={() => 'Loading companies...'}
                  onInputChange={(value = '', { action }) => {
                    if (action !== 'input-blur' && action !== 'menu-close') {
                      setInputValue(value)
                      if (value.length >= 2) debouncedGetCompanies(value)
                    }
                  }}
                  onChange={handleCompanySelect}
                  error={
                    hasError(errors.name) && touched.name
                      ? errors.name
                      : undefined
                  }
                  touched={touched.name}
                  maxMenuHeight={150}
                  className={styles.search}
                />
                <InfoNote>
                  Can&apos;t find it?{' '}
                  <button
                    className={styles['note-link']}
                    type='button'
                    onClick={() => {
                      toggleCreateNew(true)
                      setCompany(null)
                      resetForm()
                    }}
                  >
                    Add it as a New Company
                  </button>
                </InfoNote>
              </div>
            )}
            {formState !== 'SEARCHING' && (
              <div
                className={
                  showAddDuplicates || showCreateDuplicates ? styles.hide : ''
                }
              >
                <div className={styles['form-element']}>
                  <TextInput
                    label='Company Name'
                    id='company_name'
                    name='name'
                    error={errors.name}
                    handleChange={handleChange}
                    value={values?.name}
                    readOnly={formState !== 'CREATING' && !canEditCompany}
                    required={true}
                    touched={touched.name}
                  />
                  {formState === 'CREATING' && (
                    <InfoNote>
                      Think it&apos;s already in Bullhorn?
                      <button
                        className={styles['note-link']}
                        type='button'
                        onClick={() => {
                          toggleCreateNew(false)
                          setCompany(null)
                          setCreateNewCompany(false)
                        }}
                      >
                        Try searching for your company
                      </button>
                    </InfoNote>
                  )}
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Address'
                    id='company_address1'
                    name='address1'
                    value={values?.address1}
                    readOnly={formState !== 'CREATING' && !canEditCompany}
                    error={errors.address1}
                    handleChange={handleChange}
                    required={true}
                    touched={touched.address1}
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Address Line 2'
                    id='company_address2'
                    value={values?.address2 as string}
                    name='address2'
                    readOnly={formState !== 'CREATING' && !canEditCompany}
                    handleChange={handleChange}
                    touched={touched.address2}
                  />
                </div>

                <div className={styles.group}>
                  <div
                    className={classNames(styles['form-element'], styles.city)}
                  >
                    <TextInput
                      label='City'
                      id='company_city'
                      name='city'
                      readOnly={formState !== 'CREATING' && !canEditCompany}
                      value={values?.city}
                      error={errors.city}
                      handleChange={handleChange}
                      required={true}
                      touched={touched.city}
                    />
                  </div>
                  <div
                    className={classNames(styles['form-element'], styles.state)}
                  >
                    <SelectDropdownField
                      id='company_state'
                      name='state'
                      label='State/Province'
                      options={
                        values.country === 'CA' ? provinceData : stateData
                      }
                      readOnly={formState !== 'CREATING' && !canEditCompany}
                      value={
                        (values.country === 'CA'
                          ? provinceData
                          : stateData
                        ).find((s) => s.value === values?.state)
                          ? (values.country === 'CA'
                              ? provinceData
                              : stateData
                            ).find((s) => s.value === values?.state)
                          : null
                      }
                      setValue={setFieldValue}
                      error={
                        hasError(errors.state) && touched.state
                          ? errors.state
                          : undefined
                      }
                      touched={touched.state}
                      required={true}
                    />
                  </div>
                  <div
                    className={classNames(styles['form-element'], styles.zip)}
                  >
                    <TextInput
                      id='company_postal'
                      name='postal'
                      label='Postal'
                      readOnly={formState !== 'CREATING' && !canEditCompany}
                      value={values?.postal}
                      error={errors.postal}
                      handleChange={handleChange}
                      touched={touched.postal}
                      required={true}
                    />
                  </div>
                </div>
                <div className={styles.group}>
                  <div
                    className={classNames(
                      styles['form-element'],
                      styles.country
                    )}
                  >
                    <SelectDropdownField
                      label='Country'
                      id='company_country'
                      name='country'
                      readOnly={formState !== 'CREATING' && !canEditCompany}
                      options={countriesData}
                      setValue={setFieldValue}
                      value={countriesData.find(
                        (s) => s.value === values?.country
                      )}
                    />
                  </div>
                  <div
                    className={classNames(styles['form-element'], styles.time)}
                  >
                    <SelectDropdownField
                      label='Timezone'
                      id='company_timezone'
                      name='timezone'
                      options={timezoneData}
                      setValue={setFieldValue}
                      value={timezoneData.find(
                        (s) => s.value === values?.timezone
                      )}
                      error={
                        hasError(errors.timezone) && touched.timezone
                          ? errors.timezone
                          : undefined
                      }
                      touched={touched.timezone}
                    />
                  </div>
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    type='tel'
                    id='company_phone'
                    label='Headquarters Phone Number'
                    name='phone'
                    readOnly={formState !== 'CREATING' && !canEditCompany}
                    required={true}
                    value={values?.phone}
                    error={errors.phone}
                    handleChange={handleChange}
                    touched={touched.phone}
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    type='tel'
                    id='company_phone2'
                    label='Other Company Phone Number'
                    name='phone2'
                    value={values?.phone2 as string}
                    error={errors.phone2}
                    handleChange={handleChange}
                    touched={touched.phone2}
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Company Linkedin Profile'
                    id='company_linkedin'
                    name='linkedin'
                    value={values?.linkedin as string}
                    error={errors.linkedin}
                    touched={touched.linkedin}
                    handleChange={handleChange}
                    placeholder='https://www.linkedin.com/company/...'
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    id='company_notes'
                    name='notes'
                    value={values?.notes ?? ''}
                    error={errors.notes}
                    touched={touched.notes}
                    handleChange={handleChange}
                    label='Company Notes'
                    multiline
                  />
                </div>
              </div>
            )}
            {formState !== 'SEARCHING' &&
            formState !== 'EDITING' &&
            !showAddDuplicates &&
            !showCreateDuplicates ? (
              // Dupe Check Trigger
              <Button
                id='submit-company'
                style='primary-impact'
                type='button'
                as='button'
                ariaLabel='submit'
                onClick={() => {
                  // validate form before checking for dupes
                  if (formRef.current) {
                    // fields must be "touched" in order for validation to work
                    void formRef.current.setTouched(
                      setNestedObjectValues<FormikTouched<FormikValues>>(
                        errors,
                        true
                      )
                    )
                    void formRef.current.validateForm()
                  }

                  if (!Object.keys(errors).length) {
                    if (formState === 'ADDING') {
                      setIsCheckingAddDupes(true)
                      handleAddDupeCheck(values)
                    }
                    if (formState === 'CREATING') {
                      setIsCheckingCreateDupes(true)
                      handleCreateDupeCheck(values)
                    }
                  } else {
                    setIsCheckingAddDupes(false)
                    setIsCheckingCreateDupes(false)
                  }

                  Object.keys(errors).length &&
                    console.log('company form errors:', errors)
                }}
              >
                Submit
              </Button>
            ) : (
              // No Dupe Check/Add Anyway Submit
              <>
                <Button
                  id='submit-company'
                  style='primary-impact'
                  type='submit'
                  as='button'
                  ariaLabel='submit'
                  onClick={() => {
                    Object.keys(errors).length &&
                      console.log('company form errors:', errors)
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}
