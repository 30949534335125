import { forwardRef } from "react";
import { RadioButtonProps } from "./Input.types";
import * as styles from "./input.module.css";

/**
 * RadioButtonProps
 * @param {boolean} checked - Checked state
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange - Change event handler
 * @param {string} name - Name of the input
 * @param {string} value - Value of the input
 * @param {string} label - Label of the input
 * @param {string} ariaLabel - Aria label of the input
 * @param {any} rest - Any other props to be passed to the input
 * @returns {JSX.Element}
 */

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ checked, onChange, name, value, label, ariaLabel, ...rest }, ref) => {
    return (
      <label className={styles["custom-radio"]}>
        <input
          type="radio"
          aria-label={ariaLabel}
          aria-checked={checked}
          checked={checked}
          onChange={onChange}
          name={name}
          value={value}
          ref={ref}
          {...rest}
        />
        <span className={styles["custom-radio__checkmark"]} />
        <span className={styles["custom-radio__label"]}>{label}</span>
      </label>
    );
  },
);

RadioButton.displayName = "RadioButton";
