.contact-popover-wrapper {
  position: relative;
}

.contact-table {
  width: 100%;
  font-size: 1.5rem;
  min-width: 100rem;
}

.wrapper {
  position: relative;
  /* TODO: figure out how to handle table overflow without cutting off flyouts on the bottom or only row */
  /* overflow-x: auto; */
}

/* https://uit.stanford.edu/accessibility/concepts/tables/sticky-headers */
.sticky {
  overflow-y: auto;
  max-height: 43rem;
}

.sticky thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.sticky .contact-table tbody {
  z-index: 0;
}

.popover {
  position: absolute;
  top: 4rem;
  left: calc(50% - 13rem);
  width: 26rem;
  border: var(--border-add-button from global);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.5rem;
  text-align: center;
  border-radius: 0.4rem;
  background: var(--white from global);
  z-index: 10;
  box-shadow: var(--shadow-flyout from global);
  text-wrap: balance;
}

.contact-table thead {
  border: 0.1rem solid var(--table-header from global);
  border-left: 0.4rem solid var(--table-header from global);
}

.contact-table tbody {
  position: relative;

  &:before {
    content: '';
    display: block;
    line-height: 0.3rem;
    text-indent: -99999px;
  }
}

.contact-table th {
  background-color: var(--table-header from global);
  color: var(--white from global);
  vertical-align: middle;
  font-weight: bold;
  text-align: left;
  padding: 1.4rem 2rem;
}

.contact-table tbody tr {
  border: var(--border-table from global);

  &:last-child {
    border-bottom: none;
  }
}

.contact-table td:not(.popover) {
  vertical-align: top;
  color: var(--black from global);
  font-family: Poppins;
  font-size: 1.35rem;
  letter-spacing: -0.01rem;
  line-height: 1.4rem;
  padding: 1.5rem 2rem;
  white-space: nowrap;

  &.has-logo {
    padding: 1.1rem 2rem;
  }
}

.contact-table tr:last-child td {
  border-bottom: 0;
}

.contact-table td:first-child {
  font-weight: bold;
}

.contact-table td:first-child > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact-table td:first-child div .logo {
  display: block;
  width: 4rem;
  min-width: 4rem;
  margin-right: 1.3rem;
}

.contact-table td:first-child div .name {
  font-weight: 700;
}

.contact-table .bullhorn-logo {
  width: 100%;
  height: auto;
}

.contact-table td:last-child:not(.popover) {
  text-align: right;
  padding: 0;
}

.contact-table th:first-child span {
  /* name */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contact-table th:nth-child(2) {
  /* phone */
  width: 20%;
}
.contact-table th:nth-child(3) {
  /* location */
  width: 10%;
}
.contact-table th:nth-child(4) {
  /* email */
  width: 15%;
}
.contact-table th:nth-child(5) {
  /* notes */
  width: 25%;
}
.contact-table th:last-child {
  /* action item */
  width: 5%;
}

.contact-table .sort {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.contact-table .sort button {
  padding: 0.1rem;
  margin: -0.7rem;
}

.contact-table .phone {
  color: var(--dark-blue);
  text-decoration: underline;
  display: inline-flex;
  gap: 0.8rem;
  align-items: center;
  margin-left: 0.5rem;
}
.contact-table .phone img {
  max-width: 2.4rem;
}
.contact-table .notes {
  white-space: break-spaces;
  line-height: 1.3;
}
.contact-table .notes a {
  text-decoration: underline;
}

.contact-table td:last-child.empty-state {
  font-weight: 100;
  text-align: center;
  padding: 1.5rem 0;
}

.contact-table div ul .item:not(:first-child) button span:first-child {
  border-right: 0;
}

.contact-table .phoneRow {
  display: flex;
  align-items: flex-start;
}

.contact-table .phoneRow .phoneFlex {
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.contact-table .phoneHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.contact-table .referral {
  background-color: var(--fill-warn from global);
}
