/*
  Heading:
  Component Styles
*/

.one {
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.02rem;
  line-height: 1;
}

.two {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.01rem;
  line-height: 1.2;
}

.three {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
}

/* Lonely list */
.four {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.01rem;
  line-height: 1;
}

.subheading {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.016rem;
}

.subheading-small {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.03rem;
}

/*
  Text:
  Component Styles
*/

.detail {
  font-size: 1.5rem;
  letter-spacing: -0.01rem;
  line-height: 1.73;
  text-align: left;

  b {
    font-weight: 600;
  }
}

.nav-text {
  font-size: 1.8rem;
  letter-spacing: 0.04rem;
  line-height: 1;
}

.title,
.link {
  display: block;
  font-size: 1.8rem;
  letter-spacing: -0.01rem;
  line-height: 1;
}

.link {
  text-decoration: underline;
  color: inherit;
}

.metadata {
  font-size: 1.45rem;
  line-height: 1.1;
  letter-spacing: -0.0145rem;
}

.normal {
  font-size: 1.3rem;
  line-height: 1.84;
  letter-spacing: -0.026rem;
}

.small {
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: -0.012rem;
}

.menu-item {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.0014rem;
}

.note {
  font-size: 1.3rem;
  font-weight: 400;
}
