/* Colors - add as needed */
.alku-brand-primary {
  color: var(--alku-brand-primary from global);
}

.alku-icon-default {
  color: var(--alku-icon-default from global);
}

.alku-black {
  color: var(--alku-black from global);
}

.alku-white {
  color: var(--alku-white from global);
}

.alku-white-inactive {
  color: var(--alku-white-inactive from global);
}

.icon-warn {
  color: var(--icon-warn from global);
}

.icon-success {
  color: var(--icon-success from global);
}

.icon-info {
  color: var(--icon-info from global);
}

.icon-favorite {
  color: var(--icon-favorite from global);
}

.icon-red {
  color: var(--icon-red from global);
}

.cactus-green {
  color: var(--cactus-green from global);
}

.cactus-green-light {
  color: var(--cactus-green-light from global);
}

.dark-blue {
  color: var(--dark-blue from global);
}

.bright-blue {
  color: var(--bright-blue from global);
}

.dark-grey {
  color: var(--dark-grey from global);
}

.text-red {
  color: var(--text-red from global);
}

.text-green {
  color: var(--text-green from global);
}

.text-disabled {
  color: var(--text-disabled from global);
}

/* Font Weights */
.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

/* Misc useful classes */
.hyperlink {
  border-bottom: 0.1rem solid var(--bright-blue from global);
  color: var(--bright-blue from global);
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-start {
  align-items: flex-start;
}

.relative {
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 1rem;
}

/* Accessibility Utilities */
/* screen reader only - visually hidden */
.u-sr-only {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* Header */
.header {
  padding: 0 3.6rem;
  background: #fafafa;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 3.2rem;
}

.header-logo {
  margin: 2rem;
  margin-right: 17.4rem;
}

.header-profile {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.header-nav-wrapper {
  flex-grow: 1;
}

.header-avatar {
  display: flex;
  align-items: center;
}

.header-account-wrapper {
  position: relative;
  z-index: 150;
}

/* Flyout */
.flyout-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.mb-1 {
  margin-bottom: 1rem;
}

/* Overflow Utility */
.overflow-hidden {
  overflow: hidden;
}
