@import './base.css';

/* Colors - add as needed */
.dark-blue {
  color: var(--dark-blue from global);
}

.black {
  color: var(--black from global);
}

.alku-red {
  color: var(--alku-red from global);
}

.yellow-lead {
  color: var(--icon-yellow-lead from global);
}

.marketplace-lead {
  color: var(--icon-marketplace-lead from global);
}

.hot-lead {
  color: var(--icon-hot-lead from global);
}

.reference-lead {
  color: var(--icon-reference-lead from global);
}

.bright-blue {
  color: var(--bright-blue from global);
}

.alku-white-inactive {
  color: var(--white-inactive from global);
}

.alku-white {
  color: var(--white from global);
}

.bright-blue {
  color: var(--bright-blue from global);
}

.dark-grey {
  color: var(--dark-grey from global);
}

.disabled-text {
  color: var(--disabled-text from global);
}

.grey-8 {
  color: var(--grey-8 from global);
}

.text-red {
  color: var(--text-red from global);
}

.text-green {
  color: var(--text-green from global);
}

.icon-favorite {
  color: var(--icon-favorite from global);
}

.icon-default {
  color: var(--icon-default from global);
}

.icon-warn {
  color: var(--icon-warn from global);
}

.icon-success {
  color: var(--icon-success from global);
}

.disabled-check {
  color: var(--disabled-check from global);
}

/* Font Weights */
.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

/* Misc useful classes */
.hyperlink {
  border-bottom: 0.1rem solid var(--bright-blue from global);
  color: var(--bright-blue from global);
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-start {
  align-items: flex-start;
}

/* Back button for detail screens */
.back {
  margin-right: 1.8rem;
}

/* Flyout-related Styles -- putting here bc there is a lot of variation w/ components surrounding flyout, but these styles can be shared */
.flyout-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.flyout-item:not(:last-of-type) {
  margin-bottom: 1rem;
}

/* Accessibility Utilities */
/* screen reader only - visually hidden */
.u-sr-only {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* my list screen & list detail */
.section {
  margin: 3.2rem 0 4.8rem 0;
}

.listContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.container {
  border: var(--border-call-list from global);
  border-radius: 0.1rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.cancel-button {
  margin-left: 2rem;
}

