.label {
  color: var(--Colors-Black, var(--Black, #000));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}

.radio_group {
  display: flex;
  padding-right: 292px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.radio {
  /* fill: var(--White, #fff); */
  stroke-width: 1px;
  /* stroke: var(--ALKU-Red, #d0021b); */
}
.radio_checked {
  /* fill: var(--White, #fff); */
  stroke-width: 3px;
  stroke: var(--ALKU-Red, #d0021b);
}

.radio_container {
  /* padding-bottom: 1.2rem; */
  display: flex;
  height: 20px;
  align-items: center;
  align-self: stretch;
  line-height: 1;
}

.frame {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  height: 20px;
  gap: 7px;
}
