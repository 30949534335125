import { ListType } from '~/src/__generated__/gql-types'

/**
 * Get the string content from a Fetch response, create a
 * url from that data, and trigger an event to start the download
 */
export default async function downloadCSVFromResponse(
  response: Response,
  listType: ListType
): Promise<void> {
  let fileName = ''
  if (listType === ListType.Active) fileName = 'Active-Export.csv'
  else if (listType === ListType.NotCalling) fileName = 'Not-Calling-Export.csv'
  else if (listType === ListType.Leads) fileName = 'Leads-Export.csv'

  const csvString = await response.text()
  const blob = new Blob([csvString], { type: 'text/csv' })
  const href = window.URL.createObjectURL(blob)
  const a = document.createElement('A')
  a.setAttribute('href', href)
  a.setAttribute('download', fileName)
  a.click()
}
