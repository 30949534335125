import {
  type AuthenticationResult,
  EventType,
  InteractionStatus
} from '@azure/msal-browser'
import { authenticationParams, msalInstance } from '../msal/helpers'
import { useEffect, useRef } from 'react'
import { useMsal } from '@azure/msal-react'

export default function useCustomMsalAuthentication(
  isAuthenticated?: boolean
): void {
  const { inProgress: status } = useMsal()
  /** Only run the custom login flow once */
  const initiated = useRef<boolean>(false)
  /** ID of msal event, used for removal so extra event handlers aren't executing */
  const callbackId = useRef<string>()

  useEffect(() => {
    if (callbackId?.current)
      msalInstance.removeEventCallback(callbackId?.current)

    callbackId.current = msalInstance.addEventCallback((message) => {
      const { eventType } = message
      const shouldLogin =
        (isStartup(eventType, status) || shouldCheckCode(eventType, status)) &&
        !isAuthenticated &&
        !initiated.current
      if (shouldLogin) {
        initiated.current = true
        void handleCustomLogin()
      }
    }) as string
  }, [status, isAuthenticated])
}

const handleCustomLogin = async (): Promise<void> => {
  try {
    const url = await getLoginUrl()
    await launchWebAuthFlow(url)
  } catch (error) {
    console.log('error in handleCustomLogin', error)
  }
}

const getLoginUrl = async (): Promise<string> => {
  let loginURL: string = ''

  await msalInstance.loginRedirect({
    scopes: authenticationParams.scopes,
    onRedirectNavigate: (url) => {
      loginURL = url
      return false
    }
  })

  return loginURL
}

const launchWebAuthFlow = async (
  url: string
): Promise<AuthenticationResult | null> => {
  return await new Promise((resolve, reject) => {
    chrome.identity.launchWebAuthFlow(
      {
        interactive: true,
        url
      },
      (responseUrl) => {
        if (responseUrl?.includes('#')) {
          const url = new URL(responseUrl)
          msalInstance
            .handleRedirectPromise(url.hash)
            .then(resolve)
            .catch(reject)
        } else {
          resolve(null)
        }
      }
    )
  })
}

type EventCheck = (type: EventType, status: InteractionStatus) => boolean
const isStartup: EventCheck = (type, status) =>
  type === EventType.HANDLE_REDIRECT_END && status === InteractionStatus.Startup
const shouldCheckCode: EventCheck = (type, status) =>
  type === EventType.HANDLE_REDIRECT_END &&
  status === InteractionStatus.HandleRedirect
