.warning {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.8rem;
}

.warningMsg {
  margin-bottom: 2rem;
}

.action {
  margin-top: 5.2rem;
}
