import { useEffect, type FC } from 'react'
import { Alert, AlertType, Modal, Text } from '@alku/ui-kit'
import {
  type Company,
  useGetDuplicateCompaniesLazyQuery
} from '~/src/__generated__/gql-types'
import { CompanyDupeCard } from '../../shared/dupes/CompanyDupeCard'
import { CompanyDupeRow } from '../../shared/dupes/CompanyDupeRow'

import * as styles from './duplicateCompany.module.css'

interface DuplicateDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  type: 'company'
  bullhornId: number | undefined
  yourCompanyData: Company
}

export const generateSubTitle = (foundDupesCount: number): string => {
  if (foundDupesCount !== 1)
    return `${foundDupesCount} other Account Managers have this company on their lists. Please Review:`
  else
    return `${foundDupesCount} other Account Manager has this company on their list. Please Review:`
}

export const DuplicateCompanyModal: FC<DuplicateDialogProps> = ({
  isOpen,
  setIsOpen,
  type,
  bullhornId,
  yourCompanyData
}): JSX.Element => {
  const [getDuplicateCompanies, { data, loading, error }] =
    useGetDuplicateCompaniesLazyQuery({
      variables: {
        bullhornId: bullhornId ?? 0
      }
    })

  useEffect(() => {
    if (isOpen) {
      void getDuplicateCompanies({
        variables: {
          bullhornId: bullhornId ?? 0
        }
      })
    }
  }, [getDuplicateCompanies, bullhornId, isOpen])

  const foundDupesCount: number = data?.getDuplicateCompanies.length ?? 0

  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title={`Duplicate${foundDupesCount > 1 ? 's' : ''} Found`}
      theme='error'
    >
      <div className={styles.subtitle} data-testid='company-duplicate-warning'>
        <Text style='three' weight='bold' color='alku-brand-primary'>
          {generateSubTitle(foundDupesCount)}
        </Text>
      </div>
      <CompanyDupeCard type='input' data={yourCompanyData} />
      <br />
      {error && (
        <Alert type={AlertType.Error} id='company-dupe-error'>
          {error.message}
        </Alert>
      )}
      {loading && <>Loading...</>}

      {!error && !loading && (
        <>
          {data && foundDupesCount !== 0 ? (
            data?.getDuplicateCompanies.map((duplicate) => {
              return (
                <div key={duplicate.id} className={styles['duplicate-item']}>
                  <CompanyDupeRow data={duplicate} />
                </div>
              )
            })
          ) : (
            <Text style='three' as='p'>
              Cannot find duplicate data, please try refreshing your list.
            </Text>
          )}
        </>
      )}
    </Modal>
  )
}
