import { useEffect, useState } from 'react'
import { Heading, Modal, Text } from '@alku/ui-kit'
/* Styles */
import * as styles from './listScreens.module.css'

import {
  SortType,
  useGetCurrentUserQuery,
  useGetLeadsListLazyQuery,
  type Company,
  useGetLeadsDataLazyQuery,
  type Lead,
  ListType,
  useResetNewCompaniesCountMutation
} from '../../__generated__/gql-types'

import { Companies } from '~/src/components/call-list/Companies/Companies'
import { Container } from '../../components/shared/container/Container'
/* Filter Data */
import { ListHeader } from '~/src/components/call-list/ListHeader/ListHeader'
import { useAuth } from '../../auth/hooks/useAuth'
import { useGlobalState } from '~/src/auth/hooks/useGlobal'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { UploadListForm } from '~/src/components/call-list/uploadListForm/UploadListForm'
import {
  ArchiveCallListModal,
  ExportCallListModal
} from '~/src/components/call-list/ExportCallListModal/ExportCallListModal'
import { LeadCompanyForm } from '~/src/components/call-list/CompanyForm/LeadCompanyForm'
import { CompanyForm } from '~/src/components/call-list/CompanyForm/CompanyForm'
import { SCREEN_STATE } from './helpers/list.helper'
import { Spinner } from '~/src/components/shared/spinner/Spinner'

const testId = 'not-a-real-active-id'

export const LeadsList = (): JSX.Element => {
  const { data: userData } = useGetCurrentUserQuery()
  const params = useParams()
  const navigate = useNavigate()
  const { id } = useAuth()
  const isReadOnly = id !== params?.id && params?.id !== testId

  // #region state
  const [screenState, setScreenState] =
    useState<keyof typeof SCREEN_STATE>('DEFAULT')
  const [createNewCompany, setCreateNewCompany] = useState(false)
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [editingCompany, setEditingCompany] = useState<Company | null>(null)
  const [editingLead, setEditingLead] = useState<Company | null>(null)
  const [sortValue, setSortValue] = useState<SortType>(
    userData?.currentUser?.call_list_sort_by ?? SortType.Alpha
  )
  const { setGlobalState, defaultOpenContactCategory } = useGlobalState()
  // #endregion state

  // #region gql
  const [
    getLeadsList,
    { data: listData, loading: getLeadsListLoading, called }
  ] = useGetLeadsListLazyQuery({
    variables: {
      userId: typeof params?.id === 'string' ? params.id : ''
    }
  })

  const [resetNewCompaniesCount] = useResetNewCompaniesCountMutation()

  useEffect(() => {
    void resetNewCompaniesCount({
      variables: {
        listType: ListType.Leads
      }
    })
  }, [resetNewCompaniesCount])

  const [contactSearchValue, setContactSearchValue] = useState<string>('')

  // #endregion gql

  // #region effects
  useEffect(() => {
    if (params?.id !== testId) {
      if (
        called &&
        !getLeadsListLoading &&
        (listData == null || listData === undefined)
      )
        navigate('/call-list/not-found')
    }
  }, [called, getLeadsListLoading, listData, navigate, params?.id])

  useEffect(() => {
    void getLeadsList()
  }, [getLeadsList])

  // #region handlers
  const handleCompanyModalClose = (): void => {
    toggleCompanyDialog()
    setCreateNewCompany(false)
    setEditingCompany(null)
    setEditingLead(null)
  }
  const toggleCompanyDialog = (): void => {
    setCompanyDialogOpen((prevState) => !prevState)
  }
  const toggleUploadDialog = (): void => {
    setUploadDialogOpen((prevState) => !prevState)
  }
  // #endregion handlers

  const isEditingCompany = editingCompany != null

  const data = listData?.getLeadsList

  function refreshCallList(): void {
    throw new Error('Function not implemented.')
  }

  const user = useAuth()

  const [getLeadsData, { data: leads }] = useGetLeadsDataLazyQuery({
    variables: {
      userId: typeof params?.id === 'string' ? params.id : user.id
    }
  })

  useEffect(() => {
    void getLeadsData()
  }, [getLeadsData])

  const leadsData = leads?.getLeadsData as Lead[]

  if (getLeadsListLoading) return <Spinner fullScreen />

  return (
    <>
      <Container>
        <ListHeader
          listType={ListType.Leads}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setGlobalState={setGlobalState}
          listData={data}
          isReadOnly={isReadOnly}
          defaultOpenContactCategory={defaultOpenContactCategory}
          setContactSearchValue={setContactSearchValue}
          contactSearchValue={contactSearchValue}
          setExportDialogOpen={setExportDialogOpen}
          setArchiveDialogOpen={setArchiveDialogOpen}
          toggleCompanyDialog={toggleCompanyDialog}
        />
        {listData?.getLeadsList === null && (
          <Text as='p' style='detail' color='grey-8'>
            An error occurred while loading your call list. Please try again
          </Text>
        )}
        {screenState === 'DEFAULT' &&
          listData?.getLeadsList?.companies != null &&
          listData.getLeadsList.companies.length === 0 && (
            <div className={styles['company-container']}>
              <Heading as='h2' level='four'>
                It’s a little lonely here.
              </Heading>
            </div>
          )}
        {listData?.getLeadsList.companies &&
          listData?.getLeadsList.companies.length > 0 && (
            <Companies
              listType={ListType.Leads}
              leads={leadsData}
              companiesData={listData?.getLeadsList.companies}
              userId={listData?.getLeadsList.user_ids.id}
              sortValue={sortValue}
              defaultOpenContactCategory={defaultOpenContactCategory}
              isReadOnly={isReadOnly}
              contactSearchValue={contactSearchValue}
              setEditingCompany={setEditingCompany}
              setCompanyDialogOpen={setCompanyDialogOpen}
              setEditingLead={setEditingLead}
              setScreenState={setScreenState}
            />
          )}
      </Container>
      <Modal
        open={companyDialogOpen}
        onRequestClose={handleCompanyModalClose}
        closeOnOutsideClick={false}
        title={
          screenState === SCREEN_STATE.UPDATING ? 'Edit Lead' : 'Add a Company'
        }
      >
        {companyDialogOpen && (
          <>
            {editingLead ? (
              <LeadCompanyForm
                toggleCreateNew={(status) => {
                  setCreateNewCompany(status)
                }}
                closeDialog={handleCompanyModalClose}
                companyData={editingLead}
                createNewCompany={createNewCompany}
                setCreateNewCompany={setCreateNewCompany}
                setScreenState={setScreenState}
                editMode={screenState === SCREEN_STATE.UPDATING}
                callListId={listData?.getLeadsList?.user_ids?.id as string}
              />
            ) : (
              <CompanyForm
                toggleCreateNew={(status) => {
                  setCreateNewCompany(status)
                }}
                closeDialog={handleCompanyModalClose}
                companyData={editingCompany}
                createNewCompany={createNewCompany}
                setCreateNewCompany={setCreateNewCompany}
                setScreenState={setScreenState}
                editMode={isEditingCompany}
                callListId={listData?.getLeadsList?.user_ids?.id as string}
              />
            )}
          </>
        )}
      </Modal>
      <Modal
        open={uploadDialogOpen}
        onRequestClose={toggleUploadDialog}
        closeOnOutsideClick={false}
      >
        <UploadListForm
          closeModal={toggleUploadDialog}
          refreshCallList={refreshCallList}
        />
      </Modal>
      <ArchiveCallListModal
        listType={ListType.Leads}
        isOpen={archiveDialogOpen}
        setIsOpen={setArchiveDialogOpen}
        callListUserId={listData?.getLeadsList?.user_ids?.id as string}
      />
      <ExportCallListModal
        listType={ListType.Leads}
        isOpen={exportDialogOpen}
        setIsOpen={setExportDialogOpen}
      />
    </>
  )
}
