import { createHttpLink, type ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'
import {
  type AccountInfo,
  PublicClientApplication,
  EventType
} from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { type AuthOptions } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink as awsCreateSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

const CLIENT_ID: string = process.env.CLIENT_ID
const apiUrl: string = process.env.GRAPHQL_ENDPOINT

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParams = {
  scopes: [`${CLIENT_ID}/.default`]
}

export const msalInstance = new PublicClientApplication(msalConfig)
export const getAccessToken = async (): Promise<string | null> => {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    const accessToken = await msalInstance
      .acquireTokenSilent(authenticationParams)
      .then((response) => {
        return response.accessToken
      })
      .catch((error) => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        console.log(error)
        return null
      })
    return accessToken as string
  }
  return null
}

/**
 * Creates HttpLink Apollo Link
 * https://www.apollographql.com/docs/react/api/link/apollo-link-http/
 * @param key
 * @returns ApolloLink
 */
export const httpLink = createHttpLink({
  uri: apiUrl
})

/**
 * Creates the Apollo Auth Link
 * https://www.apollographql.com/docs/react/networking/authentication/
 *
I Key and JWT
 * @param key
 * @returns ApolloLink
 */
export const authLink = setContext(async (request, { headers }) => {
  const token = (await getAccessToken()) as string
  let baseUrl = apiUrl
  if (request.operationName != null) baseUrl += `?${request?.operationName}`
  return {
    uri: `${baseUrl}`,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }
})

/**
 * Error handler for the network layer.
 * https://www.apollographql.com/docs/react/data/error-handling/
 */
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors !== undefined) {
      let errors: string[] = []
      if (graphQLErrors.length > 0) {
        errors = graphQLErrors.map(
          ({ message }) => `${message} on ${operation.operationName}`
        )
      }
      console.log(errors.join('\n'))
    }
    if (
      networkError !== undefined &&
      networkError !== null &&
      'statusCode' in networkError
    ) {
      switch (networkError.statusCode) {
        case 401:
          console.error(`${networkError.message} on ${operation.operationName}`)
          break
        default:
          console.error(`${networkError.message} on ${operation.operationName}`)
          break
      }
    }
  }
)

/**
 * Apollo Link for retry operations.
 * The delay object is the default for avoiding the 'thundering herd' of network requests if
 * the server goes down - so that the client won't crash it again by distributing the load.
 * https://www.apollographql.com/docs/react/api/link/apollo-link-retry
 */
export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error) => error ?? ''
  }
})

/**
 * Creates the Apollo Auth Link
 * https://github.com/awslabs/aws-mobile-appsync-sdk-js
 *
 * @param key
 * @returns ApolloLink
 */

const createSubscriptionHandshakeLink = (): ApolloLink => {
  const url = apiUrl
  const region = 'us-east-2'
  const auth: AuthOptions = {
    type: 'API_KEY' as const,
    apiKey: getAccessToken() as unknown as string
  }

  return awsCreateSubscriptionHandshakeLink({ url, region, auth }, httpLink)
}

export const getApolloLinks = (): ApolloLink =>
  from([
    errorLink,
    retryLink,
    // TODO: do we want to change this to ApolloLink.from([authLink, httpLink]) ?
    authLink.concat(httpLink),
    createSubscriptionHandshakeLink()
  ])

// Account selection logic is app dependent. Adjust as needed for different use cases.
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    // @ts-expect-error: see above
    const account: AccountInfo = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})
