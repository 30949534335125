import { type MenuList } from 'react-select/dist/declarations/src/components/Menu'
import { FixedSizeList } from 'react-window'

export const VirtualizedMenuList: typeof MenuList = (props) => {
  const { children } = props

  if (Array.isArray(children)) {
    return (
      <FixedSizeList
        width={'100%'}
        itemSize={45}
        height={320}
        itemCount={children.length}
      >
        {({ style, index }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    )
  }

  return <>{children}</>
}
