.accordion {
  width: 100%;

  &:nth-last-child(1 of :not(.hidden)) {
    &.open,
    .accordion-title {
      border-bottom: var(--border-table from global);
    }
  }
}

.accordion-bright-blue {
  .accordion-title,
  tbody {
    border-left: 0.4rem solid #5878a8;
  }
}

.accordion-text-green {
  .accordion-title,
  tbody {
    border-left: 0.4rem solid #719541;
  }
}

.accordion-text-red {
  .accordion-title,
  tbody {
    border-left: 0.4rem solid #de011c;
  }
}

.accordion-title {
  width: 100%;
  text-align: left;
  background-color: var(--grey-10 from global);
  border: none;
  cursor: pointer;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-top: var(--border-table from global);
  border-left: var(--border-table from global);
  border-right: var(--border-table from global);
}

.accordion-title p {
  margin-right: 1rem;
}

.accordion-content {
  background-color: var(--white from global);
}

.hidden {
  display: none;
}
