export const formatStatus = (status: string): string => {
  if (status === 'DBA' || status === 'Requalified') {
    return 'DBA'
  } else {
    const firstLetter = status.slice(0, 1).toUpperCase()
    const remainder = status.substring(1).toLowerCase()

    return `${firstLetter}${remainder}`
  }
}
