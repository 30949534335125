import * as styles from "./input.module.css";
import { ToggleProps } from "./Input.types";

/**
 * ToggleProps
 * @param {boolean} checked - Checked state
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange - Change event handler
 * @param {string} label - Label of the input
 * @param {string} ariaLabel - Aria label of the input
 * @param {string} className - Class name of the input
 * @returns
 */

export const Toggle = ({ checked, onChange, label, ariaLabel, className = "" }: ToggleProps) => {
  return (
    <label className={`${styles["toggle-switch"]} ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        aria-checked={checked}
        aria-label={ariaLabel}
        className={styles["toggle-switch-checkbox"]}
      />
      <span className={styles["toggle-switch-slider"]} />
      {label && <span className={styles["toggle-switch-label"]}>{label}</span>}
    </label>
  );
};
