import { FC, MouseEventHandler, useCallback, useEffect, useRef } from "react";
import { ModalProps } from "./Modal.types";
import { createPortal } from "react-dom";
import * as styles from "./modal.module.css";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import { Heading } from "../typography";
import { themeConfig } from "../helpers/getTheme";

/**
 * Modal Component
 * @param {ReactNode} children - The content of the alert, supporting text and hyperlinks.
 * @param {boolean} open - Whether the modal is open.
 * @param {() => void} onRequestClose - Callback function when the modal is closed.
 * @param {boolean} closeOnOutsideClick - Whether the modal can be closed by clicking outside of it.
 * @param {string} title - The title of the modal.
 * @param {"default" | "info" | "alert" | "success" | "error"} [theme="default"] - The theme of the modal.
 * @param {string} className - Additional class names to be added to the modal container (useful for sticky footers).
 * @returns
 */
export const Modal: FC<ModalProps> = ({
  children,
  open,
  onRequestClose,
  closeOnOutsideClick,
  title,
  theme = "default",
  className = "",
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const lastActiveElement = useRef<HTMLElement | null>(null);
  const { icon, color } = themeConfig[theme];

  // control the open state of the modal
  useEffect(() => {
    if (!dialogRef?.current) return;

    const dialogNode = dialogRef.current;
    if (open) {
      lastActiveElement.current = document.activeElement as HTMLElement;
      dialogNode.showModal();
      dialogNode.focus();
    } else if (dialogNode.open) {
      dialogNode.close();
      if (lastActiveElement.current instanceof HTMLElement) lastActiveElement.current.focus();
    }
  }, [open]);

  // add cancel handling
  const handleCancel = useCallback(
    (event: Event): void => {
      event.preventDefault();
      if (event.target === dialogRef.current) onRequestClose();
    },
    [onRequestClose],
  );

  useEffect(() => {
    if (!dialogRef?.current) return;
    const dialogNode = dialogRef.current;

    dialogNode.addEventListener("cancel", handleCancel);

    return () => {
      dialogNode.removeEventListener("cancel", handleCancel);
    };
  }, [dialogRef, handleCancel]);

  const handleOutsideClick: MouseEventHandler<HTMLDialogElement> = (event): void => {
    const dialogNode = dialogRef.current;
    if (closeOnOutsideClick && event.target === dialogNode) onRequestClose();
  };

  return createPortal(
    <dialog className={styles.modal} ref={dialogRef} onClick={handleOutsideClick}>
      <div className={`${styles["modal-close"] as string}`}>
        <Button as="button" id="close-modal" type="button" style="icon-only" ariaLabel="close" onClick={onRequestClose}>
          <Icon symbol="CloseOutlinedIcon" color="alku-brand-primary" size="lg" />
        </Button>
      </div>
      <div className={`${styles["modal-container"] as string} ${className}`}>
        {typeof title === "string" && (
          <div className={styles["modal-title"]}>
            {theme !== "default" && <Icon symbol={icon} color={color} size="lg" space="right" />}
            <Heading as="h3" style="three">
              {title}
            </Heading>
          </div>
        )}
        {open ? children : null}
      </div>
    </dialog>,
    document.body,
  );
};
