.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 16rem;
  margin-bottom: 3.6rem;
}

.detail {
  text-align: center;
  font-size: 2.4rem;
  max-width: 60rem;
  margin: 2.4rem 0;
}
