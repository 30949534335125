import { Heading, Text } from '@alku/ui-kit'
import * as styles from './dupes.module.css'
import * as sharedStyles from '../../../styles.module.css'
import classNames from 'classnames'
import { type AmclDuplicateCompany } from '~/src/__generated__/gql-types'

export const CompanyDupeRow = ({
  data
}: {
  data: AmclDuplicateCompany
}): JSX.Element => {
  const companyCreatedDate = data.created_ts
    ? new Date(data.created_ts).toLocaleDateString('en-US')
    : null

  return (
    <div className={classNames([styles.dupe, styles['dupe-row']])}>
      <div
        className={classNames(
          sharedStyles.flex,
          sharedStyles['align-center'],
          sharedStyles['space-between']
        )}
      >
        <Heading style='four' as='h3'>
          {data.owner.first_name} {data.owner.last_name}{' '}
          <Text style='four' as='span' weight='regular'>
            ({data.owner.division ?? 'Unknown'})
          </Text>
        </Heading>
        <div
          className={classNames(
            sharedStyles.flex,
            styles['company-row-content']
          )}
        >
          <div className={styles.date}>
            <Text style='detail' as='p'>
              Date Added:
            </Text>
            <Text style='detail' as='p' weight='bold'>
              {companyCreatedDate}
            </Text>
          </div>
          <div>
            <Text style='detail' as='p'>
              Contacts:
            </Text>
            <Text style='detail' as='p' weight='bold'>
              {data.contacts_count}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
