import { type FC } from 'react'
import { Button, Modal, Text } from '@alku/ui-kit'
import * as sharedStyles from '~/src/styles.module.css'

interface MoveCompanyDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onConfirm: () => void
  entityName: string
}

export const MoveCompanyModal: FC<MoveCompanyDialogProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  entityName
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title='Confirm Move Lead to Active Call List'
      theme='alert'
    >
      <Text as='p' style='detail' color='alku-black' weight='semibold'>
        {`Moving this company will remove the company and all related contacts for your leads list and add them to your active call list. Do you wish to proceed moving ${entityName}?`}
      </Text>
      <br />
      <br />
      <Button
        as='button'
        type='button'
        style='primary-impact'
        onClick={() => {
          onConfirm()
          setIsOpen(false)
          // Todo add loading state and popover
        }}
        ariaLabel='Confirm'
      >
        Confirm
      </Button>

      <Button
        as='button'
        type='button'
        style='primary-default'
        onClick={() => {
          setIsOpen(false)
        }}
        className={sharedStyles['cancel-button']}
      >
        Cancel
      </Button>
    </Modal>
  )
}
