import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useState
} from 'react'

import { Accordion } from '../../accordion/Accordion'
import * as styles from './company.module.css'
import classNames from 'classnames'
import {
  ContactStatus,
  type Company,
  type Lead,
  useUpdateCompanyMutation,
  ListType,
  useMoveLeadToActiveMutation,
  useMoveCompanyMutation
} from '../../../__generated__/gql-types'

import { ArchiveModal } from '../ArchiveModal/ArchiveModal'
import { MoveCompanyModal } from '../MoveCompanyModal/MoveCompanyModal'
import {
  handleArchiveCompany,
  handleLeadMoveToActive
} from './helpers/handleMoveCompany'
import { CompanyTitle } from './CompanyTitle'
import { CompanySummary } from './CompanySummary'
import { getContactFilters } from './helpers/contactsFilters'
import { Table, type TogglePopover } from '../../table/Table'
import { Popover } from '../../shared/popover/Popover'
import { DuplicateCompanyModal } from '../DuplicateCompanyModal/DuplicateCompanyModal'
import { useAuth } from '~/src/auth/hooks/useAuth'
import { type SCREEN_STATE } from '~/src/screens/listScreens/helpers/list.helper'

export enum AccordionTypes {
  ActiveContacts = 'Active Contacts',
  DBAContacts = 'DBA Contacts',
  DisqualifiedContacts = 'Disqualified Contacts'
}

interface CompanySectionProps {
  data: Company
  isExpanded: boolean
  defaultOpen?: AccordionTypes[]
  isReadOnly: boolean
  contactSearchQuery?: string
  onEdit: (company: Company) => void
  callListUserId: string
  listType: ListType
  leads?: Lead[]
  setCompanyDialogOpen: Dispatch<SetStateAction<boolean>>
  setScreenState: (state: keyof typeof SCREEN_STATE) => void
}

export const CompanySection: FC<CompanySectionProps> = ({
  data,
  isExpanded = false,
  defaultOpen,
  isReadOnly,
  contactSearchQuery,
  onEdit,
  callListUserId,
  listType,
  leads,
  setCompanyDialogOpen,
  setScreenState
}) => {
  const { id: currentUserId } = useAuth()

  const activeList = (data?.contacts ?? []).filter(
    (x) => x?.status === ContactStatus.Active
  )

  const dbaList = (data?.contacts ?? []).filter(
    (x) =>
      x?.status === ContactStatus.Dba || x?.status === ContactStatus.Requalified
  )

  const disqualifiedList = (data?.contacts ?? []).filter(
    (x) => x?.status === ContactStatus.Disqualified
  )

  const leadsList = data?.contacts ?? []

  const contactLength = activeList.length
  const dbaLength = dbaList.length
  const disqualifiedLength = disqualifiedList.length

  /* Company expanded/collapsed states */
  const [isCompanyExpanded, setIsCompanyExpanded] =
    useState<boolean>(isExpanded)

  /* Add contact form show/hidden states */
  const [showAddContactForm, setShowAddContactForm] = useState<boolean>(false)

  /* Accordion collapsed/uncollapsed states */
  const [isContactAccordionOpen, setIsContactAccordionOpen] =
    useState<boolean>(false)
  const [isDBAAccordionOpen, setIsDBAAccordionOpen] = useState<boolean>(false)
  const [isDisqualifiedAccordionOpen, setIsDisqualifiedAccordionOpen] =
    useState<boolean>(false)

  const [refreshAccordionTs, setRefreshAccordionTs] = useState<number>(0)

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [moveCompanyDialogOpen, setMoveCompanyDialogOpen] = useState(false)
  const [duplicateCompanyDialogOpen, setDuplicateCompanyDialogOpen] =
    useState(false)

  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [popoverMessage, setPopoverMessage] = useState<string>('')
  const [popoverIcon, setPopoverIcon] = useState<string>('')

  const togglePopover: TogglePopover = (icon, message, timeout = 2000) => {
    setPopoverMessage(message)
    setPopoverIcon(icon)
    setShowPopover(true)

    if (typeof timeout === 'number') {
      setTimeout(() => {
        setShowPopover(false)
      }, timeout)
    }
  }

  useEffect(() => {
    if (Array.isArray(defaultOpen) && defaultOpen.length > 0) {
      setIsContactAccordionOpen(
        !!defaultOpen.find((val) => val === AccordionTypes.ActiveContacts)
      )
      setIsDisqualifiedAccordionOpen(
        !!defaultOpen.find((val) => val === AccordionTypes.DisqualifiedContacts)
      )
      setIsDBAAccordionOpen(
        !!defaultOpen.find((val) => val === AccordionTypes.DBAContacts)
      )
      setIsCompanyExpanded(true)
      // This helps trigger the Accordion components useEffect
      setRefreshAccordionTs((v) => v + 1)
    }
  }, [defaultOpen])

  const handleContactAccordionToggle = (): void => {
    setShowAddContactForm(true)
    setIsContactAccordionOpen(true)
  }

  const [updateCompanyFn] = useUpdateCompanyMutation()
  const [moveLeadToActiveFn] = useMoveLeadToActiveMutation()
  const [moveCompanyFn] = useMoveCompanyMutation()

  const {
    filterActive,
    hidden,
    filteredActiveList,
    filteredDbaList,
    filteredDisqualifiedList,
    showActive,
    showDba,
    showDisqualified
  } = getContactFilters(contactSearchQuery, data)

  return (
    <>
      {showPopover && (
        <Popover popoverMessage={popoverMessage} popoverIcon={popoverIcon} />
      )}
      <div
        className={classNames(
          styles['company-container'],
          hidden && styles.hidden
        )}
      >
        <div
          className={classNames(
            styles.wrapper,
            isCompanyExpanded ? styles.open : ''
          )}
        >
          <CompanyTitle
            listType={listType}
            data={data}
            callListUserId={callListUserId}
            isReadOnly={isReadOnly}
            isCompanyExpanded={isCompanyExpanded}
            onEdit={onEdit}
            setIsCompanyExpanded={setIsCompanyExpanded}
            setArchiveDialogOpen={setArchiveDialogOpen}
            setDuplicatesDialogOpen={setDuplicateCompanyDialogOpen}
            setMoveCompanyDialogOpen={setMoveCompanyDialogOpen}
            setCompanyDialogOpen={setCompanyDialogOpen}
            moveCompanyFn={moveCompanyFn}
            setScreenState={setScreenState}
          />
          {isCompanyExpanded && (
            <CompanySummary
              data={data}
              leads={leads}
              isReadOnly={isReadOnly}
              listType={listType}
              handleContactAccordionToggle={handleContactAccordionToggle}
            />
          )}
        </div>

        {isCompanyExpanded && (
          <div className={styles.sections}>
            {listType !== ListType.Leads && (
              <>
                <Accordion
                  listType={listType}
                  title={AccordionTypes.ActiveContacts}
                  quantity={contactLength}
                  color='bright-blue'
                  list={filterActive ? filteredActiveList : activeList}
                  setShowAddContactForm={setShowAddContactForm}
                  showAddContactForm={showAddContactForm}
                  isOpen={isContactAccordionOpen || showAddContactForm}
                  companyObject={data}
                  refreshAccordionTs={refreshAccordionTs}
                  isReadOnly={isReadOnly}
                  hidden={!showActive}
                />
                <Accordion
                  listType={listType}
                  title={AccordionTypes.DBAContacts}
                  quantity={dbaLength}
                  color='text-green'
                  list={filterActive ? filteredDbaList : dbaList}
                  isOpen={isDBAAccordionOpen}
                  companyObject={data}
                  refreshAccordionTs={refreshAccordionTs}
                  isReadOnly={isReadOnly}
                  hidden={!showDba}
                />
                <Accordion
                  listType={listType}
                  title={AccordionTypes.DisqualifiedContacts}
                  quantity={disqualifiedLength}
                  color='text-red'
                  list={
                    filterActive ? filteredDisqualifiedList : disqualifiedList
                  }
                  isOpen={isDisqualifiedAccordionOpen}
                  companyObject={data}
                  refreshAccordionTs={refreshAccordionTs}
                  isReadOnly={isReadOnly}
                  hidden={!showDisqualified}
                />
              </>
            )}
            {listType === ListType.Leads && (
              <div className={styles.leadsListContainer}>
                <Table
                  list={leadsList}
                  listType={listType}
                  companyObject={data}
                  setShowAddContactForm={setShowAddContactForm}
                  showAddContactForm={showAddContactForm}
                  contactCategory={AccordionTypes.ActiveContacts}
                  isReadOnly={isReadOnly}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {(!isReadOnly || currentUserId === callListUserId) && (
        <>
          <ArchiveModal
            isOpen={archiveDialogOpen}
            setIsOpen={setArchiveDialogOpen}
            onConfirm={() => {
              handleArchiveCompany(
                updateCompanyFn,
                data,
                callListUserId,
                togglePopover
              )
            }}
            entityName={data.name}
            type='company'
          />
          <MoveCompanyModal
            isOpen={moveCompanyDialogOpen}
            setIsOpen={setMoveCompanyDialogOpen}
            onConfirm={() => {
              handleLeadMoveToActive(
                moveLeadToActiveFn,
                data,
                callListUserId,
                togglePopover
              )
            }}
            entityName={data.name}
          />
          <DuplicateCompanyModal
            isOpen={duplicateCompanyDialogOpen}
            setIsOpen={setDuplicateCompanyDialogOpen}
            type='company'
            bullhornId={data.bullhorn_id as number}
            yourCompanyData={data}
          />
        </>
      )}
    </>
  )
}
