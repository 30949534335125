import React from 'react'
import { Text } from '@alku/ui-kit'
import styles from './flyout.module.css'

interface FlyoutHelpTextProps {
  message: string
  className?: string
}

export const FlyoutHelpText: React.FC<FlyoutHelpTextProps> = ({
  message,
  className
}) => {
  return (
    <Text
      as='p'
      style='note'
      color='text-disabled'
      weight='normal'
      className={className ?? styles['list-extra']}
    >
      {message}
    </Text>
  )
}
