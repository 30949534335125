@import "../base.css"; /* TODO: There might be a way we can explicitly require this for the package without having to import in every component that might be used */

/*
  Button:
  Component Styles
*/

.primary-impact,
.primary-default {
  min-width: 14.5rem;
  color: var(--alku-white from global);
  background-color: var(--alku-brand-primary from global);
  font-size: 1.6rem;
}

.primary-impact {
  color: var(--alku-white from global);
  background-color: var(--alku-brand-primary from global);
  border: none;
  padding: 1.5rem 3.6rem;

  &:hover {
    background-color: #f5001e;
  }

  &:active {
    background-color: #a50014;
  }
}

.primary-default {
  color: var(--alku-black from global);
  background-color: var(--alku-grey-1 from global); /* consolidation from #F9F9F9 */
  border: var(--alku-border-default from global);
  padding: 1.4rem 3.5rem; /* account for border */

  &:hover {
    background-color: var(--alku-white from global);
  }

  &:active {
    border-color: #383a42;
  }
}

.primary-default,
.primary-impact,
.secondary {
  &:disabled {
    opacity: 0.5;
  }
}

.secondary {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  min-width: 12.9rem;
  max-height: 3.8rem;

  &:hover {
    background-color: var(--alku-white from global);
  }

  &:active {
    border-color: var(--alku-icon-default from global);
  }
}

.function {
  padding: 1.1rem 1.3rem;
}

.secondary,
.function {
  color: var(--alku-black from global);
  border: var(--alku-border-default from global);
  background-color: var(--alku-grey-1 from global);

  &:hover {
    background-color: var(--alku-white from global);
  }

  &:active {
    border-color: var(--alku-icon-default from global);
  }
}

.primary-default,
.primary-impact,
.secondary,
.function {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
  transition: 0.3s ease all;
  letter-spacing: -0.01rem;
  line-height: 1;
  font-weight: 600;
  text-align: center;

  &:focus {
    box-shadow: 0 0 1rem 0 rgba(9, 55, 124, 0.5);
  }
}

.icon-only {
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;

  &:disabled,
  &[aria-disabled] {
    color: var(--alku-icon-disabled from global);

    &:hover {
      cursor: not-allowed;
    }
  }
}

.link {
  text-decoration: underline;
  background: transparent;
  padding: 0;
  border: none;
}
