/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { type GroupBase } from 'react-select'
import {
  ContactSource,
  DbaActionType,
  DbaTradeshow
} from '~/src/__generated__/gql-types'
import { type SelectValue } from '~/src/components/shared/select/types'

export const sourceOptions: Partial<
  Record<ContactSource, SelectValue<ContactSource>>
> = {
  [ContactSource.ColdCall]: {
    label: 'Cold Call',
    value: ContactSource.ColdCall
  },
  [ContactSource.Reference]: {
    label: 'Reference',
    value: ContactSource.Reference
  },
  [ContactSource.YellowLead]: {
    label: 'Yellow Lead',
    value: ContactSource.YellowLead
  },
  [ContactSource.HotLead]: {
    label: 'Hot Lead',
    value: ContactSource.HotLead
  },
  [ContactSource.Inbound]: {
    label: 'Inbound',
    value: ContactSource.Inbound
  },
  [ContactSource.Marketplace]: {
    label: 'Marketplace',
    value: ContactSource.Marketplace
  },
  [ContactSource.InHouse]: {
    label: 'In House',
    value: ContactSource.InHouse
  },
  [ContactSource.ClienteVisit]: {
    label: 'Client Visit',
    value: ContactSource.ClienteVisit
  },
  [ContactSource.Referral]: {
    label: 'Referral',
    value: ContactSource.Referral
  },
  [ContactSource.WebsiteHubspot]: {
    label: 'Website-HubSpot',
    value: ContactSource.WebsiteHubspot
  },
  [ContactSource.SocialMedia]: {
    label: 'Social Media',
    value: ContactSource.SocialMedia
  },
  [ContactSource.Linkedin]: {
    label: 'LinkedIn',
    value: ContactSource.Linkedin
  },
  [ContactSource.Thirdparty]: {
    label: '3rd Party',
    value: ContactSource.Thirdparty
  },
  [ContactSource.Indeed]: {
    label: 'Indeed',
    value: ContactSource.Indeed
  },
  [ContactSource.Tradeshow]: {
    label: 'Tradeshow Attended',
    value: ContactSource.Tradeshow
  },
  [ContactSource.Webinar]: {
    label: 'Webinar',
    value: ContactSource.Webinar
  },
  [ContactSource.Billing]: {
    label: 'Billing',
    value: ContactSource.Billing
  },
  [ContactSource.Zoominfo]: {
    label: 'ZoomInfo',
    value: ContactSource.Zoominfo
  },
  [ContactSource.Swordfish]: {
    label: 'Swordfish',
    value: ContactSource.Swordfish
  },
  [ContactSource.MarketingEmail]: {
    label: 'Marketing Email - Med',
    value: ContactSource.MarketingEmail
  },
  [ContactSource.LostHiringManager]: {
    label: 'Lost Hiring Manager',
    value: ContactSource.LostHiringManager
  }
}

const salesNotes: Array<SelectValue<DbaActionType>> = [
  { label: 'Phone Connect', value: DbaActionType.PhoneConnect },
  { label: 'Video Call', value: DbaActionType.VideoCall },
  { label: 'Text', value: DbaActionType.Text },
  { label: 'Left Message', value: DbaActionType.LeftMessage },
  { label: 'Email', value: DbaActionType.Email },
  { label: 'Sup', value: DbaActionType.Sup },
  { label: 'Forward Market', value: DbaActionType.ForwardMarket },
  { label: 'Client Visit', value: DbaActionType.ClientVisit },
  { label: 'Requalified DBA', value: DbaActionType.RequalifiedDba },
  { label: 'AM Screen', value: DbaActionType.AmScreen },
  { label: 'Candidate Call', value: DbaActionType.CandidateCall },
  { label: 'Interview Questions', value: DbaActionType.InterviewQuestions },
  { label: 'Interview Feedback', value: DbaActionType.InterviewFeedback },
  { label: 'Manager Referral', value: DbaActionType.ManagerReferral }
]

const internNotes: Array<SelectValue<DbaActionType>> = [
  { label: 'Intern Screened', value: DbaActionType.InternScreened },
  { label: 'Intern Supps', value: DbaActionType.InternSupps },
  {
    label: 'Intern Manager Cell Phone',
    value: DbaActionType.InternManagerCellPhone
  }
]

const governmentNotes: Array<SelectValue<DbaActionType>> = [
  {
    label: 'Clearance Check Approved',
    value: DbaActionType.ClearanceCheckApproved
  },
  {
    label: 'Clearance Approved Hesitation',
    value: DbaActionType.ClearanceApprovedHesitation
  },
  {
    label: 'Clearance Check Rejected',
    value: DbaActionType.ClearanceCheckRejected
  },
  { label: 'Crossover Update', value: DbaActionType.CrossoverUpdate },
  { label: 'Crossover Approved', value: DbaActionType.CrossoverApproved },
  { label: 'Crossover Rejected', value: DbaActionType.CrossoverRejected },
  { label: 'Prescreened', value: DbaActionType.Prescreened },
  { label: 'Inhpuse Interview', value: DbaActionType.InhouseInterview },
  { label: 'FE Meeting', value: DbaActionType.FeMeeting },
  { label: 'Referral', value: DbaActionType.Referral },
  { label: 'Security Note', value: DbaActionType.SecurityNote },
  { label: 'Scheduled Call', value: DbaActionType.ScheduledCall },
  { label: 'Annual Review', value: DbaActionType.AnnualReview },
  { label: 'Exit Interview', value: DbaActionType.ExitInterview }
]

export const groupedActionOptions: Array<
  GroupBase<SelectValue<DbaActionType>>
> = [
  {
    label: 'Sales Notes',
    options: salesNotes
  },
  {
    label: 'Intern Notes',
    options: internNotes
  },
  {
    label: 'Government Notes',
    options: governmentNotes
  }
]

export const tradeshowOptions: Partial<
  Record<DbaTradeshow, SelectValue<DbaTradeshow>>
> = {
  [DbaTradeshow.Unavailable]: {
    label: 'Tradeshow Unavailable',
    value: DbaTradeshow.Unavailable
  },
  [DbaTradeshow.Himss_2024]: {
    label: '2024 HIMSS',
    value: DbaTradeshow.Himss_2024
  },
  [DbaTradeshow.GlobalPrivacySummit_2024]: {
    label: '2024 Global Privacy Summit',
    value: DbaTradeshow.GlobalPrivacySummit_2024
  },
  [DbaTradeshow.Rsa_2024]: {
    label: '2024 RSA',
    value: DbaTradeshow.Rsa_2024
  },
  [DbaTradeshow.InforConnect_2024]: {
    label: '2024 Infor Connect',
    value: DbaTradeshow.InforConnect_2024
  },
  [DbaTradeshow.MedicalDeviceConference_2024]: {
    label: '2024 Medical Device and Diagnostic Labelling Conference',
    value: DbaTradeshow.MedicalDeviceConference_2024
  },
  [DbaTradeshow.SapBostonWorldTour_2024]: {
    label: '2024 SAP Boston World Tour',
    value: DbaTradeshow.SapBostonWorldTour_2024
  },
  [DbaTradeshow.SapphireNow_2024]: {
    label: '2024 Sapphire Now',
    value: DbaTradeshow.SapphireNow_2024
  },
  [DbaTradeshow.OracleAscend_2024]: {
    label: '2024 Oracle Ascend',
    value: DbaTradeshow.OracleAscend_2024
  },
  [DbaTradeshow.OdtugKscope_2024]: {
    label: '2024 ODTUG Kscope',
    value: DbaTradeshow.OdtugKscope_2024
  },
  [DbaTradeshow.EpicUgm_2024]: {
    label: '2024 Epic UGM',
    value: DbaTradeshow.EpicUgm_2024
  },
  [DbaTradeshow.OracleCloudWorld_2024]: {
    label: '2024 Oracle Cloud World',
    value: DbaTradeshow.OracleCloudWorld_2024
  },
  [DbaTradeshow.Raps_2024]: {
    label: '2024 RAPS',
    value: DbaTradeshow.Raps_2024
  },
  [DbaTradeshow.SalesforceDreamforce_2024]: {
    label: '2024 Salesforce Dreamforce',
    value: DbaTradeshow.SalesforceDreamforce_2024
  },
  [DbaTradeshow.WorkdayRising_2024]: {
    label: '2024 Workday Rising',
    value: DbaTradeshow.WorkdayRising_2024
  },
  [DbaTradeshow.UkgAspire_2024]: {
    label: '2024 UKG Aspire',
    value: DbaTradeshow.UkgAspire_2024
  },
  [DbaTradeshow.UkgCampaign_2024]: {
    label: '2024 UKG Campaign',
    value: DbaTradeshow.UkgCampaign_2024
  },
  [DbaTradeshow.CernerHealthSummit_2024]: {
    label: '2024 Cerner Health Summit',
    value: DbaTradeshow.CernerHealthSummit_2024
  },
  [DbaTradeshow.EpicOchsnerPartnerSummit_2024]: {
    label: '2024 Epic Ochsner Strategic Partner Summit',
    value: DbaTradeshow.EpicOchsnerPartnerSummit_2024
  }
}
