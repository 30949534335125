import type { StoreObject } from '@apollo/client'
import {
  type MoveContactMutationFn,
  type ListType,
  GetActiveListDocument,
  ContactStatus,
  GetActiveListSummaryDocument,
  type MoveContactMutation,
  type Company
} from '../../../__generated__/gql-types'
import type { TogglePopover } from '../Table'

export const handleMoveContactToActive = (
  moveContactFn: MoveContactMutationFn,
  listType: ListType,
  id: string,
  company: Company,
  callListUserId: string,
  togglePopover: TogglePopover,
  toggleFlyout: () => void
): void => {
  void moveContactFn({
    variables: { listType, id },
    onCompleted: () => {
      toggleFlyout()
      togglePopover('success', 'Contact moved successfully')
    },
    onError: (error) => {
      togglePopover('error', error.message)
    },
    update: (cache, result) => {
      const rootKey = 'moveContact' as keyof MoveContactMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )
      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // remove archived contact from the cache
      if (result?.data?.moveContact?.status === ContactStatus.Archived) {
        cache.evict({ id: contactCacheKey })
      } else {
        // filters out the contact from the company object
        cache.modify({
          id: cache.identify(company),
          fields: {
            contacts(existingContactRefs) {
              return [
                ...existingContactRefs.filter(
                  (element: { __ref: string }) =>
                    element.__ref !== contactCacheKey
                )
              ]
            }
          }
        })
        // TODO: might want to modify the activeList cache by searching for the company and modify
        // add the contact into that companies contact. I think that will require writing a GQL fragment to insert item.
      }
      // optional garbage collect of evicted contacts
      cache.gc()
    },
    refetchQueries: [
      {
        query: GetActiveListDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetActiveListSummaryDocument,
        variables: { userId: callListUserId }
      }
    ],
    awaitRefetchQueries: true
  })
}
