/*
  Icon:
  Component Styles
*/

.icon {
  transition: 0.3s ease all;
  transform: translate(0) rotate(0);
}

/* Sizes */
.xs {
  font-size: 0.9rem;
}

.sm {
  font-size: 1.3rem;
}

.md {
  font-size: 1.6rem;
}

.lg {
  font-size: 2rem;
}

.xl {
  font-size: 2.4rem;
}

.xxxl {
  font-size: 6.8rem;
}

/* Space */

.none {
  padding: 0;
}

.right {
  padding-right: 0.8rem;
}

.left {
  padding-left: 0.8rem;
}

.center {
  padding: 0 0.8rem;
}

.no-shrink {
  flex-shrink: 0;
  line-height: 1;
}

/* rotate */
.rotate {
  transform-origin: center;
  transform: translate(50%, 0%) rotate(-180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}
