import { useMemo } from 'react'
import { SelectDropdown } from '@alku/ui-kit'
import { type CallListSummary } from '../../../__generated__/gql-types'

import * as styles from './directoryFilters.module.css'
import { type SelectValue } from '../../shared/select/types'
import { useAuth } from '~/src/auth/hooks/useAuth'

interface DirectoryFiltersProps {
  data: { allCallListsSummaries?: CallListSummary[] }
  onNameChange: (value: SelectValue<string> | null | undefined) => void
  onDivChange: (value: Array<SelectValue<string>> | null) => void
  selectedName: SelectValue<string> | undefined | null
  selectedDivisions: Array<SelectValue<string>> | null
}

const DirectoryFilters = ({
  data,
  onNameChange,
  onDivChange,
  selectedName,
  selectedDivisions
}: DirectoryFiltersProps): JSX.Element => {
  const { id: userId } = useAuth()

  // Build both option lists at the same time
  const { divisionOptions, nameOptions } = useMemo(() => {
    // DivisionsOptions need to be generated based off summary data NOT categories data from the API bc they are different
    const divisionOptions: Array<SelectValue<string>> = []
    const nameOptions: Array<SelectValue<string>> = []
    if (Array.isArray(data?.allCallListsSummaries)) {
      data.allCallListsSummaries.forEach((summary) => {
        const { user_division, first_name, last_name, id } = summary
        const fullname = `${first_name ?? ''} ${last_name ?? ''}` as const

        // If there is a division selected, we need to make sure the keyword options are limited to that division
        if (
          selectedDivisions === null ||
          selectedDivisions?.length === 0 ||
          selectedDivisions.some((div) => div?.value === (user_division ?? ''))
        ) {
          // Check for dupes(might not be necessary considering the comparison is using their id)
          // There might be a case where the name is exactly the same, in which case we may want to attatch a discriminator
          const notAdded = nameOptions.findIndex((x) => x.value === id) === -1
          const isCurrentUser = id === userId

          if (notAdded && !exclusionList.includes(fullname) && !isCurrentUser) {
            nameOptions.push({
              label: fullname,
              value: id
            })
          }
        }

        // Check for dupes to ensure each division only appears once
        if (
          Boolean(user_division) &&
          divisionOptions.findIndex(
            (x) => x.value === (user_division ?? '')
          ) === -1
        ) {
          divisionOptions.push({
            label: user_division ?? '',
            value: user_division?.toString() ?? ''
          })
        }

        // Sort division options alphabetically
        divisionOptions.sort(function (a, b) {
          if (a.label < b.label) return -1
          if (a.label > b.label) return 1
          return 0
        })
      })
    }
    return { divisionOptions, nameOptions }
  }, [data, selectedDivisions, userId])

  return (
    <form role='search' className={styles.list}>
      <SelectDropdown
        isMulti
        options={divisionOptions}
        onChange={(values) => {
          onDivChange(
            divisionOptions.filter((option) =>
              values?.includes(option.value)
            ) ?? null
          )
        }}
        onClear={() => {
          onDivChange(null)
        }}
        isSearchable
        placeholder='Filter by Division...'
        name='Division'
        ariaLabel='Filter by Division'
        screenReaderOnlyLabel
        id='div-filter-id'
        value={selectedDivisions}
      />
      <SelectDropdown
        options={nameOptions}
        onChange={(value) => {
          onNameChange(nameOptions.find((option) => option.value === value))
        }}
        onClear={() => {
          onNameChange(null)
        }}
        isSearchable
        placeholder='Filter by Name...'
        name='Keyword'
        ariaLabel='Filter by Name'
        screenReaderOnlyLabel
        id='name-filter-id'
        value={selectedName}
      />
    </form>
  )
}

const exclusionList: Array<`${string} ${string}`> = ['ALKU Everywhere']

export default DirectoryFilters
