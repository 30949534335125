@custom-media --sm (min-width: 48em); /* 768px */
@custom-media --md (min-width: 62em); /* 992px */
@custom-media --lg (min-width: 80em); /* 1280px */

.container {
  position: relative;
  display: inline-flex;
  background-color: var(--grey-4 from global);
  box-shadow: var(--shadow-card from global);
  min-height: 15.6rem;
  width: 100%;

  &.my-list {
    border-left: 0.6rem solid var(--alku-red from global);
  }
  &.leads {
    border-left: 0.6rem solid var(--grey-6 from global);
  }
}

@media (--sm) {
  .container {
    width: calc(50% - 2rem);
  }
}

@media (--md) {
  .container {
    width: calc(33% - 2rem);
  }
}

@media (--lg) {
  .container {
    width: calc(25% - 2rem);
  }
}

.tile {
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &.default {
    padding-left: 2.4rem;
    padding-top: 3.1rem;

    .details {
      align-items: start;
    }
  }

  &.my-list {
    padding-left: 1.8rem;
    padding-top: 1.9rem;

    .details {
      align-items: center;
    }
  }
}

.title {
  margin-bottom: 1.5rem; /* consolidated */
  line-height: 1.4;
  margin-right: 3rem;
}

.details {
  display: flex;
}

.aggregates {
  padding-left: 1rem; /* consolidated */
}

.count-item {
  padding-right: 1rem;
}

.action {
  position: absolute;
  top: 1.6rem; /* consolidated */
  right: 1.8rem; /* consolidated */
}

.flyout-container {
  position: absolute;
  left: -2.4rem;
  top: 100%;
  width: 15.6rem;
  z-index: 150;
}

.countBadge {
  position: absolute;
  top: 0;
  right: 0rem;
  background-color: var(--alku-red from global);
  color: var(--white from global);
  padding: 0.5rem;
  margin: 2rem 2rem 0 0;
  border-radius: 4rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
}

.badgeWithFlyout {
  right: 3.5rem;
}
