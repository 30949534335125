import { Heading, Text } from '@alku/ui-kit'
import * as styles from './dupes.module.css'
import * as sharedStyles from '../../../styles.module.css'
import classNames from 'classnames'
import {
  type AddCompanyInput,
  type CreateCompanyInput,
  type BullhornDuplicateCompany,
  type Company
} from '~/src/__generated__/gql-types'

export const CompanyDupeCard = ({
  data,
  type
}: {
  data:
    | AddCompanyInput
    | CreateCompanyInput
    | BullhornDuplicateCompany
    | Company
  type: 'bullhorn' | 'input'
}): JSX.Element => {
  return (
    <div className={classNames([styles.dupe, styles['dupe-card']])}>
      <Heading style='four' as='h3'>
        {type === 'bullhorn' ? 'Bullhorn Record:' : 'Your Company:'}
      </Heading>
      <div className={styles.container}>
        <div className={sharedStyles.flex}>
          <div className={styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Company Name:
              </Text>
              <Text style='detail' as='p' weight='bold'>
                {data.name}
              </Text>
            </div>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Phone:
              </Text>
              <Text style='detail' as='p' weight='bold'>
                {data.phone}
              </Text>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Address:
              </Text>
              <Text style='detail' as='p'>
                <Text style='detail' as='span' weight='bold'>
                  {data.address1}
                </Text>
                {data.address2 && (
                  <>
                    <br />
                    <Text style='detail' as='span' weight='bold'>
                      {data.address2}
                    </Text>
                  </>
                )}
                <br />
                <Text style='detail' as='span' weight='bold'>
                  {data.city}, {data.state} {data.postal}
                </Text>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
