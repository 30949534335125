import { type ReactNode, type FC, useState, useCallback } from "react";
import classNames from "classnames";
import * as styles from "./flyout.module.css";
import { Button } from "../button/Button"; // TODO: add index files at the component level so we can manage imports easier
import { type ButtonProps } from "../button/Button.types";
import { Icon } from "../icon/Icon";
import { type IconProps } from "../icon/Icon.types";
import { Flyout } from "./Flyout";
import { type FlyoutProps } from "./flyout.types";

interface FlyoutIconButtonProps {
  label: string;
  id?: string;
  iconProps?: Omit<Partial<IconProps>, "symbol"> & {
    symbol: IconProps["symbol"];
  };
  flyoutChildren?: ReactNode;
  buttonProps?: Omit<Partial<ButtonProps>, "onClick" | "id">;
  position?: "right" | "left";
  flyoutWidth?: FlyoutProps["width"];
  type?: "account" | "default";
}

export const FlyoutIconButton: FC<FlyoutIconButtonProps> = (props) => {
  const {
    label,
    id,
    iconProps,
    flyoutChildren,
    buttonProps,
    position = "right",
    flyoutWidth,
    type = "default",
  } = props;
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  const toggleFlyoutVisibility = useCallback((): void => {
    setIsFlyoutOpen((bool) => !bool);
  }, []);

  return (
    <>
      <Button
        as="button"
        type="button"
        style="function"
        ariaLabel={label}
        id={id}
        onClick={toggleFlyoutVisibility}
        {...buttonProps}
      >
        {type === "account" ? (
          <>
            {buttonProps?.children ?? ""}
            {iconProps && <Icon color="black" size="lg" {...iconProps} rotate={isFlyoutOpen} />}
          </>
        ) : (
          <>
            {iconProps && <Icon color="black" size="lg" {...iconProps} rotate={isFlyoutOpen} />}
            {buttonProps?.children ?? ""}
          </>
        )}
      </Button>
      <div
        className={classNames(
          type === "account" ? styles["account-wrapper"] : styles["default-wrapper"],
          styles[`position-${position}`],
        )}
      >
        <Flyout type={type} isOpen={isFlyoutOpen} width={flyoutWidth ?? "lg"} setIsOpen={setIsFlyoutOpen}>
          <ul>{flyoutChildren}</ul>
        </Flyout>
      </div>
    </>
  );
};
