/*
  Tooltip:
  Implementation - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
*/
.tooltip {
  position: relative;
  display: inline-flex;

  &.md {
    [role='tooltip'] {
      width: 21.6rem;
    }
  }

  /* only supports center rn */
  &.lg {
    [role='tooltip'] {
      width: 25.6rem;

      &.center {
        right: calc(50% - 13.08rem);
      }
    }
  }

  &.xl {
    [role='tooltip'] {
      width: 30rem;
      white-space: normal;

      &.left {
        position: absolute;
        left: -1.8rem;
      }
    }
  }

  [role='tooltip'] {
    visibility: hidden;
    position: absolute;
    bottom: 4rem;
    background: var(--white from global);
    color: var(--icon-default from global);
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1.2rem;
    border: 0.1rem solid var(--grey-7 from global);
    border-radius: 0.5rem;
    box-shadow: var(--shadow-tooltip from global);
    z-index: 10;

    &.center {
      right: calc(50% - 11.08rem);
      &::after {
        right: calc(50% - 0.85rem);
      }
    }

    &.right {
      right: -2rem;
      &::after {
        right: 2rem;
      }
    }

    &.left {
      left: -2rem;
      &::after {
        left: 2rem;
      }
    }

    &::after {
      width: 1.5rem;
      height: 1.5rem;
      background: var(--white from global);
      transform: rotate(45deg);
      position: absolute;
      content: '';
      bottom: -0.85rem;
      border-bottom: 0.1rem solid var(--grey-7 from global);
      border-right: 0.1rem solid var(--grey-7 from global);
      border-bottom-right-radius: 0.5rem;
      box-shadow: 0.2rem 0.2rem 0.5rem -0.2rem rgba(0, 0, 0, 0.2);
    }
  }

  [aria-describedby]:hover + [role='tooltip'],
  [aria-describedby]:focus-visible + [role='tooltip'] {
    visibility: visible;
  }
}
