.form {
  width: 100%;
}

.formGroup {
  margin-bottom: 2.4rem;
  width: 70%;

  &:last-of-type {
    margin-bottom: 3.2rem;
  }
}

.alert {
  margin-bottom: 3.2rem;
}

.formContainer div[data-lastpass-icon-root] {
  display: none !important;
}

.cancel-button {
  margin-left: 2rem;
}
