import * as styles from "./typography.module.css";
import * as classes from "../classes.module.css";
import classNames from "classnames";
import { TextProps } from "./typography.types";
import { handleNewTab } from "../helpers/handleNewTab";

/**
 * Text
 * @param {string} style - the style name of the text element
 * @param {string} [as] - The html element needed. "div", "p", "strong", "a" and "span" supported.
 * @param {ReactNode} children - The text or fragment nested inside the element.
 * @param {string} [color] - name of color from shared color classes
 * @param {string} [weight] - name of weight from shared classes. "regular", "semibold" and "bold" supported.
 * @param {string} [href] - link url
 * @param {boolean} [linkNewTab] - if true, open link in new tab
 */

export const Text = ({
  children,
  style,
  as,
  color = "alku-black",
  weight = "regular",
  href,
  linkNewTab = false,
  className,
}: TextProps): JSX.Element => {
  const optionalLinkProps: React.HTMLProps<HTMLAnchorElement> = {};
  if (linkNewTab && as === "a") {
    optionalLinkProps.onClick = (event) => {
      event.preventDefault();
      handleNewTab(href);
    };
    optionalLinkProps.rel = "external nofollow nooopener";
  }

  switch (as) {
    case "p":
      return <p className={classNames(styles[style], classes[weight], classes[color], className)}>{children}</p>;
    case "span":
      return <span className={classNames(styles[style], classes[weight], classes[color], className)}>{children}</span>;
    case "strong":
      return <strong className={classNames(styles[style], classes[weight], classes[color])}>{children}</strong>;
    case "a": {
      return (
        <a href={href} className={classNames(styles[style], classes[weight], classes[color])} {...optionalLinkProps}>
          {children}
        </a>
      );
    }
    default:
      return <div className={classNames(styles[style], classes[weight], classes[color])}>{children}</div>;
  }
};
