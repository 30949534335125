.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  will-change: transform;
}

.header-hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.logo {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.logo img {
  width: 150px;
  min-width: 150px;
}

.header-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.app-links a {
  margin-right: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  padding: 6px;
  line-height: 2;
}

.app-selected {
  background-color: #92a18a75;
  padding: 6px;
  border-radius: 5px;
  font-weight: 700;
}

.user-icon {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 200px;
}

.user-initials {
  border-radius: 50%;
  background-color: #92a18a75;
  color: black;
  width: 10vw;
  height: 10vw;
  max-width: 3.5rem;
  max-height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-name {
  margin-left: 10px;
  margin-right: 5px;
}

.icon-container {
  min-width: 2rem;
  min-height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.flyout-menu {
  position: absolute;
  right: 20px;
  top: 50px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

@media (max-width: 1024px) {
  .flyout-menu {
    top: 60px;
  }
}

.flyout-item {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 6px;
  cursor: pointer;
}

.flyout-item-selected {
  background-color: #92a18a50;
  border-radius: 5px;
  padding: 6px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -10px;
  right: 0;
  transform: translateX(-50%);
  z-index: 1;
}
