import { useParams } from 'react-router-dom'
import {
  type Company,
  useGetActiveListQuery,
  useGetActiveListSummaryQuery,
  ListType,
  useGetLeadsListQuery,
  useGetLeadsListSummaryQuery
} from '~/src/__generated__/gql-types'

interface HookReturn {
  company: Company | undefined
  division: string | null | undefined
  loading: boolean
}

export default function useGetCompanyAndDivisionByRef(
  listType: ListType,
  ref_company?: string
): HookReturn {
  const params = useParams()
  const variables = {
    userId: typeof params?.id === 'string' ? params.id : ''
  }
  // TODO: update to use getList and pass in ListType so we don't have to use three different queries for this
  const { data: calllistData, loading: calllistLoading } =
    useGetActiveListQuery({
      variables
    })

  const { data: summaryData, loading: summaryLoading } =
    useGetActiveListSummaryQuery({
      variables: {
        userId: typeof params?.id === 'string' ? params.id : ''
      }
    })

  const { data: leadsListData, loading: leadsListLoading } =
    useGetLeadsListQuery({
      variables: {
        userId: typeof params?.id === 'string' ? params.id : ''
      }
    })

  const { data: leadsSummaryData, loading: leadsSummaryLoading } =
    useGetLeadsListSummaryQuery({
      variables: {
        userId: typeof params?.id === 'string' ? params.id : ''
      }
    })

  let company: Company | undefined

  if (listType === ListType.Active) {
    if (calllistData !== undefined && ref_company !== undefined) {
      const matchingCompany = calllistData.getActiveList.companies?.find(
        (company) => company?.id === ref_company
      )
      if (matchingCompany != null) company = matchingCompany // TODO: type issue bc Company type still requires call_list_summary and ids
    }
  } else if (listType === ListType.Leads) {
    if (leadsListData !== undefined && ref_company !== undefined) {
      const matchingCompany = leadsListData.getLeadsList.companies?.find(
        (company) => company?.id === ref_company
      )
      if (matchingCompany != null) company = matchingCompany // TODO: type issue bc Company type still requires call_list_summary and ids
    }
  }

  const activeDivision =
    summaryData !== undefined
      ? summaryData?.getActiveList.summary?.user_division
      : undefined

  const leadsDivision =
    leadsSummaryData !== undefined
      ? leadsSummaryData?.getLeadsList.summary?.user_division
      : undefined

  if (listType === ListType.Leads) {
    return {
      loading: leadsListLoading || leadsSummaryLoading,
      company,
      division: leadsDivision
    }
  } else {
    return {
      loading: calllistLoading || summaryLoading,
      company,
      division: activeDivision
    }
  }
}
