import * as styles from "./button.module.css";
import classNames from "classnames";
import { type ForwardedRef, forwardRef } from "react";
import { ButtonProps } from "./Button.types";
import { handleNewTab } from "../helpers/handleNewTab";

export const Button = forwardRef(function Button(props: ButtonProps, ref) {
  const {
    children,
    style,
    type,
    as,
    href,
    linkNewTab = false,
    ariaLabel,
    ariaDescribedBy,
    disabled,
    onClick,
    className,
    id,
    title,
  } = props;
  const classes = classNames(`${styles[style] as string}`, className);

  const externalLinkProps: React.HTMLProps<HTMLAnchorElement> = {};
  if (linkNewTab) {
    externalLinkProps.onClick = (event) => {
      event.preventDefault();
      handleNewTab(href);
    };
    externalLinkProps.rel = "external nofollow nooopener";
  }

  if (as === "a") {
    return (
      <a
        href={href}
        aria-label={ariaLabel ?? ""}
        aria-describedby={ariaDescribedBy ?? ""}
        className={classes}
        onClick={onClick}
        {...externalLinkProps}
        id={id}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button
        type={type}
        disabled={disabled}
        className={classes}
        aria-label={ariaLabel ?? ""}
        aria-describedby={ariaDescribedBy ?? ""}
        {...(disabled ? { "aria-disabled": true } : {})}
        onClick={disabled ? undefined : onClick}
        id={id}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        title={title}
      >
        {children}
      </button>
    );
  }
});
