import { type ReactNode, createContext, useState, useContext } from 'react'
import { type AccordionTypes } from '../../components/call-list/company/CompanySection'

interface Global {
  defaultOpenContactCategory?: AccordionTypes[]
}

interface GlobalContextType extends Global {
  setGlobalState: (global: Global) => void
}

const GlobalContext = createContext<GlobalContextType>({
  defaultOpenContactCategory: undefined,
  setGlobalState: () => {}
})

const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [global, setGlobalState] = useState<Global>({
    defaultOpenContactCategory: undefined
  })

  return (
    <GlobalContext.Provider
      value={{
        defaultOpenContactCategory: global.defaultOpenContactCategory,
        setGlobalState
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }

export const useGlobalState = (): GlobalContextType => {
  return useContext(GlobalContext)
}
