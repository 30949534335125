.container{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.fullScreen{
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: center;
}
